import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Icons, Images } from "../../assets/assets";
import { Helmet } from "react-helmet";
const TermsPolicy = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Terms & Conditions| SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Familiarise yourself with SodaStream's legal terms. Understand our policies and your rights as a consumer."
          }
        />
        <link
          rel="canonical"
          href="https://www.sodastream.co.za/terms-and-conditions"
        />
      </Helmet>
      <Header
        title="Terms & Conditions"
        subTitle=""
        textBackground="Ts & Cs"
        backgroundImg={Images.Page27BG}
      />
      <div className="relative min-h-[100rem] w-full">
        <div className="relative md:top-[-5rem] top-0 gap-10 md:z-50 z-9 flex flex-col items-center justify-center  w-full">
          <div className="mt-5 lg:px-60 md:px-40 sm:px-20 px-5 w-full">
            <div className="bg-white rounded-xl border border-gray-300 relative w-full opacity-80 px-10 py-20 text-[#005D6E]">
              <div className="flex flex-col gap-4 text-[12px] text-start">
                <h3>Our Commitment To Privacy</h3>

                <p>
                  Your privacy is important to us. To better protect your
                  privacy we provide this notice explaining our online
                  information practices and the choices you can make about the
                  way your information is collected and used. To make this
                  notice easy to find, we make it available on our homepage and
                  at every point where personally identifiable information may
                  be requested.
                </p>

                <h3>The Information We Collect</h3>
                <p>
                  This notice applies to all information collected or submitted
                  on the sodaclub.com & sodastreamusa.com websites. On some
                  pages, you can order products, make requests, enter contests &
                  sweepstakes, complete surveys, submit newsletter registrations
                  and other notification requests, and request to be contacted
                  by our staff. The types of personal information collected at
                  these pages are: Name, Address, Email address, Phone number,
                  Credit/Debit Card Information, demographic information, gender
                  information, and responses to survey questions.
                </p>

                <h3>The Way We Use Information</h3>
                <p>
                  We use the information you provide about yourself when placing
                  an order only to complete that order. We do not share this
                  information with outside parties except to the extent
                  necessary to complete that order
                </p>

                <p>
                  We use the information you provide about someone else when
                  placing an order only to ship the product and to confirm
                  delivery. We do not share this information with outside
                  parties except to the extent necessary to complete that order.
                </p>

                <p>
                  We use return email addresses to answer the email we receive,
                  and notify customers of new products, expansion of our service
                  area, and periodic special offers. Such addresses are not
                  shared with outside parties. In all cases, Sodastream will
                  provide a means by which any recipient of these email
                  notifications can opt-out of receiving any future emailings.
                </p>

                <p>
                  We use non-identifying and aggregate information to better
                  design our website and to share with advertisers. For example,
                  we may tell an advertiser that X number of individuals visited
                  a certain area on our website, or that Y number of men and Z
                  number of women filled out our registration form, but we would
                  not disclose anything that could be used to identify those
                  individuals.
                </p>

                <p>
                  Finally, we never use or share the personally identifiable
                  information provided to us online in ways unrelated to the
                  ones described above without also providing you an opportunity
                  to opt-out or otherwise prohibit such unrelated uses.
                </p>

                <h3>Our Commitment To Data Security</h3>
                <p>
                  To prevent unauthorized access, maintain data accuracy, and
                  ensure the correct use of information, we have put in place
                  appropriate physical, electronic, and managerial procedures to
                  safeguard and secure the information we collect online.
                </p>

                <h3>Our Commitment To Children’s Privacy</h3>
                <p>
                  Protecting the privacy of the very young is especially
                  important. For that reason, we never collect or maintain
                  information at our website from those we actually know are
                  under 13, and no part of our website is structured to attract
                  anyone under 13.
                </p>

                <h3>How To Contact Us</h3>
                <p>
                  Should you have other questions or concerns about these
                  privacy policies, please call us at +27800 00 22 91 or send us
                  an email at{" "}
                  <a href="mailto:customercare@www.sodastream.co.za ">
                    {" "}
                    customercare@www.sodastream.co.za
                  </a>
                </p>

                <h3>Privacy Policy Changes</h3>
                <p>
                  Any material changes to Sodastream USA’s Privacy Policy will
                  be posted conspicuously within this Privacy Policy. Effective
                  Date January 1, 2004.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsPolicy;
