import { Carousel, IconButton } from "@material-tailwind/react";

interface CarouselProps {
    images: Array<any>;
    loop?: boolean;
    placeholder?: string;
}

export const DefaultCarousel: React.FC<CarouselProps> = ({ images, loop, placeholder }) => {
    return (
        <Carousel
            loop={loop}
            placeholder={placeholder}
            prevArrow={({ handlePrev }) => (
                <IconButton
                    variant="text"
                    size="sm"
                    onClick={handlePrev}
                    className="!absolute top-2/4 left-4 -translate-y-2/4"
                    placeholder={placeholder}
                    onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-6 w-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>
                </IconButton>
            )}
            nextArrow={({ handleNext }) => (
                <IconButton
                    variant="text"
                    size="sm"
                    onClick={handleNext}
                    className="!absolute top-2/4 !right-4 -translate-y-2/4"
                    placeholder={placeholder} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-6 w-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                    </svg>
                </IconButton>
            )}
            className="rounded-xl bg-[#B6B6B6] border-[2px] mt-[10px] flex align-items-center"  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
            {images.map((image, index) => (
                <img key={index} src={image} alt="art" className="m-auto" />
            ))}
        </Carousel>
    );
};
