import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DataRecipe } from "../../../@types/productInfo";
import Loading from "../../Loading/Loading";
import Footer from "../../Footer/Footer";
import SocialLinks from "../../SocialLinks/SocialLinks";
import { Link } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Header from "../../Header/Header";
import { ProductService } from "../../../APIs";

const RecipesPreviewPage: React.FC = () => {
  const location = useLocation();
  const [selectedRecipe, setRecipeData] = useState<DataRecipe | null>(null);
  const [product, setProduct] = useState<any>();

  useEffect(() => {
 
    const query = new URLSearchParams(location.search);
    const recipe = query.get("data");

    
    if (recipe) {
      try {
        const parsedRecipe = JSON.parse(decodeURIComponent(recipe));
        setRecipeData(parsedRecipe);
      } catch (error) {
        console.error("Error parsing recipe data:", error);
      }
    }
  }, [location.search]);


  const breadcrumbItems = [
    { text: "HOME", href: "/" },
    { text: "MIXOLOGY", href: "/mixology" },
    { text: selectedRecipe ? selectedRecipe.name : "Loading..." },
  ];


  const fetchProductData = async (productId?: number) => {
    try {
      const { data, error } = await ProductService.getProductById(productId);

      if (data) {
        setProduct(data);
        console.log("data:", data);
      } else {
        console.error("Error fetching product:", error);
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  
  useEffect(() => {
    if (selectedRecipe != null && selectedRecipe.productId != null) {
      fetchProductData(selectedRecipe.productId);
    }
  }, [selectedRecipe]);

 
  useEffect(() => {
    if (selectedRecipe != null && selectedRecipe.productId != null) {
      fetchProductData(selectedRecipe.productId);
    }
  }, [selectedRecipe]);
  return (
    <div>
    {selectedRecipe ? (
      <>
        <Header isHalf title="Mixology" textBackground="MIXOLOGY" />
        <section>
          <div className="bg-[#FFFFFF] text-start px-5 md:px-20">
            <Breadcrumb items={breadcrumbItems} />
          </div>
          <div className="grid lg:grid-cols-2 gap-0 md:grid-cols-1">
            <div className="bg-[#E3E3E3] p-20 relative w-full">
              <div className="flex items-center justify-center">
                <img src={selectedRecipe?.image || ""} alt="full glass" />
              </div>

              <>
                {product?.image &&
                  <img
                    className="absolute bottom-5 right-[0]"
                    src={product?.image}
                    alt="sodastream flavour"
                    width={
                      "10%"}
                  />
                }
                <Link
                  to={"" || "#"}
                  className="absolute bottom-5 right-20 text-[#75A7AD]"
                >
                  VIEW FLAVOUR
                </Link>
              </>
            </div>
            <div className="bg-[#75A7AD] px-20 py-10 text-white text-start flex flex-col gap-5">
              <h3 className="text-left text-white font-bold opacity-100 text-[35px]">
                {selectedRecipe.name}
              </h3>
              <div className="flex flex-col gap-0">
                <h4 className="font-semibold mb-1">INGREDIENTS</h4>
                {selectedRecipe.ingredients.map((ingredient) => (
                  <p key={ingredient}>{ingredient}</p>
                ))}
              </div>
              <div>
                <h4 className="font-semibold mb-1">METHOD</h4>
                {selectedRecipe.methods.map((method) => (
                  <p key={method}>{method}</p>
                ))}
              </div>
              <div className="flex md:gap-2 items-center flex-wrap">
                <p className="text-[10px]">SHARE THIS RECIPE WITH FRIENDS</p>
                <div className="mt-1">
                  <SocialLinks />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    ) : (
      <Loading />
    )}
  </div>
  );
};

export default RecipesPreviewPage;
