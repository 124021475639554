import axios, { AxiosResponse } from 'axios';

const URL = 'https://sodastreamapi.azurewebsites.net/util/uploadImage';

class ImageUploadUtil {

  async uploadImage(file: File): Promise<{ data: string | null; error: string | null }> {
    try {
      const formData = new FormData();
      formData.append('Image', file);

      const response: AxiosResponse<{ data: string }> = await axios.post(
        URL,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        const imageUrl = response.data.data;
        console.log('Image uploaded successfully:', imageUrl);
        return { data: imageUrl, error: null };
      } else {
        const error = `Error uploading image - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error('Error uploading image:', error.message);
    
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }
}


const imageUploadUtilInstance = new ImageUploadUtil();
// ghhgghg

export default imageUploadUtilInstance;
