import React, { useState } from 'react';
import { CgPlayButtonO } from 'react-icons/cg';

const VideoPreview = ({ video, bg }: { video: string, bg: string}) => {
    const [showVideo, setShowVideo] = useState(false);
  
    return (
      <div className="relative">
        {/* Display video iframe if showVideo is true */}
        {showVideo && (
          <iframe
            title="terra"
            src={video}
            className=""
            allow="autoplay; encrypted-media"
            width="100%" 
            height="620"
          ></iframe>
        //   <video
        //     controls
        //     autoPlay
        //     className="w-full h-[620px]"
        //     onEnded={() => setShowVideo(false)}
        //     >
        //   <source src={video} type="video/mp4" />
        //     Your browser does not support the video tag.
        // </video>
        )}
  
        {/* Display preview image if showVideo is false */}
        {!showVideo && (
            <div className='relative'>
                <CgPlayButtonO onClick={() => setShowVideo(true)} fontSize={120} color='white' className='cursor-pointer absolute top-1/2 left-1/2' style={{ transform: 'translate(-50%, -50%)'}}/>
                <img
                    src={bg}
                    alt="Video Preview"
                    className="cursor-pointer w-full h-full"
                    onClick={() => setShowVideo(true)}
                />
            </div>
        )}
      </div>
    );
  };

export default VideoPreview;