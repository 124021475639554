import React, { useEffect, useState } from "react";
import { Images } from "../../assets/assets";
import Footer from "../../components/Footer/Footer";
import { usePlacesWidget } from "react-google-autocomplete";
import Header from "../../components/Header/Header";
import MapCon from "../../components/Map/MapContainer";
import storeServiceInstance from "../../APIs/store";
import { Modal } from "antd";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";



declare global {
  interface Window {
    dataLayer: any[];
  }
}

const titles = [
  "Sparkling Water Makers",
  "Gas Exchange",
  "Flavours",
];

const StoreLocator = () => {
  const [markers, setMarkers] = useState<any[]>([]);
  const [center, setCenter] = useState({ lat: -26.1937, lng: 28.0339 });
  const [loading, setLoading] = useState<boolean>(false);
  const [stores, setStores] = useState<any[]>([]);
  const [searchPlace, setSearchPlace] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])


  const fetchData = async () => {
    try {
      const { data, error } = await storeServiceInstance.getAllStores();
      if (data) {
        setStores(data);
      } else {
        console.error("Error fetching stores:", error);
      }
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { ref }: any = usePlacesWidget({
    apiKey: "Your_Google_Places_API_Key",
    onPlaceSelected: (place) => {
      setSearchPlace(place.address_components![0]?.long_name ?? "");
    },
  });

  useEffect(() => {
    if (stores.length > 0 && searchPlace) {
      filterPlaces(searchPlace);
    }
  }, [stores, searchPlace, selectedFilters, selectedOptions]);

  const filterPlaces = (city: string) => {
    setLoading(true);
    try {
      if (stores.length > 0) {
        let filteredStores = stores.filter(
          (store) =>
            store.city && store.city.toUpperCase() === city.toUpperCase()
        );

        // Apply selected filters from checkboxes
        if (selectedFilters.includes("sell_machine")) {
          filteredStores = filteredStores.filter(
            (store) => store.sell_machine === true
          );
        }

        if (selectedFilters.includes("exchange")) {
          filteredStores = filteredStores.filter(
            (store) => store.cylinder_exchange === true
          );
        }

      
        selectedOptions.forEach((option) => {
          if (option.value === "sell_machine") {
            filteredStores = filteredStores.filter(
              (store) => store.sell_machine === true
            );
          } else if (option.value === "exchange") {
            filteredStores = filteredStores.filter(
              (store) => store.cylinder_exchange === true
            );
          }
        });

        setMarkers(filteredStores);

        if (filteredStores.length > 0) {
          const filteredStore = filteredStores[0];
          if (filteredStore && filteredStore.lat && filteredStore.lng) {
            const lat = parseFloat(filteredStore.lat.trim());
            const lng = parseFloat(filteredStore.lng.trim());

            if (!isNaN(lat) && !isNaN(lng)) {
              setCenter({ lat, lng });
            } else {
             
            }
          } else {
           
          }
        } else {
         
        }
      }
    } catch (error) {
     
    } finally {
      setLoading(false);
    }
  };

  

  useEffect(() => {
    const searchBar = document.getElementById('search-bar');
    if (searchBar) {
      searchBar.addEventListener('click', handleClick);
    }
    
    
    return () => {
      if (searchBar) {
        searchBar.removeEventListener('click', handleClick);
      }
    };
  }, []);
  
  const handleClick = () => {
    console.log('Search bar clicked!');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'search_bar_click'
    });
  }



  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const logos = [
    "http://res.cloudinary.com/brabenjos/image/upload/v1723364757/sodastream/1723364757097.png",
    "http://res.cloudinary.com/brabenjos/image/upload/v1723364868/sodastream/1723364868127.png",
    "http://res.cloudinary.com/brabenjos/image/upload/v1723453555/sodastream/1723453555356.png",
    "http://res.cloudinary.com/brabenjos/image/upload/v1723364928/sodastream/1723364928860.png",
  ];

  const buttons = [
    { label: "View all products", link: "https://www.checkers.co.za/search?q=sodastream" },
  
    { label: "View all products", link: "https://www.pnp.co.za/b/SODA%20STREAM" },
    { label: "View all products", link: "https://www.takealot.com/all?filter=Brand:Sodastream" },
    { label: "View all products", link: "https://m.yuppiechef.com/sodastream.htm" },
   
   
  ];


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"SodaStream Store Locator | SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Find the nearest SodaStream retailer with our store locator. Get your favorite SodaStream products easily!"}
        />
        <link rel="canonical" href="https://www.sodastream.co.za/store-locator" />
      </Helmet>
    
      <Header
        title="Store Locator"
        subTitle=""
        backgroundImg={Images.StoreLocatorBg}
        textBackground="STORES"
      />

      {/* Main content */}
      <section  className="relative  ">
        <div className="relative   md:top-[-15px] ">
          <div className="md:relative  md:z-50 z-30 w-full">
            <div className="flex items-center justify-center">
              <div className="w-[90%] rounded-xl overflow-hidden opacity-80 z-20 mt-[-15%]">
                <div
                  className=" ph-3 md:ph-10  ph-3 md:ph-10"
                  style={{
                    background: "white",
                    paddingLeft: "3%",
                    paddingRight: "10%",
                  }}
                >
       </div>
       
                <div  className="w-full  bg-[#75A7AD] h-[60px] pt-[20px] ">
                
                <div className=" items-center justify-center p-4
                   md:flex-row md:items-center md:justify-center pb-[50px]">
                    <div className="w-full mb-5 md:mb-0 md:mr-2">
                      <input
                       id="search-bar"
                        ref={ref}
                        placeholder="Cape Town, Western Cape, South Africa"
                        className="rounded-full w-full md:w-[582px] h-[43px] pl-3 border-[1px]
                          mb-6 md:mb-0 bg-white"
                      />
                      
                    </div>
                    
                  </div>

                
                </div>
                {/* Results */}
                <div className="bg-[#75A7AD] h-auto flex items-center p-4">
  <div className="flex-shrink-0 mr-4 pt-5">
    <img className="w-8 h-8" src="https://cdn.storepoint.co/assets/img/sodastream/time@1x.svg" alt="Logo" />
  </div>
  <div className="flex-grow text-white pt-5">
    <span className="text-[14px] font-bold rounded-full hover:bg-[#080A40] focus:outline-none mr-2">
      Please note:
    </span>
    We recommend calling to check product availability before visiting a store.
  </div>
</div>
                <div className="bg-white flex flex-col md:flex-row py-5 w-full md:w-[100%] h-auto">
                  <div className="w-full md:w-[35%] max-h-[400px] overflow-y-auto">
                 
                    {markers.length === 0 ? (
                      <div className=" bg-white text-[#002238] font-poppins  text-left
                      whitespace-pre-wrap py-1 px-0  ">
                     
                     <div className="flex items-center justify-center h-full mt-[10%]">
  <p className="text-center text-[#002238] text-[18px] ">
    No locations found near you, but we'd love to change that.
  </p>
</div>


                     </div>
                      
                    ) : (
                      <div className="">
                        {markers.map((marker) => (
                          <div
                            key={marker.id}
                          
                          >

                            {/*  */}
                            <div className="flex flex-col md:flex-row  h-auto shadow-lg mb-2">
      <div className="flex-1 p-4">
      <div>
      
      <div className="bg-white text-[#002238] font-poppins text-xs font-semibold
       text-left uppercase py-1 px-0 ">
      <p className="text-[#002238]">{marker.chain_desc}{" "}
      {marker?.storeName}</p>
    </div>
    <div className=" bg-white text-[#002238] font-poppins  text-left
     whitespace-pre-wrap py-1 px-0  ">
      
    </div>
    <div className=" bg-white text-[#002238] font-poppins  text-left
     whitespace-pre-wrap py-1 px-0  ">
      <p className="text-[#002238] text-[14px]
       ">{marker?.city}</p>
        <p className="text-[#002238] text-[14px]
       ">{marker?.streetAddress}</p>
        <p className="text-[#002238] text-[14px]
       ">{marker?.National_Services_Dist_No_1}</p>
    </div>
    
      </div>
      </div>
      <div className="flex-1 pl-4 mt-[22px]">
       
      <div className="flex flex-col space-y-2">
        {titles.map((title, index) => (
          <div
            key={index}
            className={`flex items-center  px-0 ${index === titles.length - 1 ? '' : ''}`}
          >
            <span
              className={`inline-block w-2 h-2 rounded-full ${index === titles.length - 1 ? 'bg-orange-800' : 'bg-[#75A7AD]'}
               mr-3 flex-shrink-0 mt-[-12px]`}
            ></span>
            <p
              className="box-border text-[#005D6E] cursor-pointer inline-block font-sans text-[10px] 
              font-medium leading-[10px] max-w-full text-left capitalize align-middle visible antialiased"
            >
              {title}
            </p>
          </div>
        ))}
      </div>
      </div>
    </div>  
   
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-[65%]">
                    <MapCon markers={markers} center={center} />
                    </div>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <h1 className="text-center text-[#005D6E] block font-poppins text-[24px] sm:text-[29px] 
        font-semibold leading-[24px] sm:leading-[38.4px] pt-[25px] sm:mb-[40px] mb-[40px] ">
  Featured stockists
</h1>

        <section className="bg-white dark:bg-gray-900 flex justify-center items-center ">
    <div className="mb-[30px]">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-8 w-full">
            <Link to="https://www.checkers.co.za/search?q=sodastream" className="flex justify-center items-center">
                <img className="w-[120px] h-auto object-contain" src="http://res.cloudinary.com/brabenjos/image/upload/v1723364757/sodastream/1723364757097.png" alt=""/>
            </Link>
            <Link to="https://www.pnp.co.za/b/SODA%20STREAM" className="flex justify-center items-center">
                <img className="w-[120px] h-auto object-contain" src="http://res.cloudinary.com/brabenjos/image/upload/v1723364868/sodastream/1723364868127.png" alt=""/>
            </Link>
            <Link to="https://www.takealot.com/all?filter=Brand:Sodastream" className="flex justify-center items-center">
                <img className="w-[120px] h-auto object-contain" src="http://res.cloudinary.com/brabenjos/image/upload/v1723453555/sodastream/1723453555356.png" alt=""/>
            </Link>
            <Link to="https://m.yuppiechef.com/sodastream.htm" className="flex justify-center items-center">
                <img className="w-[120px] h-auto object-contain" src="http://res.cloudinary.com/brabenjos/image/upload/v1723364928/sodastream/1723364928860.png" alt=""/>
            </Link>
        </div>
    </div>
</section>
<div className="flex items-center justify-center h-auto  bg-white  "> 
 
  <button onClick={showModal} className='w-[200px] h-[45px] text-[16px] text-white font-bold rounded-full bg-[#75A7AD] 
     flex items-center justify-center hover:bg-[#005D6E] focus:outline-none mb-[20px]'>
      Shop Online
  </button>

</div>


<Modal
     
     visible={isModalVisible}
     onOk={handleOk}
     onCancel={handleCancel}
     footer={null} 
   >
    <h1 className="box-border 
     text-[#005D6E] block font-poppins text-[19px] font-semibold  leading-[38.4px]">
    Find a retailer
     </h1>
     <div>
    
     
   
     <h1 className="box-border text-center 
     text-[#005D6E] block font-poppins text-[17px] font-semibold  leading-[38.4px]"> 
      Shop Online at the following retailers:
     </h1>
   </div>
   
   <div className="grid grid-cols-1 gap-4">
  {logos.map((logo, index) => (
    <div
      key={index}
      className="flex items-center justify-between rounded-lg shadow-sm h-[70px] w-full p-4"
    >
      <img
        src={logo}
        alt={`Retailer Logo ${index + 1}`}
        className="max-w-[100px] max-h-[100px] object-contain mr-4"
      />
      <Link to={buttons[index].link}>
        <button
          className="w-full p-4 sm:w-[150px] md:w-[200px] h-[40px] text-sm sm:text-base md:text-lg text-white rounded-full bg-[#75A7AD] 
            flex items-center justify-center hover:bg-[#005D6E] focus:outline-none
            px-3 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3"
        >
          {buttons[index].label}
        </button>
      </Link>
    </div>
  ))}
</div>
   </Modal>
      <Footer />
    </>
  );
};

export default StoreLocator;
