import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Blogs from '../blogs/Blogs'
import Store from '../store/Store'
import Recipes from '../recipes/Recipes'
import Cascylinder from '../cascyllinder/Cascylinder'
import Watermachines from '../watermachines/Watermachines'

import Accessories from '../accessories/Accessories'
import ManageMembers from './../ManageMembers/ManageMembers';
import AddBlogs from '../blogs/AddBlogs'
import Products from '../products/Products'
import EditBlogs from '../blogs/EditBlogs'

import BlogPreview from '../blogs/BlogPreview'
import HomePage from '../Home/HomePage'

// 
// 

function Dashroutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="managemembers" />} />
    
      <Route path="managemembers" element={<ManageMembers/>} />
      <Route path="blogs" element={<Blogs />} />
      <Route path="store" element={<Store />} />
      <Route path="recipes" element={<Recipes />} />
      <Route path="dashboardhome" element={<HomePage/>} />
      <Route path="cascylinder" element={<Cascylinder />} />
      <Route path="watermachines" element={<Watermachines />} />
      <Route path="products" element={<Products/>} />
      <Route path="/edit/:id" element={<EditBlogs/>} />
      <Route path="addblog" element={<AddBlogs />} />
      <Route path="accessories" element={<Accessories />} />
     
      <Route
        path="previewblog"
        element={
          <BlogPreview
           
          />
        }
      />
    </Routes>
  )
}

export default Dashroutes