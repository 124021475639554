import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AccessoryData } from "../../../@types/productInfo";
import SecondaryButton from "../../Button/SecondaryButton";
import Product from "../../Product/Product";
import Footer from "../../Footer/Footer";
import Loading from "../../Loading/Loading";
import ProductContent from "../../ProductContent/ProductContent";
import { Images } from "../../../assets/assets";
import Header from "../../Header/Header";

import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Navbar from "../../Navbar/Navbar";
import { Link } from "react-router-dom";

const AccessoryPreviewPage: React.FC = () => {
  const location = useLocation();
  const [selectedAcc, setAccessoryData] = useState<AccessoryData | null>(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const accessory = query.get("data");

    if (accessory) {
      try {
        const parsedAccessory = JSON.parse(decodeURIComponent(accessory));
        setAccessoryData(parsedAccessory);
      } catch (error) {
        console.error("Error parsing accessory data:", error);
      }
    }
  }, [location.search]);

  const breadcrumbItems = [
    { text: "HOME", href: "/" },
    { text: "ACCESSORIES", href: "/accessories" },
    { text: selectedAcc ? selectedAcc.name : "Loading..." },
  ];
  useEffect(() => {
    const dataParam = localStorage.getItem('modalData');
    if (dataParam) {
      const decodedData = JSON.parse(decodeURIComponent(dataParam));
      console.log("Data from localStorage:", decodedData);
      // Utilisez les données pour afficher la prévisualisation
    }
  }, []);
  return (
    <>
      <>
        <div>
          {selectedAcc ? (
            <>
              <div>
                {/* <Header textBackground='ACCESSORIES' title='Accessories' backgroundImg={Images.AccessoriesProductBg} isHalf /> */}
                <Navbar />
                <section>
                  <div className="bg-[#FFFFFF] text-start px-20">
                    <Breadcrumb items={breadcrumbItems} />
                  </div>
                  <div className="flex px-20 py-5 pb-20 gap-5 bg-[#FFFFFF] md:flex-row flex-col">
                    <div className="basis-[50%] flex flex-col items-center justify-start">
                      <img
                        className="mt-5"
                        src={selectedAcc.image || ""}
                        alt="drink"
                        style={{
                          maxWidth: "20rem",
                          maxHeight: "20rem",
                          minHeight: "10rem",
                          minWidth: "10rem",
                        }}
                      />
                    </div>
                    {/*  */}
                    <div className="basis-[50%] flex flex-col items-center text-start">
                      <div className="flex flex-col gap-4 text-[#005D6E]">
                        <h2 className="text-[#005D6E] text-[24px]">
                          {selectedAcc.name}
                        </h2>
                        {/* <p className='text-[#005D6E] text-start text-[20px]'>R250</p> */}
                        <p>{selectedAcc.description}</p>
                        <p>{selectedAcc.caption}</p>
                        <div>
                          <Link to="/store-locator">
                            <SecondaryButton label="Store Locator" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <Footer />
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </>
    </>
  );
};

export default AccessoryPreviewPage;
