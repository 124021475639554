import UserIcon from './icons/user.png';
import IconBottle from './icons/icon-bottle-snap_1-3@2x.png';
import IconCordless from './icons/icon-cordless_1-2@2x.png';
import Icondishwasher from './icons/icon-dishwasher_1-3@2x.png';
import IconEgronomicButton from './icons/icon-ergonomic-button_1@2x.png';
import IconPatentedButton from './icons/icon-patented-system_3-3@2x.png';
import IconSustainability from './icons/icon-sustainability.png';
import IconFreshWater from './icons/icon-fresh-water_22fbbc75-33a3-4c22-b2ed-4770a854277b.png';
import IconGoodByeCarrying from './icons/icon-goodbye-carrying.png';
import IconHeathier from './icons/icon-healthier_f7e36629-a36e-4dbf-b4c5-e688d8cac0e2.png';
import IconDishWasherSDS from './icons/SDS_Icon_DishwasherSafeBottles_RGB.png';
import IconSparklingSmall from './icons/icon-sparkle Small@2x.png';
import IconQuickyEasy from './icons/icon-quick-easy_c0e65e3b-e163-46c5-a847-27dc1ea627bb Small@2x.png';
import IconQualityFirst from './icons/icon-quality-first_1 Small@2x.png';
import IconFreshCertified from './icons/icon-fresh-certified_7a023351-8805-4c9f-aa16-dd5e875bce5d Small@2x.png';
import IconGroup80 from './icons/Group 80@2x.png';
import IconCo2Cylinder from './icons/CO2_Cylinder.png';
import IconRetailExchange from './icons/Icon-RetailExchange.png';
import IconExchangeBottles from './icons/Icon-ExchangeBottles.png';
import IconDoubleBottles from './icons/IconDoubleBottles.svg';
import IconGetStarted from './icons/GetStarted.png';
import IconOrderSupport from './icons/OrderSupport.png';
import IconBubbleTroubles from './icons/BubbleTroubles.png';
import IconGasRefills from './icons/GasRefills.png';
import IconFlavoursAndBottles from './icons/FlavoursAndBottles.png';
import IconStoreLocator from './icons/StoreLocator.png';
import IconMail from './icons/Mail.png';
import IconCall from './icons/Call.png';
import IconSupport from './icons/Support.png';
import IconInstagram from './icons/Instagram.png';
import IconYoutube from './icons/Youtube.png';
import IconFacebook from './icons/Facebook.png';
import IconCertification from './icons/Certification.png';


const LogoDesign ="https://firebasestorage.googleapis.com/v0/b/cars-rental-2a2e9.appspot.com/o/loginpage.png?alt=media&token=ca2ca663-df5a-4e31-af2f-48bdb1fe07d3"
const LogoGreen2 = 'https://firebasestorage.googleapis.com/v0/b/cars-rental-2a2e9.appspot.com/o/sodastream-logo-green2%201-2.png?alt=media&token=48ccfb2b-6f6b-4a69-afba-3490de7b6b9b';
const HomeBG1 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/HomeBG-1.png';
const HomeBG3 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/HomeBG3.png';
const HomeBG4 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/MicrosoftTeams-image_64';
const HomeBG2 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/home_banner_2.png';
const LogoLight = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/sodastream-logo-light.png';
const LogoGreen = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/sodastream-logo-green.png';
const WatterBottles = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/WaterBottles.png';
const ArtImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/art.png';
const ColoredWatterBottle = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ColoredWatterBottle.png';
const GasTextImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/GAS.png';
const WatterBottleBlack = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/WatterBottleBlack_2.png';
const WatterBottleBlack2 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/WatterBottleBlack.png';
const Glass = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Glass.png';
const FlavourTxt = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/flavourText.png';
const DeepBlueSodastreamRipple = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/DeepBlueSodastream_Ripple.png';
const StoreLocatorBg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/StoreLocatorBg.png';
const FullGlass = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/fullGlass.png';
const RecommendationPageBG = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/WhiteTerraMachineBg.png';

const SodastreamOrange = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/sodastream-orange.png';
const SodastreamLemon = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/sodastream-lemon.png';
const SodastreamStrawberry = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/sodastream-strawberry.png';
const SodastreamWaterMellon = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/sodastream-waterMellon.png';
const SodastreamDietDrPete = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/sodastream_diet_dr_pete.png';

const Slide = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Slide.png';
const FriendsgivingFizz  = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/FriendsgivingFizz.png';
const PineappleDelightDuo = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/PineappleDelightDuo.png';
const ReCrafted = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ReCrafted.png';

// RecommendationPage Images
const JamImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Jam.png'; 
const HappySourtHourImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/HappySourArt.png';
const PearMangoTangoSmokelessArt = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/PearMangoTangoSmokelessArt.png';
const GinGinArt = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/GinGinArt.png';

const PouringFromFuseBottle = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/PouringFromFuseBottle.png';
const MyOnlyBottleOutdoor = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/MyOnlyBottle_outdoor.png';
const VideoBG = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/VideoBG.png';

// Product Page
const FamilyDinnerPourring = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/FamilyDinnerPouring.png';
const StarryLifestyle = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/StarryLifestyle.png';
const FuseBottleWorkingWomen = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/FuseBottleWorkingWomen.png';
const BLACKMOB = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/BLACK-MOB.png';
const CocktailBook = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/CocktailBook.png';
const US_ARTWhiteHydrationBundle = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/US_ARTWhiteHydrationBundle.png';
const Pack1 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/pack_1.png';

// 
const TerraBlack = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/TerraBlack.png';
const SparklingWaterMakerBg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SparklingWaterMakerBg.png';

// 
const WaterMakerProductBg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/WaterMakerProductBg.png';
const US_ART_Black_Hydration_Bundle = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/US_ART_black_hydration_bundle_550X_2x.png';
const DelivrarySteps = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Delivrary_Steps.png';
const WatterMakerSectionBg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/WatterMakerSectionBg.png';

// 
const AccessoriesBg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/AccessoriesBg.png';
const DoubleBottles = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/1L_SLIM_Black_Fuse_Bottles_1_e6943d9b-88ad-4624-8511-5e19eae79660.png';
const DoubleBottles2 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/DoubleBottles2.png';
const SingleBottle = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/0.5LICY_BLUE_MOB_1_a211e5f0-e67c-4c50-864b-9ee560813702.png';
const AccessoriesProductBg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/AccessoriesProductBg.png';

//
const GroupRaisingGlasses = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/GroupRaisingGlasses.png';

//
const BlogBg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Full_Frame-_CoupleWithTwo_GlassesAndblack_TerraMachine.png';
const Benefits1GallonWater = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/BlogPost-Benefit_of_Drinking_Gallon_of_Water.png';
const StayHydratedPost = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/BlogPost-Stay_Hydrated_at_Work.png';
const Post2 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/BlogPost-Sparkling_Water_Dispenser_1beb2e42-9e23-4dd5-8b14-f65467ceada1_2x';
const Post4Bg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Blog-2_-_Cover_Photo_-_Ditch_the_Soda_for_Better_Health_f1a0ae87-1c05-445c-8ee8-27e17efd7706_2x.png';


//
const Page6BG = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Page6BG.png';
const FuseBottleImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Fuse_bottle_pouring_blueberries_2x.png';
const Group11Img = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Group_11_2x.png';
const ReviewsImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Soda_stream2.png';
const DeepBlueSodaStreamSmall = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Deep_Blue_Sodastream_Ripple_Small.png';
const GroupEnjoyingSparklingWater = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Group_enjoying_sparkling_water_with_meal.png';
const FuseBottle1 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Fuse_Bottle_And_Glass.png';
const FullFrameCoupleMachine = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Full_Frame_-_Couple_And_Open_White_Duo_Machine.png';
const ManWithBlackHat = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Man_with_black_Art_machine.png';
const WhiteArtMachine = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/White_Art_Machine_With_Glass.png';
const BlogPostPageBg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Black_Art_Machine_And_Two_Glassws_2x.png';
const CylinderUS = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Cylinder-US.png';
const CylindersWithRippleImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/image-with-ripple_2x_0dde8a05-6408-491b-b23a-b821dfb1ce4a.png';
const OrderSpareImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/image-order-spare_2x_1.png';
const ExchangeBottleSpareImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/image-exchange-cyl_2x_703e42c0-fd55-4b2b-a184-8c5799a45e55_1'; 
const Page26BG = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Full_Frame-_CoupleWithTwo_GlassesAndblack_TerraMachine.png';
const Page27BG = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Fuse_bottle_pouring_blueberries_2x.png';
const CouplesDrinkingSparkilingWater = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Couple_drinking_sparkling_water_fuse.png';//check
const FuseBottleWithDinner = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Fuse_Bottle_With_Dinner.png';
const FuseBottleWithFood = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Fuse_bottle_with_food.png';
const Page16BG = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Full_Frame_-_Black_Art_Machine_With_Glass_With_Fruits_2x.png';
const PromoHeadline2 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/My_only_bottle_pool.png';
const PromoHeadline3 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Pouring_Bottle_And_Glass.png';
const Page24BG = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Black_Art_Machine_And_Two_Glassws_2x.png';

const TerraBlackNavbar = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/TerraBlackNavbar.png';
const GasNavbar1 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/GasNavbar1.png';
const GasNavbar2 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/GasNavbar2.png';
const Navbar3 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Navbar3.png';
const Navbar01 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/navbar_pep.png';
const Navbar02 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/navbar_creme.png';
const Navbar32 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Navbar32.png';
const Navbar4 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Navbar4.png';
const Navbar42 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Navbar42.png';
const Navbar51 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Navbar51.png';
const Navbar52 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Navbar52.png';
const Navbar53 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Navbar53.png';
const HomeRipple = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/HomeRipple.png';
const GroupedSodaStreamRipple = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/soda_stream.png';

const TerraWhiteRight = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/TerraWhiteRight.jpg';
const TerraWhiteFront = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/TerraWhiteFront.jpg';
const TerraWhiteLeft = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/TerraWhiteLeft.jpg';

const TerraBlackRight = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/TerraBlackRight.jpg';
const TerraBlackFront = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/TerraBlackFront.jpg';
const TerraBlackLeft = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/TerraBlackLeft.jpg';

const ArtWhiteRight = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ArtWhiteRight.jpg';
const ArtWhiteFront = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ArtWhiteFront.jpg';
const ArtWhiteLeft = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ArtWhiteLeft.jpg';

const ArtBlackRight = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ArtBlackRight.jpg';
const ArtBlackFront = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ArtBlackFront.jpg';
const ArtBlackLeft = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ArtBlackLeft.jpg';

const XstreamEnergy = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/xstream_energy.png';
const PepsiBev = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/PEPSI_BEV.png';
const ProdExtremeEnergy = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/prod_extreme_energy.png';
const Prod7Up = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/prod_7up.png';
const Prod7UpFree = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/prod_7up_free.png';
const ProdClassicsCreamSoda = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/prod_classics_cream_soda.png';
const ProdCranberry = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Cranberry_Raspberry.png';
const ProdGingerBeer = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ging.png';
const ProdGingerFlavour = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ProdGingerFlavour2.png';
const ProdGrapeFruit = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Pink_Grapefruit.png';
const ProdIceTeaPeach = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ProdIceTea_1.png';
const ProdLemonade = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/prod_lemonade.png';
const ProdMiranda = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/prod_miranda.png';
const ProdPepsi = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/prod_pepsi.png';
const ProdPepsiMax = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/prod_pepsi_max.png';
const ProdTonic = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/prod_tonic.png';
const ProdTonic2 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Tonic.png';
const ProdTonic3 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ProdTonic4.png';


const ArtStep1 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ART_STEP_1.png'
const ArtStep2 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ART_STEP2.png'
const ArtStep3 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ART_STEP_3.png'
const ArtStep4 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/ART_STEP_4.png'
const Home_Orange = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/home_orange.png'
const Home_Lemonade = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/home_lemonade.png'
const Home_Cranberry = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/home_cranberry.png'
const Home_Fountain_Mist = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/home_fountain_mist.png'
const Home_Ginger_Ale = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/home_ginger_ale.png'
const Home_Strawberry = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/home_strawberry.png'
const PepsiLemonade = 'http://res.cloudinary.com/brabenjos/image/upload/v1714988563/sodastream/1714988563650.png'
const AutumnElixir = 'http://res.cloudinary.com/brabenjos/image/upload/v1714988290/sodastream/1714988290537.png'
const YouAJam = 'http://res.cloudinary.com/brabenjos/image/upload/v1714988110/sodastream/1714988110529.png'
const NewYearsSparkler = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/NewYearsSparkler.png'
const NewOldFashion = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/NewOldFashion.png';
const BottleClassic1L = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/1L_Fuse_Metalic_NewLogo_final_copy.png';

const ManualImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SodaStream_Graph.jpg';

//
const Page19BG = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Page19Bg.png';
const Page18BG = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Page18Bg.png';
const BlackDuoImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/BlackDuoMachineAndGlassBottle.png';
const Page13BG = 'https://firebasestorage.googleapis.com/v0/b/cars-rental-2a2e9.appspot.com/o/ManPouringFromFuseBottle%20(1).png?alt=media&token=161ca574-7a06-487e-a41e-d3066f09603c';
const FuseBottleOutdoor = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/FuseBottleOutdoor.png';
const BlogBanners = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/BlogBanners.png';
const BlogBanners1 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/BlogBanners01.png';
const BlogBanners3 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/BlogBanners03.png';
const BlogBanners4 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/BlogBanners04.png';
const SouthAfricaImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SouthAfrica.png';
const MyOnlyBottleCheers = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/My_only_bottle_cheers.png';

const SodaStream_Web_Banner_7up_free = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SodaStream_Web_Banner_7up_free.png';
const SodaStream_Web_Banner_7up = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SodaStream_Web_Banner_7up.png';
const SodaStream_Web_Banner_Cranberry = 'https://res.cloudinary.com/brabenjos/image/upload/v1696928855/sodastream/images/SodaStream_Web_Banner_Cranberry.png';
const SodaStream_Web_Banner_cream_soda = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SodaStream_Web_Banner_cream_soda.png';
const SodaStream_Web_Banner_ginger_ale = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SodaStream_Web_Banner_ginger_ale.png';
const SodaStream_Web_Banner_Grape_Fruit = 'https://res.cloudinary.com/brabenjos/image/upload/v1696928855/sodastream/images/SodaStream_Web_Banner_Grape_Fruit.png';
const SodaStream_Web_Banner_ice_tea_peach = 'https://res.cloudinary.com/brabenjos/image/upload/v1696928855/sodastream/images/SodaStream_Web_Banner_ice_tea_peach.png';
const SodaStream_Web_Banner_lemonade = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SodaStream_Web_Banner_lemonade.png';
const SodaStream_Web_Banner_miranda = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SodaStream_Web_Banner_miranda.png';
const SodaStream_Web_Banner_pepsi_zero = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SodaStream_Web_Banner_pepsi_zero.png';
const SodaStream_Web_Banner_pepsi = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SodaStream_Web_Banner_pepsi.png';
const SodaStream_Web_Banner_xstream_energy = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/SodaStream_Web_Banner_xstream_energy.png';
const sodastream_web_banner_ginger_beer = 'https://res.cloudinary.com/brabenjos/image/upload/v1696927084/sodastream/images/sodastream_web_banner_ginger_beer.png';
const sodastream_web_banner_tonic = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/sodastream_web_banner_tonic.png';
const sodastream_web_banner_pepepsi_max = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/sodastream_web_banner_pepepsi_max.png';

const Page20BG = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Page20.png';
const Page20Img = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Page20Img.png';

const HomeBottomImage1 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/1.png';
const HomeBottomImage2 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/2.png';
const HomeBottomImage3 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/3.png';
const HomeBottomImage4 = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/4.png';


// Mixology/1
const FriendsgivingFizzImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Friendsgiving-Fizz_ef4117d7-36cf-41d4-a8ba-562a78d0c3d1_2x.png';
const RecraftedImg = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/re_crafted_7808_1ad304b3-4b6c-4e75-ad43-d481ada39722_2x.png';
const Slides211Img = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Slide_2_1_1_ad304656-1005-4f5f-a3e5-84f863d77931_2x.png';

const _21LPack = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/21LPack.png';
const TrioPack = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/TrioPack-min.png';

const pick_n_pay_logo = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Pick_n_Pay_logo.png';
const checkers_logo = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Checkers-Logo.png';
const game_logo = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/game-logo.png';
const dischem_logo = 'https://res.cloudinary.com/brabenjos/image/upload/v1696433365/sodastream/images/Dischem_Logo.png';

export const Images = {
    LogoGreen2,
    LogoDesign,
    ArtImg,
    ColoredWatterBottle,
    DeepBlueSodastreamRipple,
    FlavourTxt,
    FullGlass,
    GasTextImg,
    Glass,
    HomeBG1,
    HomeBG2,
    LogoLight,
    LogoGreen,
    StoreLocatorBg,
    WatterBottles,
    WatterBottleBlack,
    WatterBottleBlack2,
    SodastreamOrange,
    SodastreamLemon,
    SodastreamStrawberry,
    SodastreamWaterMellon,
    SodastreamDietDrPete,
    Slide,
    FriendsgivingFizz,
    PineappleDelightDuo,
    ReCrafted,
    RecommendationPageBG,
    // 
    JamImg,
    HappySourtHourImg,
    PearMangoTangoSmokelessArt,
    GinGinArt,

    PouringFromFuseBottle,
    MyOnlyBottleOutdoor,
    VideoBG,

    FamilyDinnerPourring,
    StarryLifestyle,
    FuseBottleWorkingWomen,
    BLACKMOB,
    CocktailBook,
    US_ARTWhiteHydrationBundle,
    Pack1,

    TerraBlack,
    SparklingWaterMakerBg,

    WaterMakerProductBg,
    US_ART_Black_Hydration_Bundle,
    DelivrarySteps,
    WatterMakerSectionBg,

    AccessoriesBg,
    DoubleBottles,
    SingleBottle,
    AccessoriesProductBg,
    DoubleBottles2,

    GroupRaisingGlasses,

    BlogBg,
    Benefits1GallonWater,
    StayHydratedPost,
    Post4Bg,
    Post2,

    Page6BG,
    FuseBottleImg,
    Group11Img,
    ReviewsImg,
    DeepBlueSodaStreamSmall,
    GroupEnjoyingSparklingWater,
    FuseBottle1,
    FullFrameCoupleMachine,
    ManWithBlackHat,
    WhiteArtMachine,
    BlogPostPageBg,
    CylinderUS,
    CylindersWithRippleImg,
    OrderSpareImg,
    ExchangeBottleSpareImg,
    Page26BG,

    Page27BG,
    CouplesDrinkingSparkilingWater,
    FuseBottleWithDinner,
    FuseBottleWithFood,
    Page16BG,
    PromoHeadline2,
    PromoHeadline3,
    Page24BG,

    TerraBlackNavbar,
    GasNavbar1,
    GasNavbar2,
    Navbar3,
    Navbar32,
    Navbar01,
    Navbar02,
    Navbar4,
    Navbar42,
    Navbar51,
    Navbar52,
    Navbar53,
    HomeRipple,

    TerraWhiteLeft,
    TerraWhiteFront,
    TerraWhiteRight,

    TerraBlackRight,
    TerraBlackLeft,
    TerraBlackFront,

    ArtWhiteLeft,
    ArtWhiteFront,
    ArtWhiteRight,

    ArtBlackRight,
    ArtBlackFront,
    ArtBlackLeft,

    ArtStep1,
    ArtStep2,
    ArtStep3,
    ArtStep4,

    Home_Orange,
    Home_Cranberry,
    Home_Fountain_Mist,
    Home_Ginger_Ale,
    Home_Lemonade,
    Home_Strawberry,
    HomeBG3,
    HomeBG4,
    PepsiLemonade,
    AutumnElixir,
    YouAJam,
    NewOldFashion,
    NewYearsSparkler,
    ManualImg,
    BottleClassic1L,
    XstreamEnergy,
    PepsiBev,

    Page19BG,
    Page18BG,

    BlackDuoImg,

    Page13BG,
    FuseBottleOutdoor,
    BlogBanners,
    BlogBanners1,
    BlogBanners4,
    SouthAfricaImg,
    MyOnlyBottleCheers,
    BlogBanners3,

    Page20BG,
    Page20Img,

    GroupedSodaStreamRipple,

    HomeBottomImage1,
    HomeBottomImage2,
    HomeBottomImage3,
    HomeBottomImage4,

    ProdExtremeEnergy,
    Prod7Up,
    Prod7UpFree,
    ProdClassicsCreamSoda,
    ProdCranberry,
    ProdGingerBeer,
    ProdGingerFlavour,
    ProdGrapeFruit,
    ProdIceTeaPeach,
    ProdLemonade,
    ProdMiranda,
    ProdPepsi,
    ProdPepsiMax,
    ProdTonic,
    ProdTonic2,
    ProdTonic3,
    

    SodaStream_Web_Banner_7up_free,
    SodaStream_Web_Banner_7up,
    SodaStream_Web_Banner_Cranberry,
    SodaStream_Web_Banner_cream_soda,
    SodaStream_Web_Banner_ginger_ale,
    SodaStream_Web_Banner_Grape_Fruit,
    SodaStream_Web_Banner_ice_tea_peach,
    SodaStream_Web_Banner_lemonade,
    SodaStream_Web_Banner_miranda,
    SodaStream_Web_Banner_pepsi_zero,
    SodaStream_Web_Banner_pepsi,
    SodaStream_Web_Banner_xstream_energy,
    sodastream_web_banner_ginger_beer,
    sodastream_web_banner_tonic,
    sodastream_web_banner_pepepsi_max,

    pick_n_pay_logo,
    checkers_logo,
    dischem_logo,
    game_logo,

    FriendsgivingFizzImg,
    RecraftedImg,
    Slides211Img,

    _21LPack,
    TrioPack
}

export const Icons = {
    UserIcon,
    IconBottle,
    IconCordless,
    IconPatentedButton,
    IconEgronomicButton,
    Icondishwasher,

    IconSustainability,
    IconFreshWater,
    IconGoodByeCarrying,
    IconHeathier,
    IconDishWasherSDS,

    IconSparklingSmall,
    IconQuickyEasy,
    IconQualityFirst,
    IconFreshCertified,
    IconGroup80,
    IconCo2Cylinder,

    IconRetailExchange,
    IconExchangeBottles,
    IconDoubleBottles,

    IconGetStarted,
    IconOrderSupport,
    IconBubbleTroubles,
    IconGasRefills,
    IconFlavoursAndBottles,
    IconStoreLocator,
    IconMail,
    IconCall,
    IconSupport,
    IconInstagram,
    IconYoutube,
    IconFacebook,
    IconCertification,
}


export const Videos = {

}