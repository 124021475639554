import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Navbar from "../../components/Navbar/Navbar";
import { AccessoriesClass } from "../../APIs";
import Loading from "../../components/Loading/Loading";
import { Helmet } from "react-helmet";
import { Images } from "../../assets/assets";
import Header from "../../components/Header/Header";
import RetailerModal from "../../components/RetailerModal/RetailerModal";
// TODO: change them
const AccessoriesProductPage = () => {
  const [quantity, setQuantity] = useState<number>(1);
  const [selectedAcc, setSelectedRAcc] = useState<any | undefined>(undefined);

  const breadcrumbItems = [
    { text: "HOME", href: "/" },
    { text: "ACCESSORIES", href: "/accessories" },
    { text: selectedAcc ? selectedAcc.name : "Loading..." },
  ];

  const { accessorylink } = useParams<{ accessorylink: string }>();
  const encodedUrl = accessorylink ? accessorylink.replace(/-/g, " ") : "";
  const fetchRecipeById = async (recipeName: any) => {
    try {
      const { data, error } = await AccessoriesClass.getAccessoryByLink(
        recipeName
      );

      if (data) {
        setSelectedRAcc(data);

        console.log("Recipe by ID:", data);
      } else {
        console.error("Error fetching Recipe by ID:", error);
      }
    } catch (error) {
      console.error("Error fetching Recipe by ID:", error);
    }
  };

  useEffect(() => {
    if (encodedUrl) {
      fetchRecipeById(encodedUrl);
    }
  }, [encodedUrl]);

  
  const capitalizeFirstLetter = (str:string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  function toUrlSlug(text: string) {
    const decoded = decodeURIComponent(text);
    const hyphenated = decoded.replace(/\s+/g, "-");
    return hyphenated;
  }
// 

  return (
    <>
      <>
        <div>
          {selectedAcc ? (
            <>
              <Helmet>
                <meta charSet="utf-8" />
                <title>{selectedAcc.meta_title}</title>
                <meta
                  name="description"
                  content={selectedAcc.meta_description}
                />
                  <link rel="canonical" href={`https://www.sodastream.co.za/${toUrlSlug(selectedAcc.accessorylink)}`} />
               
              </Helmet>
              <Header
                title={capitalizeFirstLetter(selectedAcc.name)}
                textBackground="ACCESSORIES"
                backgroundImg={Images.AccessoriesBg}
              />
              <div>
              
               
                <section>
                  <div className="bg-[#FFFFFF] text-start px-20">
                    <Breadcrumb items={breadcrumbItems} />
                  </div>
                  <div className="flex px-20 py-5 pb-20 gap-5 bg-[#FFFFFF] md:flex-row flex-col">
                    <div className="basis-[50%] flex flex-col items-center justify-start">
                      <img
                        className="mt-5"
                        src={selectedAcc.image}
                        alt={selectedAcc.name}
                        style={{
                          maxWidth: "20rem",
                          maxHeight: "20rem",
                          minHeight: "10rem",
                          minWidth: "10rem",
                        }}
                      />
                    </div>
                    {/*  */}
                    <div className="basis-[50%] flex flex-col items-center text-start">
                      <div className="flex flex-col gap-4 text-[#005D6E]">
                        <h2 className="text-[#005D6E] text-[24px]">
                          {capitalizeFirstLetter(selectedAcc.name)}
                        </h2>
                        {/* <p className='text-[#005D6E] text-start text-[20px]'>R250</p> */}
                        <p>{selectedAcc.description}</p>
                        <p>{selectedAcc.caption}</p>
                        <section  className="flex md:flex-row flex-col gap-4">

                  <div className="mr-2">
                    <Link to="/store-locator">
                      <SecondaryButton label={"Store Locator"} />
                    </Link>
                  </div>
                  <div>
                    <RetailerModal/>
                  </div>
                  </section>
                      </div>
                    </div>
                  </div>
                </section>
                <Footer />
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </>
    </>
  );
};

export default AccessoriesProductPage;