import React, { useState, useEffect } from 'react';
import Slider from '../../components/Slider/Slider';
import Navbar from '../../components/Navbar/Navbar';
import { Images } from '../../assets/assets';

const Header = ({ title, textBackground, backgroundImg, isHalf, subTitle }: { title?: string, subTitle?: string, textBackground?: string, isHalf?: boolean, backgroundImg?: string }) => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  return (
    <div className={`${isHalf ? 'md:h-[60vh]' : 'md:h-[75vh]'}  mb-0 overflow-hidden`}>
    <div className=' z-100 flex relative w-full '>
      {screenSize.width < 1060 && <Navbar />}
    </div>
  
    {/* Slider component with a background image */}
    <Slider images={[backgroundImg ? backgroundImg : Images.StoreLocatorBg]} />
  
    {/* Container for text content */}
    <div className="overflow-hidden whitespace-nowrap ">
      {/* Conditional rendering of text based on screen width */}
      <p
        style={{
          marginTop: screenSize.width > 1060 ? '2%' : '-45%',
        }}
        className=" w-auto md:w-[90px]mt-[-10%] absolute top-45 md:top-1/4 left-4 text-left font-black text-[45px] md:text-[140px]
         md:leading-[95px] tracking-normal text-[#75A7AD] uppercase opacity-40 font-dm-sans z-0 font-poppins"
      >
        {textBackground}
      </p>
    </div>
    
    {/* Container for additional content, including Navbar */}
    <div className='absolute md:z-20 top-40 md:top-0 md:left-0 w-full'>
      {/* Conditional rendering of Navbar based on screen width */}
      {screenSize.width > 1060 && <Navbar />}
  
      {/* Container for title and subtitle */}
      <div className='md:mt-[80px] ml-[10%] flex flex-col '>
        {/* Conditional rendering of title based on screen width */}
        <h1
          style={{
            marginTop: screenSize.width > 1060 ? '3%' : '5%',
          }}
          className="text-left text-[30px] mt-[-15%] md:text-[40px] tracking-normal
           text-white opacity-100 font-bold ml-[-5%] font-poppins"
        >
          {title}
        </h1>
  
     
        <p style={{ marginTop: '10%' }} className="text-left text-[15px] md:text-[20px] tracking-normal
         text-white opacity-100 font-semibold ">
          {subTitle}
        </p>
      </div>
    </div>
  </div>
  
  )
};

export default Header;