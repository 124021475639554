import React from 'react';
import CommentIdentity from './../CommentIdentity/CommentIdentity';
import CommentContent from './../CommentContent/CommentContent';
import CommentFooter from './../CommentFooter/CommentFooter';

interface CommentData {
  name: string;
  isVerified: boolean;
  rating: number;
  date: string;
  message: string;
  numLikes: number;
  numDislikes: number;
}

interface CommentCardProps {
  commentData: CommentData;
}

const CommentCard: React.FC<CommentCardProps> = ({ commentData }) => {
  return (
    <div className='border-t border-gray-300 w-full text-start flex flex-col gap-4 pt-4 mt-10'>
      <CommentIdentity 
        name={commentData.name}
        isVerified={commentData.isVerified}
        rating={commentData.rating}
        date={commentData.date}
      />
      <CommentContent 
        message={commentData.message}
      />
      <CommentFooter 
        numLikes={commentData.numLikes}
        numDislikes={commentData.numDislikes}
      />
    </div>
  );
};

export default CommentCard;
