import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  useRef,
} from "react";
import { IsLoadingButton, SubmitButton } from "../../Loading/ButtonLoading";
import { labelStyle, WaterMachine } from "../../../@types/productInfo";
import { ImageUploadUtil } from "../../../APIs";
interface FormComponentProps {
  isUpdate?: boolean;
  isUpdateDelete?: boolean;
  onClick?: (e: any) => void;
  formData: WaterMachine | (() => WaterMachine);
  onSubmit: (data: WaterMachine) => Promise<void>;
}

const WaterMachineForm: React.FC<FormComponentProps> = ({
  isUpdate = false,
  formData,
  isUpdateDelete = false,
  onClick,
  onSubmit,
}) => {
  const isMounted = useRef(true);
  const [localFormData, setLocalFormData] = useState<WaterMachine>(() => {
    if (typeof formData === "function") {
      return formData();
    }
    return formData;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPercentage, setShowPercentage] = useState(false);
  const [showPercentagewhite, setShowPercentagewhite] = useState(false);
  const [showPercentagevideo, setShowPercentagevideo] = useState(false);
  useEffect(() => {
    setLocalFormData((prevData) => {
      if (typeof formData === "function") {
        return formData();
      }
      return {
        ...prevData,
        ...formData,
      };
    });
  }, [formData]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      handleFileChangeImage(e as ChangeEvent<HTMLInputElement>);
    } else {
      // Handle other input changes
      setLocalFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setLocalFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setShowPercentage(true);
    if (files && files.length > 0) {
      setShowPercentage(true);
      try {
        const uploadedImages = await Promise.all(
          Array.from(files).map(async (file) => {
            const uploadedImage = await ImageUploadUtil.uploadImage(file);
            if (uploadedImage.data) {
              console.log("Uploaded black images:", uploadedImage.data);
              setShowPercentage(false);
              return uploadedImage.data;
            } else {
              console.error(
                "Error uploading black images:",
                uploadedImage.error
              );
              return null;
            }
          })
        );

        const imagesArray = uploadedImages.filter(
          (image) => image !== null
        ) as string[];

        setLocalFormData((prevData) => ({
          ...prevData,
          images_black: imagesArray,
        }));
      } catch (error) {
        console.error("Error uploadingblack images:", error);
      }
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setShowPercentagewhite(true);
    if (files && files.length > 0) {
      setShowPercentagewhite(true);
      try {
        const uploadedImages = await Promise.all(
          Array.from(files).map(async (file) => {
            const uploadedImage = await ImageUploadUtil.uploadImage(file);
            if (uploadedImage.data) {
              console.log("Uploaded white images:", uploadedImage.data);
              setShowPercentagewhite(false);
              return uploadedImage.data;
            } else {
              console.error(
                "Error uploading white images:",
                uploadedImage.error
              );
              return null;
            }
          })
        );

        const imagesArray = uploadedImages.filter(
          (image) => image !== null
        ) as string[];

        setLocalFormData((prevData) => ({
          ...prevData,
          images_white: imagesArray,
        }));
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    await onSubmit(localFormData);
    setIsLoading(false);
  };

  const handleFileChangeVideo = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setShowPercentagevideo(true);
    if (file) {
      setShowPercentagevideo(true);
      try {
        const uploadedImage = await ImageUploadUtil.uploadImage(file);

        if (uploadedImage.data) {
          console.log("Uploaded video:", uploadedImage.data);
          setShowPercentagevideo(false);
          setLocalFormData((prevData) => ({
            ...prevData,
            video: uploadedImage.data,
          }));
        } else {
          console.error("Error uploading video:", uploadedImage.error);
        }
      } catch (error) {
        console.error("Error uploading video:", error);
      }
    }
  };

  const handleRemoveVideo = () => {
    setLocalFormData({ ...localFormData, video: "" });
  };

  const handlePreview = () => {
    const previewUrl = `/water-machine-preview?data=${encodeURIComponent(
      JSON.stringify(localFormData)
    )}`;
    window.open(previewUrl, "_blank");
  };

  const label = isUpdateDelete ? "Delete Water Machine" : "Cancel";
  return (
    <div className="mt-[-3%]">
      <section>
        <form>
          <div className="flex flex-col space-y-4  ">
            <div className="flex items-center space-x-4">
              <div className="flex flex-col w-full">
                <label
                  style={labelStyle as React.CSSProperties}
                  htmlFor="Product Name"
                  className="text-gray-600 font-bold text-sm"
                >
                  Machine Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={localFormData.name}
                  onChange={handleChange}
                  placeholder="Water Machine Name"
                  required
                  className="w-full px-3 py-2 border text-black rounded-[0.50rem] border-gray-400 bg-white"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full mt-[12px]">
            <div className="flex flex-col w-full ">
              <label
                style={labelStyle as React.CSSProperties}
                htmlFor="firstName"
                className="text-gray-600 font-bold text-sm"
              >
                Description
              </label>
              <textarea
                name="description"
                value={localFormData.description}
                onChange={handleChangeTextArea}
                placeholder="Describe the water machine"
                required
                className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
              />
            </div>

            <div className=" items-center ">
              <div className="flex flex-col w-full">
                <label
                  style={labelStyle as React.CSSProperties}
                  htmlFor="Meta Title"
                  className="text-gray-600 font-bold text-sm"
                >
                  Meta Title
                </label>
                <input
                  type="text"
                  id="meta_title"
                  name="meta_title"
                  value={localFormData.meta_title}
                  onChange={handleChange}
                  placeholder="Water Machine Link"
                  required
                  className="w-full px-3 py-2 mb-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
                />
              </div>
            </div>
            <div className="flex flex-col w-full mt-[12px]">
              <div className="flex flex-col w-full ">
                <label
                  style={labelStyle as React.CSSProperties}
                  htmlFor="firstName"
                  className="text-gray-600 font-bold text-sm"
                >
                  Meta Description
                </label>
                <textarea
                  name="meta_description"
                  value={localFormData.meta_description}
                  onChange={handleChangeTextArea}
                  placeholder="Describe the product"
                  required
                  className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  style={labelStyle as React.CSSProperties}
                  htmlFor="Meta Title"
                  className="text-gray-600 font-bold text-sm"
                >
                  Water Machine Link
                </label>
                <input
                  type="text"
                  id="meta_title"
                  name="machinelink"
                  value={localFormData.machinelink}
                  onChange={handleChange}
                  placeholder="Water Machine Link"
                  required
                  className="w-full px-3 py-2 mb-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
                />
              </div>
            </div>

            <label
              style={labelStyle as React.CSSProperties}
              htmlFor="firstName"
              className="text-gray-600 font-bold text-sm mt-[4%]"
            >
              Black Images
            </label>

            <input
              className="block w-full text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              id="dropzone-file-1"
              type="file"
              accept="image/*"
              onChange={handleFileChangeImage}
              multiple
            />
          </div>

          <div
            style={{
              height: "118px",
              width: "100%",
              maxHeight: "120px",
              overflowY: "auto",
            }}
            className="block rounded-lg bg-white mt-[3%] shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700"
          >
            <div
              className="overflow-hidden"
              style={{ maxHeight: "370px", overflowY: "auto" }}
            >
              {localFormData.images_black.length > 0 ? (
                <ul className="max-w-md divide-y divide-gray-200 dark:divide-gray-700 flex flex-wrap flex-wrap-wrap overflow-y-auto">
                  {localFormData.images_black.map((black, index) => (
                    <div key={index} className="w-1/4 p-2">
                      <div className="flex items-center">
                        <p className="text-xs font-medium text-gray-900 truncate dark:text-white">
                          <img
                            src={black}
                            alt=""
                            className="object-cover border border-solid border-white"
                            style={{
                              height: "100px",
                              width: "100%",

                              overflowY: "auto",
                            }}
                          />
                        </p>
                      </div>
                      <div className="flex items-center"></div>
                    </div>
                  ))}
                </ul>
              ) : (
                <p className=" mt-[10%]  text-black font-bold">
                  {showPercentage && (
                    <div className="flex gap-2  mt-2 justify-center mb-4 ">
                      <div className="w-3 h-3 rounded-full animate-pulse bg-teal-600"></div>
                      <div className="w-3 h-3 rounded-full animate-pulse bg-teal-600"></div>
                      <div className="w-3 h-3 rounded-full animate-pulse bg-teal-600"></div>
                    </div>
                  )}
                  No black Images uploaded yet
                </p>
              )}
            </div>
          </div>

          <div className="flex flex-col w-full mt-[12px]">
            <label
              style={labelStyle as React.CSSProperties}
              htmlFor="firstName"
              className="text-gray-600 font-bold text-sm mt-[4%]"
            >
              White Images
            </label>
            <input
              className="block w-full text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              id="dropzone-file-1"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              multiple
            />
          </div>

          <div
            style={{
              height: "118px",
              width: "100%",
              maxHeight: "120px",
              overflowY: "auto",
            }}
            className="block  bg-white mt-[3%] 
              shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]
               dark:bg-neutral-700"
          >
            <div>
              {localFormData.images_white.length > 0 ? (
                <ul className="max-w-md divide-y divide-gray-200 dark:divide-gray-700 flex flex-wrap flex-wrap-wrap overflow-y-auto">
                  {localFormData.images_white.map((white, index) => (
                    <div key={index} className="w-1/4 p-2">
                      <div className="flex items-center">
                        <p className="text-xs font-medium text-gray-900 truncate dark:text-white">
                          <img
                            src={white}
                            alt=""
                            className="object-cover border border-solid"
                            style={{
                              height: "100px",
                              width: "100%",
                              maxHeight: "270px",
                              overflowY: "auto",
                            }}
                          />
                        </p>
                      </div>
                      <div className="flex items-center"></div>
                    </div>
                  ))}
                </ul>
              ) : (
                <p className="mt-[10%]  text-black font-bold">
                  {showPercentagewhite && (
                    <div className="flex gap-2  mt-2 justify-center mb-4 ">
                      <div className="w-3 h-3 rounded-full animate-pulse bg-teal-600"></div>
                      <div className="w-3 h-3 rounded-full animate-pulse bg-teal-600"></div>
                      <div className="w-3 h-3 rounded-full animate-pulse bg-teal-600"></div>
                    </div>
                  )}
                  No white images uploaded yet
                </p>
              )}
            </div>
          </div>

          <div className="flex flex-col w-full mt-[2px] ">
            <label
              style={labelStyle as React.CSSProperties}
              htmlFor="Product Name"
              className="text-gray-600 m-3 font-bold text-sm"
            >
              Video Preview
            </label>
            {showPercentagevideo && (
              <div className="flex gap-2 mb-4 mt-2">
                <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
              </div>
            )}
            <input
              className="block w-full text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              id="dropzone-file-1"
              type="file"
              accept="video/*"
              onChange={handleFileChangeVideo}
              multiple
            />
          </div>

          <div className="block rounded-l dark:bg-neutral-700">
            <div
              className="block rounded-lg bg-white mt-[2%] h-[140px]
             shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700"
            >
              <ul>
                <div className="p-2">
                  <div className="flex items-center">
                    <p className="text-xs font-medium text-gray-900 truncate dark:text-white">
                      <div className="p-1 w-full ">
                        <div
                          className="block rounded-lg
                           dark:bg-neutral-700"
                        >
                          <div>
                            {localFormData.video ? (
                              <div>
                                <video controls width="180" height="70">
                                  <source
                                    src={localFormData.video}
                                    type="video/mp4"
                                  />
                                </video>
                                <p
                                  className=" mt-[5px] justify-start text-left  text-red-800 cursor-pointer underline text-sm"
                                  onClick={handleRemoveVideo}
                                >
                                  Change Video
                                </p>
                              </div>
                            ) : (
                              <p className=" p-2 overflow-hidden z-50 mt-[11%] bg-white ml-[120px] text-s text-center justify-center text-black font-bold">
                                No video uploaded yet
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </p>
                  </div>
                  <div className="flex items-center"></div>
                </div>
              </ul>
            </div>
          </div>

          <div>
            <div className=" mt-[1px]">
              {!isLoading ? (
                <SubmitButton
                  type="submit"
                  onClick={handleFormSubmit}
                  disabled={isLoading}
                  label={
                    isUpdate ? "Update Water Machine" : "Add Water Machine"
                  }
                />
              ) : (
                <IsLoadingButton type="submit" label="Loading..." />
              )}
              <button
                onClick={onClick}
                type="button"
                className="bg-white w-full mt-[7px]
            hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border
             border-gray-400 rounded shadow"
              >
                {label}
              </button>
              <button
                onClick={handlePreview} // Appeler handlePreview lors du clic sur ce bouton
                type="button"
                className="bg-white w-full mt-[7px]
            hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border
             border-gray-400 rounded shadow"
              >
                Preview Water Machine
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default WaterMachineForm;
