
import React, { useContext, useEffect, useCallback } from 'react';
import { Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../AuthContext/AuthContext';

interface ProtectedRouteProps {
  element: React.ReactNode;
  path: string;
  rest?: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, path, rest }): any => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  // const handleNotLoggedIn = useCallback(() => {
  //   const returnUrl = btoa(`${location.pathname}${location.search}`);
  //   navigate(returnUrl);
  // }, [navigate, location.pathname, location.search]);

  const handleNotLoggedIn = useCallback(() => {
    const returnUrl = `${location.pathname}${location.search}`;
    navigate(returnUrl);
  }, [navigate, location.pathname, location.search]);
 

  useEffect(() => {
    if (!user) {
      handleNotLoggedIn();
    }
  }, [user, handleNotLoggedIn]);

  return user ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
