import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import "./style.css";

interface MapConProps {
  markers: any[];
  center: { lat: number; lng: number };
}

const MapCon: React.FC<MapConProps> = ({ markers, center }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB1rIsa_A5keNvPCSqAzVsY92LgLRmfTmI",
    libraries: ["places"]
  });

  useEffect(() => {
    if (isLoaded) {
      setMapLoaded(true);
    }
  }, [isLoaded]);

  const mapContainerStyle = {
    width: "90%",
    height: "400px",
    borderRadius: '1%',
    margin: '10px',
    marginLeft: '20px',
    positision:'relative'
  

  };

  if (!mapLoaded) return <div>Loading Maps...</div>;

  console.log("Center:", center);
  console.log("Markers:", markers);

  return (
   <div>
     <GoogleMap mapContainerStyle={mapContainerStyle} zoom={10} center={center}>
      {markers.map(({ lat, lng }) => (
        <Marker key={`${lat}-${lng}`} position={{ lat: parseFloat(lat), lng: parseFloat(lng) }} />
      ))}
    </GoogleMap>
   </div>
  );
};

export default MapCon;
