import React, { useState } from 'react';
import { FaUserCircle, FaShoppingCart, FaBook, FaUtensils } from 'react-icons/fa';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import Blogs from './blogs/Blogs';
import Store from './store/Store';
import Recipes from './recipes/Recipes';
import Cascylinder from './cascyllinder/Cascylinder';
import Watermachines from './watermachines/Watermachines';

import Accessories from './accessories/Accessories';
import Drawer from 'react-modern-drawer';
import { HiOutlineUser, HiMenu, HiOutlineLocationMarker } from 'react-icons/hi';
import { BsPerson } from 'react-icons/bs';
import { Images } from '../../assets/assets';
import Sidebar from './Sidebar/Sidebar';
import Dashroutes from './Routes/Dashroutes';
function Dashboard() {

  
  const [toggle, setToggle] = React.useState(false);

  const toggleDrawer = () => {
      console.log(toggle)
      setToggle((prevState) => !prevState)
  }

  
  return (

    <>
<div className='min-h-screen flex flex-col md:flex-row'>

  <nav className='bg-white shadow-sm p-6 space-y-6 w-64
   hidden md:block flex-none'> 
    <Sidebar/> 
  </nav>
  <Drawer
    open={toggle}
    onClose={toggleDrawer}
    direction='left'
    className='flex-none'  
  >
    <div className='float-left ml-1 font text-[#005D6E] 
    cursor-pointer text-lg' onClick={toggleDrawer}>x</div>
    <Sidebar />
  </Drawer>

  <div className="md:hidden z-50 block w-full items-center">
    <div className="flex-col">
      <nav className='bg-white w-[100%] pr-0 md:hidden'>
        <div className="flex w-[100%] justify-between">
          <div className="">
            <div className='z-20 h-[100%]'>
              <button onClick={toggleDrawer} className="text-white p-5 flex justify-center bg-[#75A7AD] h-[100%] ">
                <HiMenu fontSize={30} />
              </button>
            </div>
          </div>
          <div className="">
            <div className='w-[225px]'>
              <Link to="/">
                <img src={Images.LogoGreen} alt="logo" className="w-full h-full" />
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <main className='bg-gray-100  p-2 flex-auto'> 
    <Dashroutes/>
  </main>
</div>
</>
    
  );
}

export default Dashboard;





