import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  useRef,
} from "react";
import { IsLoadingButton, SubmitButton } from "../../Loading/ButtonLoading";
import { labelStyle } from "../../../@types/productInfo";
import { ImageUploadUtil } from "../../../APIs";
import { AccessoryData } from "../../../@types/productInfo";

interface FormComponentProps {
  isUpdate?: boolean;
  onClick?: (e: any) => void;
  isUpdateDelete?: boolean;
  formData: AccessoryData | (() => AccessoryData);
  onSubmit: (data: AccessoryData) => Promise<void>;
}

const FormComponent: React.FC<FormComponentProps> = ({
  isUpdate = false,
  formData,
  isUpdateDelete = false,
  onClick,
  onSubmit,
}) => {
  const isMounted = useRef(true);
  const [localFormData, setLocalFormData] = useState<AccessoryData>(() => {
    if (typeof formData === "function") {
      return formData();
    }
    return formData;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<string>("");
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [showPercentage, setShowPercentage] = useState(false);
  const [percentage, setPercentage] = useState(35);

  const handlePreview = () => {
    const previewUrl = `/accessory-preview?data=${encodeURIComponent(
      JSON.stringify(localFormData)
    )}`;
    window.open(previewUrl, "_blank");
  };

  useEffect(() => {
    setLocalFormData((prevData) => {
      if (typeof formData === "function") {
        return formData();
      }
      return {
        ...prevData,
        ...formData,
      };
    });
  }, [formData]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      handleFileChangeImage(e as ChangeEvent<HTMLInputElement>);
    } else {
      // Handle other input changes
      setLocalFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setLocalFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setPercentage(0);
    setShowPercentage(true);
    if (file) {
      setPercentage(100);
      setShowPercentage(true);
      try {
        const uploadedImage = await ImageUploadUtil.uploadImage(file);
        setShowPercentage(false);
        if (uploadedImage.data) {
          console.log("Uploaded image:", uploadedImage.data);
          setLocalFormData((prevData) => ({
            ...prevData,
            image: uploadedImage.data,
          }));

          setPreviewImage(uploadedImage.data);
        } else {
          console.error("Error uploading image:", uploadedImage.error);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    console.log("Image state:", image);
  }, [image]);

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const lowercaseAccessory = localFormData.name.toLowerCase();
    const updatedFormData = {
      ...localFormData,
      name: lowercaseAccessory,
    };

    setIsLoading(true);
    await onSubmit(updatedFormData);
    setIsLoading(false);
  };

  const label = isUpdateDelete ? "Delete Accessory" : "Cancel";
  return (
    <div className="mt-[3%]">
      <div className="flex flex-col w-full">
        <label
          htmlFor="name"
          className="text-gray-600 font-bold text-sm"
          style={labelStyle as React.CSSProperties}
        >
          Accessory Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={localFormData.name}
          onChange={handleChange}
          placeholder="Accessory Name"
          required
          className="w-full px-3 mb-5 py-2 border text-black rounded-[0.50rem] border-gray-400 bg-white"
        />
      </div>

      <div className="flex flex-col w-full">
        <label
          htmlFor="caption"
          className="text-gray-600 font-bold text-sm"
          style={labelStyle as React.CSSProperties}
        >
          Caption
        </label>
        <input
          type="text"
          id="caption"
          name="caption"
          value={localFormData.caption}
          onChange={handleChange}
          placeholder="Caption"
          required
          className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
        />
      </div>

      <div className="flex flex-col w-full mt-[12px]">
        <label
          htmlFor="description"
          className="text-gray-600 font-bold text-sm"
          style={labelStyle as React.CSSProperties}
        >
          Description
        </label>

        <textarea
          name="description"
          value={localFormData.description}
          onChange={handleChangeTextArea}
          placeholder="Describe the accessory"
          required
          className="w-full px-3 py-2 border mb-3 rounded-[0.50rem] text-black border-gray-400 bg-white"
        />
      </div>

      <div className=" items-center ">
        <div className="flex flex-col w-full">
          <label
            style={labelStyle as React.CSSProperties}
            htmlFor="Meta Title"
            className="text-gray-600 font-bold text-sm"
          >
            Meta Title
          </label>
          <input
            type="text"
            id="meta_title"
            name="meta_title"
            value={localFormData.meta_title}
            onChange={handleChange}
            placeholder="Caption"
            required
            className="w-full px-3 py-2 mb-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
          />
        </div>
      </div>


      <div className=" items-center ">
        <div className="flex flex-col w-full">
          <label
            style={labelStyle as React.CSSProperties}
            htmlFor="Meta Title"
            className="text-gray-600 font-bold text-sm"
          >
            Accessory URL
          </label>
          <input
            type="text"
            id="meta_title"
            name="accessorylink"
            value={localFormData.accessorylink}
            onChange={handleChange}
            placeholder="Caption"
            required
            className="w-full px-3 py-2 mb-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
          />
        </div>
      </div>

      <div className="flex flex-col w-full mt-[12px]">
        <div className="flex flex-col w-full ">
          <label
            style={labelStyle as React.CSSProperties}
            htmlFor="firstName"
            className="text-gray-600 font-bold text-sm"
          >
            Meta Description
          </label>
          <textarea
            name="meta_description"
            value={localFormData.meta_description}
            onChange={handleChangeTextArea}
            placeholder="Describe Meta Description"
            required
            className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
          />
        </div>
      </div>

      <div className="flex justify-between space-x-4 ">
        {localFormData.image && (
          <div className="w-[50%] float-left mt-[5%] items-center justify-center h-[120px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 ">
            <div className="p-4 w-full">
              <div className="w-auto">
                <div className=" py-0 rounded justify-center ">
                  <a href="#">
                    <img
                      src={localFormData.image || ""}
                      alt=""
                      style={{
                        width: "auto",
                        maxHeight: "100px",
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="w-[50%] h-[auto] float-right">
          <label
            htmlFor="Ingredient"
            style={labelStyle as React.CSSProperties}
            className="text-gray-600 font-bold text-sm mb-2"
          >
            Image
          </label>

          <div className="flex justify-center w-full mb-[2%]">
            <label
              htmlFor="dropzone-file-1"
              className="flex flex-col items-center justify-center w-full h-[120px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pt-2 pb-3">
                {showPercentage && (
                  <div className="flex gap-2 mb-4 mt-2">
                    <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                    <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                    <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                  </div>
                )}
                <svg
                  className="w-6 h-6 mb-2 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-1 text-xss text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop icon
                </p>
              </div>

              <input
                id="dropzone-file-1"
                type="file"
                onChange={handleFileChangeImage}
                className="hidden"
              />
            </label>
          </div>
        </div>
      </div>

      <div>
        <div className="mt-[25px]">
          {!isLoading ? (
            <SubmitButton
              type="submit"
              onClick={handleFormSubmit}
              disabled={isLoading}
              label={isUpdate ? "Update Accessory" : "Add Accessory"}
            />
          ) : (
            <IsLoadingButton type="submit" label="Loading..." />
          )}
          <button
            onClick={onClick}
            type="button"
            className="bg-white w-full mt-[7px]
            hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border
             border-gray-400 rounded shadow"
          >
            {label}
          </button>
          <button
            onClick={handlePreview}
            type="button"
            className="bg-white w-full mt-[7px]
            hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border
             border-gray-400 rounded shadow"
          >
            Preview Accessory
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormComponent;
