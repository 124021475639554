import React, {  useState } from 'react';
import { Images } from '../../assets/assets';

export {}

const ForgotPassword: React.FC = () => {
  // Define the UserData type
  type UserData = {
    password: string;
    confirmPassword: string;
    token: string | null;
  };

  // State variables for form data, errors, and user context
  const [formData, setFormData] = useState<UserData>({  password: '', confirmPassword: '', token: null });
  const [errors, setErrors] = useState<{  password?: string; confirmPassword?: string }>({});

  // State variable for confirm password and its error
  const [confirmPassword, setConfirmPassword] = useState<string>('');
// Function to handle the sign-in process


const handleForgotPassword = async () => {
    // Input validation
    const newErrors: { password?: string; confirmPassword?: string } = {};

    if (!formData.password) {
      newErrors.password = 'Please enter a password.';
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = 'Please confirm your password.';
    } else if (confirmPassword !== formData.password) {
      newErrors.confirmPassword = 'Passwords do not match.';
    }

    // Check if there are validation errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Clear previous errors
    setErrors({});

    
  };


  return (
    <div className="min-h-screen py-10">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row w-11/12 lg:w-10/12 bg-white rounded-xl mx-auto 
        shadow-lg overflow-hidden">
          <div
            className="w-full lg:w-1/2 flex flex-col items-center p-8 bg-no-repeat bg-cover bg-center relative"
            style={{
              backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/cars-rental-2a2e9.appspot.com/o/loginpage.png?alt=media&token=ca2ca663-df5a-4e31-af2f-48bdb1fe07d3')",
            }}
          ></div>
{errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
          <div className="w-full lg:w-1/2 py-8 px-8 bg-white">
          <h3 className="text-xl lg:text-3xl font-semibold text-purple-500">CREATE NEW PASSWORD</h3>

            <img
              src={Images.LogoGreen2}
              alt=""
              className="w-40 h-40 object-contain mx-auto block"
            />
            <form>
           
              <div className="mt-3">
                <label  style={labelStyle as React.CSSProperties} htmlFor="password" className="text-gray-600 font-bold mr-2 text-sm">
                  Password
                </label>

                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  className="border rounded-[0.50rem] border-gray-400 py-1 px-2 w-full bg-white"
                />
              </div>
              <div className="mt-3">
                <label  style={labelStyle as React.CSSProperties}
                  htmlFor="confirmPassword"
                  className="text-gray-600 font-bold mr-2 text-sm"
                >
                    
                  Confirm Password
                </label>

                <input
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="border rounded-[0.50rem] border-gray-400 py-1 px-2 w-full bg-white"
                />
                {errors.confirmPassword && <p style={{ color: 'red' }}>{errors.confirmPassword}</p>}
              </div>
              <div className="flex items-center mt-4"></div>
              <div className="mt-3">
                <button
                  type="button"
                  onClick={ handleForgotPassword}
                  className="w-full bg-teal-800 py-2 text-center text-white rounded-[0.50rem] font-poppins"
                >
                    
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;


interface LabelStyle {
  color: string;
  fontFamily: string;
  fontSize: string;
  fontStyle: string;
  fontWeight: number;
  lineHeight: string;
  textAlign: string;
  float: string;
}

const labelStyle: LabelStyle = {
  color: '#515151',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  textAlign: 'start',
  float: 'left',
};

