import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  useRef,
} from "react";
import { IsLoadingButton, SubmitButton } from "../../Loading/ButtonLoading";
import { Product, labelStyle } from "../../../@types/productInfo";
import { ImageUploadUtil } from "../../../APIs";
import { truncate } from "fs/promises";
import { useNavigate } from "react-router-dom";

interface FormComponentProps {
  isUpdate?: boolean;
  formData: Product | (() => Product);
  onSubmit: (data: Product) => Promise<void>;
  onClick?: (e: any) => void;
  isUpdateDelete: boolean;
}

const ProductForm: React.FC<FormComponentProps> = ({
  isUpdate = false,
  formData,
  onClick,
  isUpdateDelete = false,
  onSubmit,
}) => {
  const isMounted = useRef(true);
  const [localFormData, setLocalFormData] = useState<Product>(() => {
    if (typeof formData === "function") {
      return formData();
    }
    return formData;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [percentage, setPercentage] = useState(35);
  const [showPercentage, setShowPercentage] = useState(false);
  const [showPercentageImage, setShowPercentageImage] = useState(false);

  const handlePreview = () => {
    const previewUrl = `/preview?data=${encodeURIComponent(
      JSON.stringify(localFormData)
    )}`;
    window.open(previewUrl, "_blank");
  };

  useEffect(() => {
    setLocalFormData((prevData) => {
      if (typeof formData === "function") {
        return formData();
      }
      return {
        ...prevData,
        ...formData,
      };
    });
  }, [formData]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      handleFileChangeImage(e as ChangeEvent<HTMLInputElement>);
    } else {
      // Handle other input changes
      setLocalFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setLocalFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setPercentage(0);
    setShowPercentageImage(true);

    if (file) {
      setPercentage(100);
      setShowPercentageImage(true);
      try {
        const uploadedImage = await ImageUploadUtil.uploadImage(file);

        setShowPercentageImage(false);
        if (uploadedImage.data) {
          console.log("Uploaded image:", uploadedImage.data);
          setLocalFormData((prevData) => ({
            ...prevData,
            image: uploadedImage.data,
          }));
        } else {
          console.error("Error uploading image:", uploadedImage.error);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setPercentage(0);
    setShowPercentage(true);

    const file = e.target.files?.[0];
    setPercentage(50);
    setShowPercentage(true);
    if (file) {
      try {
        setPercentage(100);
        setShowPercentage(true);

        const uploadedBanner = await ImageUploadUtil.uploadImage(file);

        if (uploadedBanner.data) {
          console.log("Uploaded banner:", uploadedBanner.data);

          setLocalFormData((prevData) => ({
            ...prevData,
            banner: uploadedBanner.data,
          }));
        } else {
          console.error("Error uploading banner:", uploadedBanner.error);
          setPercentage(0);
        }
      } catch (error) {
        console.error("Error uploading banner:", error);
      } finally {
        setShowPercentage(false);
      }
    }
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    console.log("Form data for submission:", localFormData);
    await onSubmit(localFormData);
    setIsLoading(false);
  };

  //remove image
  const handleRemoveImage = () => {
    setLocalFormData({ ...localFormData, image: "" });
  };

  //remove icon
  const handleRemoveBanner = () => {
    setLocalFormData({ ...localFormData, banner: "" });
  };

  const label = isUpdateDelete ? "Delete Product" : "Cancel";
  return (
    <div className="mt-[3%]">
      <section>
        <form>
          <div className="flex flex-col space-y-4 ">
            <div className="items-center">
              <div className="flex flex-col w-full">
                <label
                  style={labelStyle as React.CSSProperties}
                  htmlFor="Product Name"
                  className="text-gray-600 font-bold text-sm"
                >
                  Product Name
                </label>

                <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700"></div>

                <input
                  type="text"
                  id="name"
                  name="name"
                  value={localFormData.name}
                  onChange={handleChange}
                  placeholder="Product Name"
                  required
                  className="w-full px-3 py-2 mb-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
                />
              </div>

              <div className="flex flex-col w-full">
                <label
                  style={labelStyle as React.CSSProperties}
                  htmlFor="Ingredient"
                  className="text-gray-600 font-bold text-sm"
                >
                  Ingredient
                </label>
                <input
                  type="text"
                  id="ingredient"
                  name="ingredient"
                  value={localFormData.ingredient}
                  onChange={handleChange}
                  placeholder="Ingredient"
                  required
                  className="w-full px-3 py-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
                />
              </div>
            </div>

            <div className=" items-center ">
              <div className="flex flex-col w-full">
                <label
                  style={labelStyle as React.CSSProperties}
                  htmlFor="Product Name"
                  className="text-gray-600 font-bold text-sm"
                >
                  Caption
                </label>
                <input
                  type="text"
                  id="caption"
                  name="caption"
                  value={localFormData.caption}
                  onChange={handleChange}
                  placeholder="Caption"
                  required
                  className="w-full px-3 py-2 mb-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
                />
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <div className="flex flex-col w-1/2">
              <label
                style={labelStyle as React.CSSProperties}
                htmlFor="firstName"
                className="text-gray-600 mb-[-10px] font-bold text-sm"
              >
                Category
              </label>
              <div className=" items-center space-x-4 mt-[1%]">
                <div className="form-group gender text-gray-500 p-2 rounded-md w-full border-2">
                  <select
                    id="category"
                    name="category"
                    className="mt-1 block w-full rounded-md focus:outline-none text-black text-sm bg-white"
                    value={localFormData.category}
                    onChange={handleChange}
                  >
                    CLASSIC, PEPSI, ZERO, FRUIT
                    <option value="CLASSIC">CLASSIC</option>
                    <option value="PEPSI">PEPSI</option>
                    <option value="ZERO">ZERO</option>
                    <option value="FRUITS">FRUITS</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <label
                style={labelStyle as React.CSSProperties}
                htmlFor="firstName"
                className="text-gray-600 font-bold text-sm"
              >
                Price
              </label>
              <input
                type="number"
                id="price"
                name="price"
                value={localFormData.price}
                onChange={handleChange}
                placeholder="Price"
                required
                className="w-full px-3 py-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
              />
            </div>
          </div>

          <div className="flex flex-col w-full mt-[12px]">
            <div className="flex flex-col w-full ">
              <label
                style={labelStyle as React.CSSProperties}
                htmlFor="firstName"
                className="text-gray-600 font-bold text-sm"
              >
                Description
              </label>
              <textarea
                name="description"
                value={localFormData.description}
                onChange={handleChangeTextArea}
                placeholder="Describe the product"
                required
                className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
              />
            </div>
          </div>

          <div className="flex flex-col w-full mt-[12px]">
            <div className="flex flex-col w-full ">
              <label
                style={labelStyle as React.CSSProperties}
                htmlFor="firstName"
                className="text-gray-600 font-bold text-sm"
              >
                Meta Description
              </label>
              <textarea
                name="meta_description"
                value={localFormData.meta_description}
                onChange={handleChangeTextArea}
                placeholder="Describe the product"
                required
                className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
              />
            </div>
          </div>

          <div className=" items-center ">
            <div className="flex flex-col w-full">
              <label
                style={labelStyle as React.CSSProperties}
                htmlFor="Meta Title"
                className="text-gray-600 font-bold text-sm"
              >
                Meta Title
              </label>
              <input
                type="text"
                id="meta_title"
                name="meta_title"
                value={localFormData.meta_title}
                onChange={handleChange}
                placeholder="Caption"
                required
                className="w-full px-3 py-2 mb-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
              />
            </div>
          </div>

          <div className="flex flex-col w-full">
            <label
              style={labelStyle as React.CSSProperties}
              htmlFor="Product Name"
              className="text-gray-600 font-bold text-sm"
            >
              Product Link
            </label>

            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700"></div>

            <input
              type="text"
              id="productlinkname"
              name="productlinkname"
              value={localFormData.productlinkname}
              onChange={handleChange}
              placeholder="Product Name"
              required
              className="w-full px-3 py-2 mb-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
            />
          </div>

          <div className="flex items-center space-x-4 mt-[20px] mb-[-20px] ">
            <div className="flex flex-row w-1/2">
              <label
                style={labelStyle as React.CSSProperties}
                htmlFor="Ingredient"
                className="text-gray-600 font-bold text-sm mb-[-20px]"
              >
                Image
              </label>
            </div>
            <div className="flex flex-row w-1/2 justify-end">
              <label
                style={labelStyle as React.CSSProperties}
                htmlFor="Ingredient"
                className="text-gray-600 font-bold text-sm"
              >
                Banner
              </label>
            </div>
          </div>

          <div className="flex flex-row w-full justify-between mt-[20px] space-x-2">
            {/* Upload Icon */}
            <div className="flex justify-center w-1/2 ">
              <div
                className="flex flex-col items-center justify-center
               w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                {localFormData.image ? (
                  <>
                    <div className="p-4 w-full">
                      <div className="w-auto mt-[5%]">
                        <div className=" py-0 rounded justify-center mt-8">
                          <a href="#">
                            <img
                              src={localFormData.image}
                              alt=""
                              style={{
                                width: "auto",
                                maxHeight: "100px",
                              }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        textAlign: "left",
                        justifyContent: "flex-start",
                      }}
                    >
                      <p
                        style={{ textAlign: "start", justifyContent: "start" }}
                        className=" cursor-pointer text-red-800 underline text-sm mt-[5%] ml-[-70%] justify-start text-left"
                        onClick={handleRemoveImage}
                      >
                        Change Image
                      </p>
                    </div>
                  </>
                ) : (
                  <label
                    htmlFor="dropzone-file-1"
                    className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-2 pb-3">
                      {showPercentageImage && (
                        <div className="flex gap-2 mb-4 mt-2">
                          <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                          <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                          <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                        </div>
                      )}
                      <svg
                        className="w-6 h-6 mb-2 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-1 text-xss text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop Image
                      </p>
                    </div>
                    <input
                      id="dropzone-file-1"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChangeImage}
                      className="hidden"
                    />
                  </label>
                )}
              </div>
            </div>

            {/* Upload Image*/}
            <div className="flex items-center justify-center w-1/2 space-x-4 ">
              <div className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                {localFormData.banner ? (
                  <>
                    <div className="p-4 w-full">
                      <div className="w-auto mt-[5%]">
                        <div className=" py-0 rounded justify-center mt-8">
                          <a href="#">
                            <img
                              src={localFormData.banner}
                              alt=""
                              style={{
                                width: "auto",
                                maxHeight: "100px",
                                justifyContent: "center",
                              }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ textAlign: "right", justifyContent: "flex-end" }}
                    >
                      <p
                        style={{ textAlign: "end", justifyContent: "end" }}
                        className=" cursor-pointer text-red-800 underline text-sm mt-[9%] mr-[-60%] justify-end
                       text-right"
                        onClick={handleRemoveBanner}
                      >
                        Change Banner
                      </p>
                    </div>
                  </>
                ) : (
                  <label
                    htmlFor="dropzone-file-2"
                    className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-2 pb-1">
                      {showPercentage && (
                        <div className="flex gap-2 mb-4 mt-2">
                          <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                          <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                          <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                        </div>
                      )}
                      <svg
                        className="w-6 h-6 mb-2 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-1 text-xss text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop Banner
                      </p>
                    </div>
                    <input
                      id="dropzone-file-2"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden"
                    />
                  </label>
                )}
              </div>
            </div>
          </div>

          <div className=" mt-[35px]">
            {!isLoading ? (
              <SubmitButton
                type="submit"
                onClick={handleFormSubmit}
                disabled={isLoading}
                label={isUpdate ? "Update Product" : "Add Product"}
              />
            ) : (
              <IsLoadingButton type="submit" label="Loading..." />
            )}
            <button
              onClick={onClick}
              type="button"
              className="bg-white w-full mt-[7px]
            hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border
             border-gray-400 rounded shadow"
            >
              {label}
            </button>
            <button
              onClick={handlePreview}
              type="button"
              className="bg-white w-full mt-[7px] hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
            >
              Preview Product
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default ProductForm;
