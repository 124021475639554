import React, { useState, useEffect } from 'react';
import { CgPlayButtonO } from 'react-icons/cg';

const PlainVideo = ({ video, bg, height }: { video: string, bg?: string, height?: string }) => {
    const [showVideo, setShowVideo] = useState(false);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    //listen for window resize event, and update dimensions 
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    return (
        <div className="relative h-full">
            {/* Display video iframe if showVideo is true */}
            {/* {showVideo && bg && ( */}
            {/* <iframe
            title="terra"
            src={video}
            className=""
            allow="autoplay; encrypted-media"
            width="100%" 
            height={screenSize.width > 1060 ? "620": "220"}
          ></iframe> */}

            <video
                controls
                autoPlay
                className="w-full h-[220px]"
                onEnded={() => setShowVideo(false)}
            >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default PlainVideo;