import React from "react";
import Header from "../../components/Header/Header";
import { Icons, Images } from "../../assets/assets";
import Footer from "../../components/Footer/Footer";
import Product from "../../components/Product/Product";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const SupportPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Support | SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Need help? Get in touch with SodaStream support for assistance with your products or any queries you may have."
          }
        />
        <link rel="canonical" href="https://www.sodastream.co.za/support" />
      </Helmet>
      <Header
        backgroundImg={Images.Page18BG}
        title="Support"
        textBackground="SUPPORT"
      />

      <div>
        <div className="mt-5 flex flex-col items-start justify-start w-full px:10 md:px-20 ">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mx-4">
            <div className=" mb-5">
              <Link
                to="https://support-us.sodastream.com/hc/en-us/categories/4418327617051-Getting-Started"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Product>
                  <div className="flex flex-col my-5 gap-2 items-center justify-center py-10 px-6">
                    <img
                      src={Icons.IconGetStarted}
                      alt="get started"
                      style={{ width: "25%" }}
                    />
                    <h3 className="font-bold text-black text-[16px]">
                      Getting Started
                    </h3>
                    <p className="text-[12px]">
                      Learn about SodaStream and explore our user guides,
                      manuals, and how-to videos.
                    </p>
                  </div>
                </Product>
              </Link>
            </div>
            <div className="mb-5">
              <Product>
                <div className="flex flex-col my-5 gap-2 items-center justify-center py-10 px-6">
                  <img
                    src={Icons.IconOrderSupport}
                    alt="order support"
                    style={{ width: "25%" }}
                  />
                  <h3 className="font-bold text-black text-[16px]">
                    Order Support
                  </h3>
                  <p className="text-[12px]">
                    Information and policies about purchasing online, shipping,
                    registration, warranty and returns.
                  </p>
                </div>
              </Product>
            </div>
            <div className=" mb-5">
              <Link
                to="https://support-us.sodastream.com/hc/en-us/categories/4418327617051-Getting-Started"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Product>
                  <div className="flex flex-col my-5 gap-2 items-center justify-center py-10 px-6">
                    <img
                      src={Icons.IconBubbleTroubles}
                      alt="get started"
                      style={{ width: "25%" }}
                    />
                    <h3 className="font-bold text-black text-[16px]">
                      Bubble Troubles
                    </h3>
                    <p className="text-[12px]">
                      Our step-by-step troubleshooting guides will help you get
                      your SodaStream back to bubbling.
                    </p>
                  </div>
                </Product>
              </Link>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mx-4">
            <div className=" mb-5">
              <Link to={"/store-locator"}>
                <Product>
                  <div className="flex flex-col gap-2 items-center justify-center py-10 px-6">
                    <img
                      src={Icons.IconGasRefills}
                      alt="get started"
                      style={{ width: "25%" }}
                    />
                    <h3 className="font-bold text-black text-[16px]">
                      Gas Refills
                    </h3>
                    <p className="text-[12px]">Visit your newarest store</p>
                  </div>
                </Product>
              </Link>
            </div>
            <div className="mb-5">
              <a
                href="https://support-us.sodastream.com/hc/en-us/categories/4418327679643-Flavors-and-bottles"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Product>
                  <div className="flex flex-col gap-2 items-center justify-center py-10 px-6">
                    <img
                      src={Icons.IconFlavoursAndBottles}
                      alt="order support"
                      style={{ width: "25%" }}
                    />
                    <h3 className="font-bold text-black text-[16px]">
                      Flavours and bottles
                    </h3>
                    <p className="text-[12px]">
                      All vou need to know about our varietv of flavours and
                      bottles.
                    </p>
                  </div>
                </Product>
              </a>
            </div>
            <div className=" mb-5">
              <Link to={"/store-locator"}>
                <Product>
                  <div className="flex flex-col gap-2 items-center justify-center py-10 px-6">
                    <img
                      src={Icons.IconStoreLocator}
                      alt="get started"
                      style={{ width: "25%" }}
                    />
                    <h3 className="font-bold text-black text-[16px]">
                      Store Locator
                    </h3>
                    <p className="text-[12px]">Find a store new you.</p>
                  </div>
                </Product>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex px-7 md:px-20  md:py-20 py-5 text-[#005D6E] flex-col gap-10">
          <div>
            <h2 className="text-[20px]">Contact Us</h2>
            <p className="mt-2 text-[14px]">
              Can’t find the answer? Get in touch with our sparkle support team
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mx-4 text-[#005D6E]">
            <div className=" mb-5">
              <div className="flex flex-col bg-white border rounded-xl border-gray-300  p-5 shadow-md gap-2 items-center justify-center">
                <img
                  className="md:w-[25%] w-[50%]"
                  src={Icons.IconMail}
                  alt="icon mail"
                  // style={{ width: "25%" }}
                />
                <h3 className="font-bold text-[16px]">WRITE US</h3>
                <p className="text-[12px]">
                  Email us - customercare-sa@sodastream.com
                </p>
              </div>
            </div>
            {/* sendon */}
            <div className="mb-5">
              <div className="flex flex-col bg-white border rounded-xl border-gray-300  p-5 shadow-md gap-2 items-center justify-center">
                <img
                  className="md:w-[25%] w-[50%]"
                  src={Icons.IconCall}
                  alt="icon call"
                />
                <h3 className="font-bold text-[16px]">CALL US</h3>
                <p className="text-[12px]">+27 800 00 22 91</p>
              </div>
            </div>
            <div className=" mb-5">
              <a
                href="https://www.instagram.com/sodastreamsouthafrica"
                rel="noopener noreferrer"
                target="_blank"
                className="flex flex-col bg-white border rounded-xl border-gray-300  p-5 shadow-md gap-2 items-center justify-center"
              >
                <img
                  className="md:w-[25%] w-[50%]"
                  src={Icons.IconInstagram}
                  alt="icon instagram"
                />
                <h3 className="font-bold text-[16px]">INSTAGRAM</h3>
                <p className="text-[12px]">Let’s connect!</p>
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mx-4">
            <div className=" mb-5">
              <a
                className=""
                href="https://www.youtube.com/user/SodaStreamGuru"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex flex-col bg-white border rounded-xl border-gray-300  p-5 shadow-md gap-2 items-center justify-center">
                  <img
                    className="md:w-[25%] w-[50%]"
                    src={Icons.IconYoutube}
                    alt="icon youtube"
                  />
                  <h3 className="font-bold text-[16px]">YOUTUBE</h3>
                  <p className="text-[12px]">
                    Watch our How-To and support videos.
                  </p>
                </div>
              </a>
            </div>
            {/* sendon */}
            <div className="mb-5">
              <a
                className=""
                href="https://web.facebook.com/sodastreamsa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex flex-col bg-white border rounded-xl border-gray-300  p-5 shadow-md gap-2 items-center justify-center">
                  <img
                    className="md:w-[25%] w-[50%]"
                    src={Icons.IconFacebook}
                    alt="icon youtube"
                  />
                  <h3 className="font-bold text-[16px]">FACEBOOK</h3>
                  <p className="text-[12px]">Let's be friends</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="md:flex ">
          <div className="bg-[#005D6E] text-white flex flex-col gap-3 px-20 items-start text-start justify-center flex-grow">
            <h2 className="text-[24px] font-semibold">
              Register your sparkling water maker
            </h2>
            <p>
              Let’s make it official. Register your machine and receive
              specialized offers and promotions!
            </p>
            <Link to="/register-machine">
              <PrimaryButton label="REGISTER HERE" />
            </Link>
          </div>
          <div>
            <img src={Images.BlackDuoImg} alt="black duo machine" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SupportPage;
