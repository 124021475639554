import React, { useEffect, useRef, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { FaEdit, FaTimesCircle } from "react-icons/fa";

import { ProductService, RecipeService } from "../../../APIs/index";
import {
  DeleteButton,
  IsLoadingButton,
  ModalDeleteCancel,
  ModalDeleteConfirm,
  ModalDeleteHeader,
  ModalDeleteTitle,
  SubmitButton,
} from "../../Loading/ButtonLoading";
import FormRecipe from "../recipesContent/recipesContent";
import {
  AccessData,
  AccessRecipe,
  DataRecipe,
  Recipe,
} from "../../../@types/productInfo";
import { ToastSuccess } from "../../Loading/Tost";
import ModalForm from "../ModalForm/ModalForm";
import { FormNav } from "../../FormNav/FormNav";
import ModalButton from "../../Button/ModalButton";

const Recipes: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [isSuccessMessageVisible, setSuccessMessageVisible] = useState(false);
  const [isSuccessRec, setSuccessRec] = useState(false);
  const [isEditSuccess, setEditSuccess] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRec, setFilteredRec] = useState<Recipe[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [description, setDescription] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [methods, setMethods] = useState<any[]>([]);
  const [newMethod, setNewMethod] = useState<string>("");
  const [ingredients, setIngredients] = useState<any[]>([]);
  const [newIngredient, setNewIngredient] = useState<string>("");
  const [meta_title, setMetaTitle] = useState<string>("");
  const [meta_description, setMetaDescription] = useState<string>("");
  const [selectedRec, setSelectedRec] = useState<any | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<number | undefined>();
  const [products, setProducts] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recipelink, setRecipeLink] = useState<string>("");
  const handleButtonClickk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const closeModalEdit = () => {
    setShowModalEdit(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const [formData, setFormData] = useState<Recipe>({
    name: "",
    description: "",
    ingredients: [],
    methods: [],
    productId: undefined,
    image: "",
    newMethod: "",
    newIngredient: "",
    meta_description,
    meta_title,
    recipelink
  });

  // Method to create a new recipe
  const handleFormSubmit = async (data: DataRecipe) => {
    setIsLoading(true);
    const recipeData: Recipe = {
      name: data.name,
      ingredients: data.ingredients,
      methods: data.methods,
      description: data.description,
      image: data.image,
      productId: data.productId,
      newMethod: "",
      newIngredient: "",
      meta_description: data.meta_description,
      meta_title: data.meta_title,
      recipelink: data.recipelink
    };

    try {
      const { data, error } = await RecipeService.createRecipe(recipeData);

      if (data) {
        console.log("data: ", data);
        console.log("Recipe created successfully:", data);
        fetchRecipeData();
        setSuccessRec(true);
        setShowModal(false);
        setSelectedRec(null);
        setTimeout(() => {
          setSuccessRec(false);
        }, 3000);

        setName("");
        setDescription("");

        setImage("");
      } else {
        console.error("Error creating recipe:");
      }
    } catch (error) {
      console.error("Error creating recipe:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //delete recipe
  const handleDeleteRecipe = async (recipeId: number) => {
    try {
      setIsLoadingDelete(true);
      const { data, error } = await RecipeService.deleteRecipe(recipeId);

      if (data) {
        setRecipes((prev) => prev.filter((recipe) => recipe.id !== recipeId));
        console.log("Recipe deleted successfully:", data);
        setSuccessMessageVisible(true);
        fetchRecipeData();
        setShowModalEdit(false);
        setIsModalVisible(false);
        setSelectedRec(null);
        setTimeout(() => {
          setSuccessMessageVisible(false);
        }, 3000);
      } else {
        setIsModalVisible(false);
      }
    } catch (error) {
      setIsModalVisible(false);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleEditRecipe = (rec: Recipe) => {
    setSelectedRec(rec);
    setName(rec.name || "");
    setIngredients(rec.ingredients || []);
    setMethods(rec.methods || []);
    setImage(rec.image || "");
    setNewMethod("");
    setDescription(rec.description || "");
    setMetaTitle(rec.meta_title || '')
    setMetaDescription(rec.meta_description || "")
    setRecipeLink(rec.recipelink || "")
    setShowModalEdit(true);
  };

  const handleSaveEdit = async (formData: DataRecipe) => {
    setIsLoadingUpdate(true);

    try {
      if (selectedRec && selectedRec.id) {
        const updatedRecData = {
          name: formData.name,
          ingredients: formData.ingredients,
          methods: formData.methods,
          description: formData.description,
          image: formData.image,
          productId: formData.productId,
          newMethod: "",
          newIngredient: "",
          meta_title:formData.meta_title,
          meta_description: formData.meta_description,
          recipelink: formData.recipelink
        };

        const { data, error } = await RecipeService.editRecipe(
          selectedRec.id,
          updatedRecData
        );

        if (data) {
          fetchRecipeData();
          setShowModalEdit(false);
          setEditSuccess(true);
          setIsModalVisible(false);
          setTimeout(() => {
            setEditSuccess(false);
          }, 3000);
        } else {
          console.error("Error updating recipe:", error);
          setIsModalVisible(false);
        }
      } else {
        console.error("Selected recipe is undefined");
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error uploading image or updating recipe:", error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  //create the method to fetch all recipes
  const fetchRecipeData = async () => {
    try {
      const { data, error } = await RecipeService.getAllRecipes();

      if (data) {
        setRecipes(data);
        setFilteredRec(data);
      } else {
        console.error("Error fetching recipes:");
      }
    } catch (error) {
      console.error("Error fetching recipes:");
    }
  };

  //call the method
  useEffect(() => {
    fetchRecipeData();
  }, []);

  //create the method to fetch all products
  const fetchProductData = async () => {
    try {
      const { data, error } = await ProductService.getAllProducts();

      if (data) {
        setProducts(data);
        console.log("data:", products);
      } else {
        console.error("Error fetching products:", error);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  //call the method
  useEffect(() => {
    fetchProductData();
  }, []);

  return (
    <div className="p-4 rounded-md w-3/4 mx-auto">
      <div className="flex justify-between items-center mb-4">
        {isSuccessMessageVisible && (
          <ToastSuccess label="Recipe successfully deleted!" />
        )}
        {isEditSuccess && <ToastSuccess label="Recipe successfully updated!" />}
        {isSuccessRec && <ToastSuccess label="Recipe successfully created!" />}
        <h2 className="text-xl font-semibold text-black">Recipes</h2>
      </div>
      <div className="relative mt-4">
        {showModal && (
          <div>
            <ModalForm isOpen={showModal} modalId={""} closeModal={closeModal}>
              <FormNav label="Add Recipe" onClick={closeModal} />
              <div>
                <FormRecipe
                  formData={formData}
                  onSubmit={handleFormSubmit}
                  onClick={closeModal}
                />
              </div>
            </ModalForm>
          </div>
        )}
      </div>
      <div className="relative mt-4">
        {showModalEdit && selectedRec && (
          <ModalForm
            isOpen={showModalEdit}
            modalId={""}
            closeModal={closeModalEdit}
          >
            <FormNav label="Edit Recipe" onClick={closeModalEdit} />
            <div>
              <div className="">
                <FormRecipe
                  isUpdate={true}
                  isUpdateDelete
                  formData={{
                    name,
                    ingredients,
                    methods,
                    description,
                    productId: selectedProduct,
                    newMethod,
                    newIngredient,
                    image: image,
                    meta_title,
                    meta_description,
                    recipelink
                  }}
                  onSubmit={handleSaveEdit}
                  onClick={handleButtonClickk}
                />

                {isModalVisible && (
                  <div
                    className="fixed
                 backdrop-blur-sm top-0  h-[199%]
                  left-0 w-full bg-gray-800 opacity-30 z-50"
                  ></div>
                )}

                {isModalVisible && (
                  <div
                    id="popup-modal"
                    tabIndex={-1}
                    className="overflow-y-auto grid overflow-x-hidden mt-[65%] fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                  >
                    <div className="relative p-4 text-black">
                      <div
                        className="relative bg-white rounded-lg text-black shadow dark:bg-gray-700"
                        style={{ zIndex: 999 }}
                      >
                        <ModalDeleteHeader
                          onClick={handleModalClose}
                          label="close Modal"
                        />

                        <div className="p-4 md:p-5 text-center">
                          <ModalDeleteTitle label=" Are you sure you want to delete this recipe ?" />
                          {!isLoadingDelete && (
                            <div>
                              <ModalDeleteConfirm
                                label="Confirm"
                                onClick={() =>
                                  selectedRec?.id &&
                                  handleDeleteRecipe(selectedRec.id)
                                }
                              />

                              <ModalDeleteCancel
                                label="Cancel"
                                onClick={handleModalClose}
                              />
                            </div>
                          )}

                          {isLoadingDelete && (
                            <IsLoadingButton
                              type="submit"
                              label="Deleting Recipe..."
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ModalForm>
        )}
      </div>
      <div className="bg-white p-4 rounded-md w-5/4 mx-auto">
        <ModalButton
          title="All Recipes"
          label="Add Recipe"
          onClick={openModal}
        />
        <div className="flex items-center mb-4">
          <input
            className="border rounded-[0.50rem] border-gray-400 py-1 px-2 w-1/4 mr-4 bg-white"
            placeholder="Search Recipes"
          />
          <div className="flex-grow"></div>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="w-full text-xs">
                  <thead>
                    <tr>
                      <th className="border-b p-2 bg-white text-left">Image</th>
                      <th className="border-b p-2 bg-white text-left">Name</th>
                      <th className="border-b p-2 bg-white text-left">
                        Description
                      </th>
                      <th className="border-b p-2 bg-white text-left">
                        Ingredient
                      </th>
                      <th className="border-b p-2 bg-white text-left">
                        Method
                      </th>
                      <th className="border-b p-2 bg-white text-left">
                        Flavour
                      </th>
                      <th className="border-b p-2 bg-white text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredRec.map((rec, index) => (
                      <tr key={index}>
                        <td>
                          <img
                            src={rec.image || ""}
                            alt=""
                            className="object-cover w-30 h-20 
                              rounded border border-solid border-gray-500"
                          />
                        </td>

                        <td className="border-b p-2 bg-white text-left text-black font-bold">
                          {rec.name}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          {rec.description}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          {rec.ingredients.map((ingredient, i) => (
                            <div key={i}>{ingredient}</div>
                          ))}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          {rec.methods.map((method, i) => (
                            <div key={i}>{method}</div>
                          ))}
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          {products.find(
                            (product) => product.id === rec.productId
                          )?.name || "Product Not Found"}
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          <span
                            onClick={() => handleEditRecipe(rec)}
                            className="cursor-pointer rounded flex items-center"
                          >
                            <FaEdit />
                            Edit
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recipes;
