import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BlogsService, ImageUploadUtil } from "../../../APIs/index";
import { useParams } from "react-router-dom";
import {
  EditorState,
  convertToRaw,
  ContentState,
  Modifier,
  convertFromHTML,
} from "draft-js";

import { AtomicBlockUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ToastSuccess } from "../../Loading/Tost";
import { useNavigate } from "react-router-dom";
import { BlogState, labelStyle } from "../../../@types/productInfo";
import {
  IsLoadingButton,
  ModalDeleteCancel,
  ModalDeleteConfirm,
  ModalDeleteHeader,
  ModalDeleteTitle,
} from "../../Loading/ButtonLoading";

const EditBlogs = () => {
  const [title, setTitle] = useState<string>("");
  const [meta_title, setMetaTitle] = useState<string>("");
  const [meta_description, setMetaDescription] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [blogText, setBlogText] = useState(() => EditorState.createEmpty());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState<string>("");
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [blog, setBlog] = useState<BlogState | null>(null);
  const [isSuccessBlog, setSuccessBlog] = useState(false);
  const [isSuccessBlogDelete, setSuccessBlogDelete] = useState(false);
  const [bloglink, setBlogLink] = useState<string>("");
  const [showPercentage, setPercentage] = useState(false);
  const handleButtonClickk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const localStorageKey = "addBlogsFormData";
  // Function to convert EditorState content to HTML
  const editorStateToHtml = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    return html || "";
  };

  // Function to save form data to local storage
  const saveFormDataToLocalStorage = () => {
    const formData = {
      title,
      meta_title,
      meta_description,
      content: editorStateToHtml(blogText), // Convert content to HTML
      link,
      image,
      bloglink,
    };
    localStorage.setItem(localStorageKey, JSON.stringify(formData));
  };

  useEffect(() => {
    const storedFormData = localStorage.getItem(localStorageKey);

    if (storedFormData) {
      const parsedData = JSON.parse(storedFormData);
      setTitle(parsedData.title);

      // Ensure that content is an EditorState instance
      const parsedContent = parsedData.content;

      // Use convertFromHTML to create a ContentState from HTML
      const blocksFromHTML = convertFromHTML(parsedContent);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      const editorState = EditorState.createWithContent(contentState);
      setBlogText(editorState);

      setLink(parsedData.link);
      setImage(parsedData.image);
    }
  }, []);

  const handlePreviewClick = () => {
    // Trigger preview when the "Preview" button is clicked
    const blogData: BlogState = {
      title,
      meta_title,
      meta_description,
      blogText: htmlContent,
      link,
      image,
      bloglink: meta_title,
    };

    localStorage.setItem("blogPreviewData", JSON.stringify(blogData));
    navigate("/dashboard/previewblog", { state: blogData });
  };

  const handleFileChangeImage = async (e: any) => {
    const file = e.target.files?.[0];
    setPercentage(true);
    if (file) {
      setPercentage(true);
      try {
        const uploadedImage = await ImageUploadUtil.uploadImage(file);
        setPercentage(false);
        if (uploadedImage.data) {
          console.log("Uploaded image:", uploadedImage.data);
          setImage(uploadedImage.data);
        } else {
          console.error("Error uploading image:", uploadedImage.error);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };
  const { id } = useParams();

  const htmlContent = stateToHTML(blogText.getCurrentContent());
  const fetchBlogData = async () => {
    try {
      const { data, error } = await BlogsService.getBlogById(id);

      if (data) {
        setBlog(data);
        setTitle(data.title);
        setMetaTitle(data.meta_title);
        setMetaDescription(data.meta_description);
        setLink(data.link);
        setBlogLink(data.bloglink);
        // Convert HTML content to EditorState
        const contentState = convertFromHTML(data.blogText || "");
        const newEditorState = EditorState.createWithContent(
          ContentState.createFromBlockArray(contentState.contentBlocks)
        );

        setBlogText(newEditorState);
        setImage(data.image);
      } else {
        console.error("Error fetching blog:", error);
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  useEffect(() => {
    fetchBlogData();
  }, [id]);

  const updateBlog = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (blog) {
        console.log("blog:", blog);
        const updatedBlog: BlogState = {
          ...blog,
          title,
          meta_title,
          meta_description,
          link,
          blogText: htmlContent,
          image: image,
          bloglink,
        };

        const { data, error } = await BlogsService.editBlog(id, updatedBlog);

        if (data) {
          console.log("Blog updated successfully:");
          setSuccessBlog(true);

          setTimeout(() => {
            setSuccessBlog(false);
            navigate("/dashboard/blogs");
          }, 3000);
        } else {
          console.error("Error updating blog:", error);
        }
      }
    } catch (error) {
      console.error("Error updating blog:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteBlog = async () => {
    try {
      setIsLoadingDelete(true);
      if (id) {
        const { data, error } = await BlogsService.deleteBlog(parseInt(id, 10));

        if (data) {
          console.log("Blog deleted successfully:", data);
          setSuccessBlogDelete(true);
          setIsModalVisible(false);
          setTimeout(() => {
            setSuccessBlogDelete(false);
            navigate("/dashboard/blogs");
          }, 3000);
        } else {
          console.error("Error deleting blog:", error);
        }
      }
    } catch (error) {
      console.error("Error deleting blog:", error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleImageUpload = async (file: any) => {
    try {
      const { data, error } = await ImageUploadUtil.uploadImage(file);

      if (data) {
        //map to update the blog text
        setBlogText((prevContent) => {
          const contentState = prevContent.getCurrentContent();

          // Create a new entity for the image
          const contentStateWithEntity = contentState.createEntity(
            "IMAGE",
            "IMMUTABLE",
            { src: data }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

          // Use AtomicBlockUtils to insert the image entity....
          const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            prevContent,
            entityKey,
            " " // Empty text for the atomic block
          );

          console.log("Content:", newEditorState.getCurrentContent());

          console.log("Image link:", data);

          return newEditorState;
        });
      } else {
        console.error("Image link not provided in the response");
        throw new Error("Upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw new Error("Upload failed");
    }
  };
  const handleChange = (newEditorState: EditorState) => {
    setBlogText(newEditorState);
    saveFormDataToLocalStorage();
  };

  return (
    <div>
      <div></div>
      <div>
        <h2 className="text-xl font-semibold text-black text-left mb-[25px]">
          Edit Blog
        </h2>
        <div>
          {isSuccessBlog && <ToastSuccess label="Blog successfully updated!" />}
        </div>
        <div>
          {isSuccessBlogDelete && (
            <ToastSuccess label="Blog successfully deleted!" />
          )}
        </div>
        <div>
          <div className="flex space-x-4">
            <div className="bg-white p-4 rounded-md w-[65%] mt-[-10px]">
              <div></div>
              <div>
                <div>
                  <div>
                    <div>
                      <label
                        style={labelStyle as React.CSSProperties}
                        htmlFor="firstName"
                        className="text-gray-600 font-bold text-sm "
                      >
                        Blog Title
                      </label>
                      <input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        type="text"
                        id="blogTitle"
                        className="w-full border p-2  bg-white text-black"
                        placeholder="Enter your blog title here..."
                      />
                      <div className="mt-4">
                        <label
                          style={labelStyle as React.CSSProperties}
                          htmlFor="firstName"
                          className="text-gray-600 font-bold text-sm "
                        >
                          Blog Link
                        </label>
                        <input
                          value={link}
                          onChange={(e) => setLink(e.target.value)}
                          type="text"
                          id="blogLink"
                          className="w-full border p-2 bg-white text-black"
                          placeholder="Enter your blog link here..."
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        style={labelStyle as React.CSSProperties}
                        htmlFor="text blog"
                        className="text-gray-600 font-bold text-sm "
                      >
                        Meta Title
                      </label>
                      <input
                        value={meta_title}
                        onChange={(e) => setMetaTitle(e.target.value)}
                        type="text"
                        id="blogTitle"
                        className="w-full border p-2  bg-white text-black"
                        placeholder="Enter Meta Title..."
                      />
                      <div className="mt-4">
                        <label
                          style={labelStyle as React.CSSProperties}
                          htmlFor="link"
                          className="text-gray-600 font-bold text-sm "
                        >
                          Meta Description
                        </label>
                        <input
                          value={meta_description}
                          onChange={(e) => setMetaDescription(e.target.value)}
                          type="text"
                          id="blogLink"
                          className="w-full border p-2 bg-white text-black"
                          placeholder="Enter Meta Description..."
                        />
                      </div>
                      <div className="mt-4">
                        <label
                          style={labelStyle as React.CSSProperties}
                          htmlFor="link"
                          className="text-gray-600 font-bold text-sm "
                        >
                          Blog Url
                        </label>
                        <input
                          name="bloglink"
                          value={bloglink}
                          onChange={(e) => setBlogLink(e.target.value)}
                          type="text"
                          id="blogLink"
                          className="w-full border p-2 bg-white text-black"
                          placeholder="Blog Url..."
                        />
                      </div>
                    </div>
                    <div
                      className="bg-white mt-[4px] rounded-md w-100% h-full text-black"
                      style={{
                        padding: "25px",
                      }}
                    >
                      <Editor
                        editorState={blogText}
                        placeholder="Write your Blog here...."
                        onEditorStateChange={handleChange}
                        toolbar={{
                          image: {
                            uploadCallback: handleImageUpload,
                            alt: { present: true, mandatory: true },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-md w-[35%] relative h-[150px] mt-[-10px]">
              <div className="bg-white p-4">
                <div className="flex justify-between items-center border-b mb-4 pb-2">
                  <h6 className="text-black font-bold">Preview & Draft</h6>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <button
                    data-modal-hide="popup-modal"
                    type="button"
                    className="text-white bg-[#75A7AD] hover:bg-gray-100 focus:ring-4
                    focus:outline-none focus:ring-gray-200 rounded-lg border
                     border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 
                     focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    Save Draft
                  </button>
                  <button
                    data-modal-hide="popup-modal"
                    type="button"
                    onClick={handlePreviewClick}
                    className="text-white bg-[#75A7AD] hover:bg-gray-100 focus:ring-4
                    focus:outline-none focus:ring-gray-200 rounded-lg border
                     border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 
                     focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    Preview
                  </button>
                </div>
              </div>

              {isModalVisible && (
                <div
                  id="popup-modal"
                  tabIndex={-1}
                  className="overflow-y-auto grid overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                  <div className="relative p-4 w-full text-black max-w-md max-h-full">
                    <div
                      className="relative bg-white rounded-lg text-black shadow dark:bg-gray-700"
                      style={{ zIndex: 999 }}
                    >
                      <ModalDeleteHeader
                        onClick={handleModalClose}
                        label="close Modal"
                      />

                      <div className="p-4 md:p-5 text-center">
                        <ModalDeleteTitle label=" Are you sure you want to delete this blog ?" />
                        {!isLoadingDelete && (
                          <div>
                            <ModalDeleteConfirm
                              label="Confirm"
                              onClick={deleteBlog}
                            />

                            <ModalDeleteCancel
                              label="Cancel"
                              onClick={handleModalClose}
                            />
                          </div>
                        )}

                        {isLoadingDelete && (
                          <IsLoadingButton
                            type="submit"
                            label="Deleting blog..."
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className=" w-[full] justify-between mt-[14px] ">
                {/* Upload Icon */}
                <div className=" justify-center  w-full ">
                  <label
                    style={labelStyle as React.CSSProperties}
                    htmlFor="firstName"
                    className="text-gray-600 font-bold text-sm mt-[25px]"
                  >
                    Upload Image
                  </label>
                  <label
                    htmlFor="dropzone-file-1"
                    className="flex flex-col items-center  justify-center w-full h-[110px] 
                      border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50
                    dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500
                     dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center ">
                      {showPercentage && (
                        <div className="flex gap-2 mt-5">
                          <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                          <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                          <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                        </div>
                      )}
                      <svg
                        className="w-6 h-6 mb-2 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-1 text-gray-500  dark:text-gray-400">
                        <span className="font-semibold text-xss">
                          Click to upload
                        </span>{" "}
                        or drag and drop image
                      </p>
                    </div>
                    <input
                      id="dropzone-file-1"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChangeImage}
                      className="hidden"
                    />
                  </label>
                </div>

                {/* Upload Image*/}

                <div className=" w-full  ">
                  {image && (
                    <div>
                      <label
                        style={labelStyle as React.CSSProperties}
                        htmlFor="image"
                        className="text-gray-600 font-bold text-sm mt-[15px]"
                      >
                        Preview Image
                      </label>
                      <label className=" w-[full] h-40  mt-[60px]   rounded  ">
                        <div className="p-4 w-full">
                          <div className="w-auto mt-[5%]">
                            <div className=" py-0 rounded justify-center mt-8">
                              <img
                                src={image}
                                alt=""
                                style={{
                                  width: "auto",
                                  maxHeight: "100px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="px-6 py-4"></div>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row w-[64%] justify-between mt-[14px] space-x-2">
            <div className=" justify-center text-center  w-1/2 ">
              <div>
                {!isLoading && (
                  <button
                    data-modal-hide="popup-modal"
                    type="button"
                    onClick={updateBlog}
                    className="text-white w-full font-bold bg-teal-800 py-2 text-center rounded-[0.50rem] "
                  >
                    <span className="text-center bold">Publish</span>
                  </button>
                )}

                {isLoading && (
                  <button
                    disabled
                    className="text-white w-full font-bold bg-black py-2 text-center rounded-[0.50rem] "
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 me-3   animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Updating Blog...
                  </button>
                )}
              </div>
            </div>

            <div className=" w-1/2  ">
              <button
                data-modal-hide="popup-modal"
                type="submit"
                onClick={handleButtonClickk}
                className="text-white w-full font-bold bg-teal-800 py-2 text-center rounded-[0.50rem] "
              >
                Delete Blog
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBlogs;
