import React from 'react'
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { Images } from '../../assets/assets';
import SecondaryButton from '../../components/Button/SecondaryButton';

const PromotionsPage = () => {
  return (
    <div>
        <Header title='Promotions' textBackground='PROMOTIONS' backgroundImg={Images.Page6BG} />
        <div className='relative min-h-[40rem] w-full'>
            <div className='md:absolute md:top-[-20rem] gap-10 z-50 flex flex-col items-center justify-center w-full'>
                <div className='mt-5 px-10 md:px-60 w-full'>
                    <div className='bg-white rounded-xl border border-gray-300 relative w-full opacity-80 px-10 py-5 text-[#005D6E]'>
                        <img src={Images.WatterBottleBlack} alt='terra' className='hidden md:block absolute z-50 top-[-3rem] left-0' style={{ width: "27%" }} />
                        <div className='md:ml-40 flex flex-col items-start justify-start md:px-20 px-5 py-10'>
                            <h2 className='text-[40px] font-semibold text-[#E56C5C]'>PROMO HEADLINE</h2>
                            <h3 className='text-[20px] mt-2'>PRODUCT NAME</h3>
                            <p className='mt-5'>Promo copy and details go here…</p>
                            <div className='flex md:flex-row flex-col md:gap-10 gap-3 mt-5 md:mt-36'>
                                <SecondaryButton label='ADD TO CART' />
                                <SecondaryButton label='VIEW ALL OFFERS' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col md:flex-row px-10 md:px-60 gap-10 md:mt-20 my-5'>
                    <div className='relative'>
                        <img src={Images.PromoHeadline2} alt='product headline 2' style={{ width: "100%"}} />
                        <div className='absolute z-10 top-0'>
                            <div className='p-10 text-start'>
                                <h2 className='text-[#E56C5C] text-[30px]'>PROMO HEADLINE 2</h2>
                                <h3 className='text-white text-[20px]'>PRODUCT NAME</h3>
                            </div>
                        </div>
                    </div>
                    <div className='relative'>
                        <img src={Images.PromoHeadline3} alt='product headline 2' />
                        <div className='absolute z-10 bottom-0'>
                            <div className='p-10 text-start'>
                                <h2 className='text-[#E56C5C] text-[30px]'>PROMO HEADLINE 3</h2>
                                <h3 className='text-white text-[20px]'>PRODUCT NAME</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default PromotionsPage;