import axios, { AxiosResponse } from "axios";
import { Recipe } from "../@types/productInfo";
const BASE_URL = "https://sodastreamapi.azurewebsites.net//recipes"; // Updated base URL for recipes


class RecipeService {

  async getAllRecipes(): Promise<{
    data: Recipe[] | null;
    error: string | null;
  }> {
    try {
      const response: AxiosResponse<Recipe[]> = await axios.get(BASE_URL);
      if (response.status >= 200 && response.status < 300) {
        const recipes: Recipe[] = response.data;
        console.log("Recipes:", recipes);
        return { data: recipes, error: null };
      } else {
        const error = "Error fetching recipes";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching recipes:", error);
      return { data: null, error };
    }
  }

  async getRecipeById(
    recipeId: number
  ): Promise<{ data: Recipe | null; error: string | null }> {
    try {
      const response: AxiosResponse<Recipe> = await axios.get(
        `${BASE_URL}/id/${recipeId}`
      );
      if (response.status >= 200 && response.status < 300) {
        const recipe: Recipe = response.data;
        console.log("Recipe:", recipe);
        return { data: recipe, error: null };
      } else {
        const error = "Error fetching recipe by ID";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching recipe by ID:", error);
      return { data: null, error };
    }
  }

  async getRecipeByLink(recipelink: string): Promise<{ data: Recipe | null; error: string | null }> {
    try {
        const response: AxiosResponse<Recipe> = await axios.get(`${BASE_URL}/${recipelink}`);
        if (response.status >= 200 && response.status < 300) {
            const recipe: Recipe = response.data;
            return { data: recipe, error: null };
        } else {
            const error = "Error fetching recipe by link";
            console.error(error);
            return { data: null, error };
        }
    } catch (error: any) {
        console.error("Error fetching recipe by link:", error);
        return { data: null, error };
    }
}


  async createRecipe(
    recipeData: any
  ): Promise<{ data: Recipe | null; error: string | null }> {
    try {
      const response: AxiosResponse<Recipe> = await axios.post(
        BASE_URL,
        recipeData
      );
      if (response.status >= 200 && response.status < 300) {
        const createdRecipe: Recipe = response.data;
        console.log("Created Recipe:", createdRecipe);
        return { data: createdRecipe, error: null };
      } else {
        const error = "Error creating recipe";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error creating recipe:", error);
      return { data: null, error };
    }
  }

  async editRecipe(
    recipeId: number,
    updatedRecipeData: Recipe
  ): Promise<{ data: Recipe | null; error: string | null }> {
    try {
      const response: AxiosResponse<Recipe> = await axios.put(
        `${BASE_URL}/id/${recipeId}`,
        updatedRecipeData
      );
      if (response.status >= 200 && response.status < 300) {
        const editedRecipe: Recipe = response.data;
        console.log("Edited Recipe:", editedRecipe);
        return { data: editedRecipe, error: null };
      } else {
        const error = `Error editing recipe: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error editing recipe:", error.message);
      return { data: null, error: error.message };
    }
  }

  async deleteRecipe(
    recipeId: number
  ): Promise<{ data: boolean | null; error: string | null }> {
    try {
      const response: AxiosResponse<void> = await axios.delete(
        `${BASE_URL}/id/${recipeId}`
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Recipe deleted successfully");
        return { data: true, error: null };
      } else {
        const error = `Error deleting recipe: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error deleting recipe:", error.message);
      return { data: null, error: error.message };
    }
  }
}

const recipeServiceInstance = new RecipeService();

export default recipeServiceInstance;
