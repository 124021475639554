import React from 'react';

interface SecondaryButtonProps {
  onClick?: () => void;
  label?: string;
  isFull?:Boolean;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({ onClick, label = 'Submit', isFull = false }) => {
  return (
    <button
    style={{width: isFull? '100%': 'auto'}}
      onClick={onClick}
      className="bg-[#005D6E] rounded-[46px] opacity-100 flex items-center justify-center cursor-pointer px-4"
    >
      <span className="text-[12px] leading-[29px] uppercase font-bold text-white">
        {label}
      </span>
    </button>
  );
};

export default SecondaryButton;
