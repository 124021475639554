import React from "react";
import Header from "../../components/Header/Header";
import { Images } from "../../assets/assets";
import Footer from "../../components/Footer/Footer";
import RecommendationItem from "../../components/RecommendationItem/RecommendationItem";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const recommendations = [
  {
    image: Images.YouAJam,
    title: "Together We Can Make a Difference",
    recipies: [],
    link:"https://www.sodastream.co.za/mixology/you-re-a-jam"
  },
  { image: Images.PepsiLemonade, title: "About Us", link:"" },
  {
    image: Images.AutumnElixir,
    title: "Exchange and Refill Your CO2 Cylinders",
    link:"https://www.sodastream.co.za/co2-gas-cylinders"
  },
  { image: Images.NewYearsSparkler, title: "Compare machines models", link:"https://www.sodastream.co.za/mixology/new-years-sparkler" },
  // { image: Images.NewOldFashion, title: 'The New Old Fashioned' },
];

const WhySodastream = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Why SodaStream | SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Discover why SodaStream is the ultimate choice for sparkling water lovers. Join the fizz revolution today!"
          }
        />
        <link
          rel="canonical"
          href="https://www.sodastream.co.za/why-sodastream"
        />
        
      </Helmet>
      <Header
        backgroundImg={Images.Page13BG}
        title="Why SodaStream"
        textBackground={`WHY SODASTREAM`}
      />

      <section>
        <div className="flex">
          <div>
            <img src={Images.FuseBottleOutdoor} alt="fuse bottle outdoor" />
          </div>
          <div className="text-white bg-[#005D6E] flex flex-col gap-4 items-start justify-center px-20 py-10 text-start">
            <h2 className="font-bold text-[24px]">
              Together We Can Make a Difference
            </h2>
            <p className="font-light">
              SodaStream offers a sparkling difference in your hydration
              journey, where convenience, taste, and sustainability meet. We
              believe that something as small as sparkling your water at home
              can spark a huge change in the world. By 2025, SodaStream pledges
              to save up to 76-billion single-use plastic bottles from our
              planet, so we don’t have to go looking for a new one.
            </p>
            <p className="font-light">
              Join the global community committed to reducing plastic waste and
              embracing sustainability, one ‘pshhh’ at a time.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-[#75A7AD] flex px-40">
          <div className="basis-[50%] px-10 py-10 items-center flex flex-col gap-2 justify-center text-start text-white">
            <h2 className="text-start w-full">The Lifecycle of Plastic</h2>
            <hr className="w-full" />
            <p className="text-[12px]">
              Of all the plastic ever produced, only about 9% was ever recycled.
              12% was incinerated, and 79% ended up in natural environment or
              landfills. Designed to last, nearly all plastic exists in some
              form today.
            </p>
          </div>
          <div className="basis-[50%] px-10 py-10 flex items-center justify-center">
            <img
              src={Images.BlogBanners}
              alt="The Lifecycle of Plastic"
              style={{ width: "50%" }}
            />
          </div>
        </div>
      </section>

      <section>
        <div className="bg-[#75A7AD] flex px-40">
          <div className="basis-[50%] px-10 py-10 flex items-center justify-center">
            <img
              src={Images.BlogBanners1}
              alt="Single-use plastic waste"
              style={{ width: "50%" }}
            />
          </div>
          <div className="basis-[50%] px-10 py-10 items-center flex flex-col gap-2 justify-center text-start text-white">
            <h2 className="text-start w-full">
              Coming together to make a difference: SodaStream Pledge
            </h2>
            <hr className="w-full" />
            <p className="mb-2 text-[12px]">
              We believe that something as small as sparkling your water at home
              can spark a huge change in the world. By 2025, SodaStream will
              save up to 76 billion single-use plastic bottles from our planet.
            </p>
            <p className="text-[12px]">
              It starts with our carbonating bottle. Every SodaStream bottle
              will work toward eliminating thousands of single-use plastic waste
              and keeping our planet healthy and cleaner for everyone.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="flex">
          <div className="basis-[50%]">
            <img src={Images.MyOnlyBottleCheers} alt="SodaStream my only bottle cheers" />
          </div>
          <div className="basis-[50%] text-[#005D6E] flex flex-col items-center justify-center px-20">
            <h2 className="text-start w-full font-bold text-[20px]">
              The fight against single-use plastic waste is part of the
              SodaStream DNA
            </h2>
            <hr className="w-full" />
            <div className="flex flex-col gap-4 text-[10px] text-start mt-2">
              <p className="text-[12px]">
                Your home sparkling water maker makes it possible to drastically
                lower your consumption of single-use plastic bottles, hydrating
                more, and leading healthier lives on a cleaner planet.
              </p>
              <p className="text-[12px]">
                Our Earth needs less waste and more sparkle!
              </p>
              <p className="text-[12px]">
                SodaStream PET carbonating bottles are reusable for 3 years and
                each one helps eliminate thousands of single-use plastic
                bottles.
              </p>
              <p className="text-[12px]">
                Your bubbles are not confined to your home or office anymore,
                either.
              </p>
              <p className="text-[12px]">But it doesn’t stop there.</p>
              <p className="text-[12px]">
                CO2 carbonating cylinders further contribute to minimizing
                harmful waste. With every exchange, your empty cylinder is
                sterilized, inspected, and filled with fresh, dietary-grade CO2.
              </p>
              <p className="text-[12px]">
                One of the best things about saving the world from single-use
                plastic is that you don’t have to give up on your favorite
                drinks or sacrifice taste.
              </p>
              <p className="text-[12px]">
                With dozens of flavours and customisable options, you can be
                sure that staying hydrated with SodaStream is better for you,
                and better for the planet.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-[#75A7AD] flex px-40">
          <div className="basis-[50%] px-10 py-10 items-center flex flex-col gap-2 justify-center text-start text-white">
            <h2 className="text-start w-full text-[20px]">
              Already own a SodaStream and want to do more?
            </h2>
            <hr className="w-full" />
            <p className="text-[14px]">
              Commit to one month of avoiding single-use plastic. Start small by
              picking one item on the list below and switch to reusable bottles
              for a month, or challenge yourself and inspire others by avoiding
              the ‘big four’!
            </p>
          </div>
          <div className="basis-[50%] px-10 py-10 flex items-center justify-center">
            <img
              src={Images.BlogBanners3}
              alt="Avoiding single-use plastic"
              style={{ width: "80%" }}
            />
          </div>
        </div>
      </section>
      <section>
        <div className="bg-[#EEEAE5] px-5 py-10 flex flex-col gap-4">
          <section>
            <div className="relative w-full">
              <div className="relative  gap-10 md:z-50 z-9 flex flex-col items-center justify-center w-full">
                <div className="mt-5">
                  <div className="grid md:grid-cols-4 gap-10 px-10 mb-4">
                    {recommendations.map((recommendation, index) => (
                     <Link to={recommendation.link} key={index}>
                      <RecommendationItem 
                        image={recommendation.image}
                        title={recommendation.title}
                      />
                     </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default WhySodastream;
