import React from 'react';

interface CommentContentProps {
  message: string;
}

const CommentContent: React.FC<CommentContentProps> = ({ message }) => {
  return (
    <p className='text-[#005D6E]'>
      {message}
    </p>
  );
};

export default CommentContent;
