import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../Footer/Footer";
import Loading from "../../Loading/Loading";

import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Products } from "../../../pages/ProductPage/ProductPage";
import { Images } from "../../../assets/assets";
import SecondaryButton from "../../Button/SecondaryButton";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";

const PreviewProduct: React.FC = () => {
  const location = useLocation();

  const [selectedProduct, setSelectedProduct] = useState<Products | null>(null);

  useEffect(() => {
    // Récupérer les données du produit de l'URL et les décoder
    const query = new URLSearchParams(location.search);
    const product = query.get("data");

    // Vérifier si les données existent et les convertir en objet JavaScript
    if (product) {
      try {
        const parsedProduct = JSON.parse(decodeURIComponent(product));
        setSelectedProduct(parsedProduct);
      } catch (error) {
        console.error("Error parsing product data:", error);
      }
    }
  }, [location.search]);

  const breadcrumbItems = [
    { text: "HOME", href: "/" },
    { text: "FLAVOURS", href: "/flavours" },
    { text: selectedProduct ? selectedProduct.name : "Loading..." },
  ];

  return (
    <div>
      {selectedProduct ? (
        <>
          <Navbar />
          <section>
            <div className="bg-[#FFFFFF] text-start px-20">
              <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="flex items-center px-20 py-5 gap-5 bg-[#FFFFFF] mb-5 md:flex-row md:flex-nowrap flex-col flex-wrap">
              <div className="basis-[30%] flex flex-col items-center justify-center">
                <img
                  className="w-[65px] mw-[200px]"
                  src={selectedProduct.image}
                  alt="drink"
                />
              </div>
              <div className="flex-grow flex flex-col items-center text-start">
                <div className="flex flex-col gap-4 text-[#005D6E]">
                  <h2 className="text-[#005D6E] text-[24px]">
                    {selectedProduct.name}
                  </h2>

                  <p>{selectedProduct.description}</p>

                  <div>
                    <Link to="/store-locator">
                      <SecondaryButton label={"Store Locator"} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="flex gap-0 flex-col md:flex-row">
              <div className="basis-[50%] p-20 bg-[#EEEAE5] flex items-center justify-center">
                <p className="text-[#005D6E] font-bold">
                  {selectedProduct.caption}
                </p>
              </div>
              <div className="basis-[50%]">
                <img src={selectedProduct.banner} alt="bg" />
              </div>
            </div>
          </section>

          <section>
            <div className="flex gap-0 flex-col-reverse md:flex-row ">
              <div className="basis-[50%]">
                <img src={Images.FuseBottleWorkingWomen} alt="bg" />
              </div>
              <div className="basis-[50%] p-20 bg-[#EEEAE5] flex flex-col items-start justify-center gap-6">
                <h2 className="text-[#005D6E] font-bold text-[20px]">
                  What's inside?
                </h2>
                <p className="text-[#005D6E]">{selectedProduct.ingredient}</p>
                {/* <p className='border rounded-full px-4 border-black'>NUTRITION INFORMATION</p> */}
              </div>
            </div>
          </section>

          <Footer />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default PreviewProduct;
