import React from "react";
import { AiFillStar } from "react-icons/ai";
import dayjs from "dayjs";

interface CommentIdentityProps {
  name: string;
  isVerified: boolean;
  rating: number;
  date: string; // You could also use Date or another type depending on how the date is coming in
}

const CommentIdentity: React.FC<CommentIdentityProps> = ({
  name,
  isVerified,
  rating,
  date,
}) => {
  const renderStars = (count: number) => {
    let stars = [];
    for (let i = 0; i < count; i++) {
      stars.push(<AiFillStar key={i} />);
    }
    return stars;
  };

  const formattedDate = dayjs(date).format("MM/DD/YYYY");

  return (
    <div className="flex justify-between">
      <div className="text-[#005D6E]">
        <span className="font-bold">{name} </span>
        <span className="text-gray-500">
          {isVerified ? "Verified Buyer" : "Unverified Buyer"}
        </span>
        <div className="flex gap-1 mt-2">{renderStars(rating)}</div>
      </div>
      <div className="flex flex-col items-end gap-2">
        <p>Sort: Select</p>
        <p className="text-gray-500">{formattedDate}</p>
      </div>
    </div>
  );
};

export default CommentIdentity;
