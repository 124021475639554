import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

interface SliderProps {
  images: string[];
}

const Slider: React.FC<SliderProps> = ({ images }) => {
  return (
    <Carousel
      showThumbs={false}
      infiniteLoop={true}
      emulateTouch={true}
      swipeable={true}
      showStatus={false} // This line removes the numbering
      renderArrowPrev={(onClickHandler: () => void, hasPrev: boolean, label: string) =>
        hasPrev && (
          <button type="button" onClick={onClickHandler} title={label} className="hidden md:absolute left-0 top-1/2 transform -translate-y-1/2 text-white z-50">
            <BsChevronLeft fontSize={50} />
          </button>
        )
      }
      renderArrowNext={(onClickHandler: () => void, hasNext: boolean, label: string) =>
        hasNext && (
          <button type="button" onClick={onClickHandler} title={label} className="hidden md:absolute right-0 top-1/2 transform -translate-y-1/2 text-white z-50">
            <BsChevronRight fontSize={50} />
          </button>
        )
      }
    >
      {images.map((image, index) => (
        <div key={index} className='w-full h-full'>
          <img className='h-full' src={image} alt={`Slide ${index + 1}`} width={"100%"} height={"100%"} />
        </div>
      ))}
    </Carousel>
  );
};

export default Slider;