import React from 'react'
import SocialIcon from '../SocialIcon/SocialIcon';
import { BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs';
import { BiLogoFacebook } from 'react-icons/bi';

const SocialLinks = () => {
  return (
    <div className='flex gap-4 items-center justify-start'>
        <SocialIcon>
       



        <a className="twitter-share-button"
  href="https://twitter.com/intent/tweet?text=Hello%20world"  target="_blank" rel="noopener noreferrer">
     <BsTwitter />
</a>
        </SocialIcon>
        <SocialIcon>
        <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.sodastream.co.za%2F&amp;quote=Votre%20texte%20ici"
         target="_blank" rel="noopener noreferrer"><BiLogoFacebook/></a>






            
        </SocialIcon>
        <SocialIcon>
            <a href='https://www.instagram.com/sodastreamsouthafrica/' target="_blank" rel="noopener noreferrer">
                <BsInstagram />
            </a>
        </SocialIcon>
        <SocialIcon>
        <a href='https://youtube.com/@sodastreamsa1781?si=98CUY4uLstG8RtZd' target="_blank" rel="noopener noreferrer">
            <BsYoutube />
            </a>
        </SocialIcon>
    </div>
  )
}

export default SocialLinks;