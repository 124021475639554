import React, { useEffect, useRef, useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { ImageUploadUtil, ProductService } from "../../../APIs/index";
import {
  DeleteButton,
  IsLoadingButton,
  ModalDeleteCancel,
  ModalDeleteConfirm,
  ModalDeleteHeader,
  ModalDeleteTitle,
  SubmitButton,
} from "../../Loading/ButtonLoading";
import { ToastDanger, ToastSuccess, ToastWarning } from "../../Loading/Tost";
import ProductForm from "../ProductForm/ProductForm";
import { Product } from "../../../@types/productInfo";
import ModalForm from "../ModalForm/ModalForm";
import ModalButton from "../../Button/ModalButton";
import { FormNav } from "../../FormNav/FormNav";
import {
  BiArrowBack,
  BiArrowFromRight,
  BiChevronLeft,
  BiChevronRight,
} from "react-icons/bi";
import { TbArrowForward } from "react-icons/tb";

const Products: React.FC = () => {
  // State for handling product-related data
  const [banner, setBanner] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [meta_description, setMetaDescription] = useState<string>("");
  const [meta_title, setTitle] = useState<string>("");
  const [caption, setCaption] = useState<string>("");
  const [ingredient, setIngredient] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [image, setImage] = useState<string>("");

  const [rating, setRating] = useState<number>(5);
  const [price, setPrice] = useState<number>(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState("all");
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [isSuccessMessageVisible, setSuccessMessageVisible] = useState(false);
  const [isSuccessProduct, setSuccessProduct] = useState(false);
  const [isEditSuccess, setEditSuccess] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const [productlinkname, setProductLinkName] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 3;
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);
  const [formData, setFormData] = useState<Product>({
    banner: "",
    name: "",
    description: "",
    meta_title:"",
    meta_description: "",
    caption: "",
    ingredient: "",
    category: "",
    price: 49,
    rating: 5,
    image: "",
    productlinkname
  });

  // State to manage the selected image file

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleButtonClickk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const closeModalEdit = () => {
    setShowModalEdit(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSearchTermChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const filterProductsFromSearch = (users: any[]) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const tempProducts = users.filter((user) => {
      const productProperties = Object.values(user).map((value) =>
        String(value).toLowerCase()
      );
      return (
        (selectedRole === "all" || user.role === selectedRole) &&
        productProperties.some((property) =>
          property.includes(lowerCaseSearchTerm)
        )
      );
    });

    setFilteredProducts(tempProducts);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterProductsFromSearch(products);
    } else {
      setFilteredProducts(products);
    }
  }, [searchTerm, products]);

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Create an array of page numbers using map
  const generatePageNumbers = () => {
    const numbers = [];
    for (let i = 1; i <= totalPages; i++) {
      numbers.push(i);
      console.log("TotalPage", totalPages);
      console.log("ff", totalPages);
    }
    setPageNumbers(numbers);
  };

  useEffect(() => {
    generatePageNumbers();
  }, [totalPages]);

  // Method to create a new product
  const handleFormSubmit = async (data: any) => {
    setIsLoading(true);

    const productData: Product = {
      banner: data.banner,
      name: data.name,
      meta_title: data.meta_title,
      meta_description: data.meta_descripton,
      description: data.description,
      caption: data.caption,
      ingredient: data.ingredient,
      category: data.selectedRole,
      price,
      rating: 5,
      image: data.image,
      productlinkname
    };

    try {
      const { data, error } = await ProductService.createProduct(productData);

      if (data) {
        console.log("Product created successfully:", data);
        fetchProductData();
        setSuccessProduct(true);
        setShowModal(false);

        setSelectedProduct(null);
        setTimeout(() => {
          setSuccessProduct(false);
        }, 3000);
        setBanner("");
        setName("");
        setDescription("");
        setCaption("");
        setIngredient("");
        setPrice(0);
        setRating(0);
        setImage("");
      } 
    } catch (error) {
      
    } finally {
      setIsLoading(false);
    }
  };

  //fill the edit form
  const handleEditProduct = (product: Product) => {
    console.log("Selected Product:", product);
    setSelectedProduct(product);
    setProductLinkName(product.productlinkname || "");
    setBanner(product.banner || "");
    setName(product.name || "");
    setTitle(product.meta_title || "");
    setMetaDescription(product.meta_description || "");
    setDescription(product.description || "");
    setCaption(product.caption || "");
    setIngredient(product.ingredient || "");
    setPrice(product.price || 0);
    setRating(product.rating || 0);
    setImage(product.image || "");
    setCategory(product.category || "CLASSIC");

    setShowModalEdit(true);
  };

  //edit product
  const handleSaveEdit = async (formData: Product) => {
    setIsLoadingUpdate(true);
    try {
      const productId = selectedProduct?.id;
      if (productId) {
        const updatedProductData = {
          banner: formData.banner,
          name: formData.name,
          meta_title: formData.meta_title,
          meta_description: formData.meta_description,
          description: formData.description,
          caption: formData.caption,
          ingredient: formData.ingredient,
          price: formData.price,
          category: formData.category,
          rating: 5,
          image: formData.image,
          productlinkname: formData.productlinkname
        };

        const { data, error } = await ProductService.editProduct(
          productId,
          updatedProductData
        );

        console.log("API Response:", data, error);

        if (data) {
          fetchProductData();
          setEditSuccess(true);
          setSelectedProduct(null);
          setTimeout(() => {
            setEditSuccess(false);
          }, 3000);
        } else {
          console.error("Error updating product:", error);
        }
      } else {
        console.error("Selected product or product id is undefined");
      }
    } catch (error) {
      console.error("Error uploading images or updating product:", error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const fetchProductData = async () => {
    try {
      const { data, } = await ProductService.getAllProducts();
  
      if (data) {
        const sortedData = data.slice().sort((a, b) => a.name.localeCompare(b.name))
        setProducts(sortedData);
        setFilteredProducts(sortedData);
      } else {
        console.error("Error fetching products:");
      }
    } catch (error) {
      console.error("Error fetching products:");
    }
  };
  

  //call the method
  useEffect(() => {
    fetchProductData();
  }, []);

  //delete product
  const handleDeleteProduct = async (productId: number) => {
    if (productId) {
      try {
        setIsLoadingDelete(true);
        const { data, error } = await ProductService.deleteProduct(productId);

        if (data) {
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product.id !== productId)
          );
          // jjj
          console.log("Product deleted successfully:", data);
          setSuccessMessageVisible(true);
          fetchProductData();
          setIsModalVisible(false);
          setSelectedProduct(null);
          setCurrentPage(1);
          setTimeout(() => {
            setSuccessMessageVisible(false);
          }, 3000);
        } else {
          console.error("Error deleting product:", error);
          setIsModalVisible(false);
        }
      } catch (error) {
        console.error("Error deleting product:", error);
        setIsModalVisible(false);
      } finally {
        setIsLoadingDelete(false);
      }
    }
  };

  return (
    <div className="p-4 rounded-md w-5/4 mx-auto">
      {isSuccessMessageVisible && (
        <ToastSuccess label="Product successfully deleted!" />
      )}
      {isEditSuccess && <ToastSuccess label="Product successfully updated!" />}
      {isSuccessProduct && (
        <ToastSuccess label="Product successfully created!" />
      )}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-black">Products </h2>
      </div>
      <div className="relative mt-4"></div>
      {showModal && (
        <ModalForm isOpen={showModal} modalId={""} closeModal={closeModal}>
          <FormNav label="Add Product" onClick={closeModal} />

          <div>
            <ProductForm
              formData={formData}
              onSubmit={handleFormSubmit}
              isUpdateDelete={false}
              onClick={closeModal}
            />
          </div>
        </ModalForm>
      )}

      {showModalEdit && selectedProduct && (
        <div>
          <ModalForm
            isOpen={showModalEdit}
            modalId={""}
            closeModal={closeModalEdit}
          >
            <FormNav label="Edit Product" onClick={closeModalEdit} />
            <div>
              <ProductForm
                formData={{
                  banner,
                  price,
                  ingredient,
                  rating,
                  name,
                  category,
                  description,
                  meta_title,
                  meta_description,
                  caption,
                  image: image,
                  productlinkname
                }}
                onSubmit={handleSaveEdit}
                isUpdate
                isUpdateDelete
                onClick={handleButtonClickk}
              />

              {isModalVisible && (
                <div
                  className="
                 backdrop-blur-sm h-[177%] max-h-[1000%] top-0 fixed
                  left-0 w-full bg-gray-800 opacity-30 z-100"
                ></div>
              )}
              {isModalVisible && (
                <div
                  id="popup-modal"
                  className="overflow-y-auto grid overflow-x-hidden
                   mt-[65%] fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                  <div className="relative p-4 w-full text-black max-w-md max-h-full">
                    <div
                      className="relative bg-white rounded-lg text-black shadow dark:bg-gray-700"
                      style={{ zIndex: 999 }}
                    >
                      <ModalDeleteHeader
                        onClick={handleModalClose}
                        label="close Modal"
                      />

                      <div className="p-4 md:p-5 text-center">
                        <ModalDeleteTitle label=" Are you sure you want to delete this product ?" />
                        {!isLoadingDelete && (
                          <div>
                            <ModalDeleteConfirm
                              label="Confirm"
                              onClick={() =>
                                selectedProduct?.id &&
                                handleDeleteProduct(selectedProduct.id)
                              }
                            />

                            <ModalDeleteCancel
                              label="Cancel"
                              onClick={handleModalClose}
                            />
                          </div>
                        )}

                        {isLoadingDelete && (
                          <IsLoadingButton
                            type="submit"
                            label="Deleting Product..."
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ModalForm>
        </div>
      )}

      {/*  */}

      <div className="bg-white p-4 rounded-md w-5/4 mx-auto">
        <div>
          <ModalButton
            title="All Products"
            label="Add Product"
            onClick={openModal}
          />
          <div className="flex items-center mb-4">
            <input
              className="border rounded-[0.50rem] border-gray-400 py-1 px-2 w-1/4 mr-4 bg-white"
              placeholder="Search Product"
              onChange={handleSearchTermChange}
              value={searchTerm}
            />
            <div className="flex-grow"></div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className=" min-w-full py-2 sm:px-6 lg:px-8">
              <div
                className="overflow-hidden"
                //  style={{ maxHeight: "360px", overflowY: "auto" }}
              >
                <table className="w-full text-xs">
                  <thead>
                    <tr>
                      <th className="border-b p-2 bg-white text-left">Image</th>
                      <th className="border-b p-2 bg-white text-left">
                        Banner
                      </th>
                      <th className="border-b p-2 bg-white text-left">
                        Product Name
                      </th>
                      <th className="border-b p-2 bg-white text-left">
                        Description
                      </th>
                      <th className="border-b p-2 bg-white text-left">
                        Ingredient
                      </th>
                      <th className="border-b p-2 bg-white text-left">
                        Caption
                      </th>
                      <th className="border-b p-2 bg-white text-left">
                        Rating
                      </th>
                      <th className="border-b p-2 bg-white text-left">Price</th>

                      <th className="border-b p-2 bg-white text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentProducts.map((product, index) => (
                      <tr key={index} className="border-b bg-white text-left ">
                        <td className="border-b h-22 bg-white text-left font-bold">
                          {product.image ? (
                            <img
                              src={product.image}
                              alt=""
                              className="w-[20px] max-w-[20px] h-22 object-cover"
                            />
                          ) : (
                            <span>No Banner uploaded yet</span>
                          )}
                        </td>

                        <td className="border-b bg-white text-left font-bold">
                          {product.banner ? (
                            <img
                              src={product.banner}
                              alt=""
                              className="object-cover w-[120px] max-w-[200px] 
                              rounded border border-solid border-gray-500"
                            />
                          ) : (
                            <span>No image Uploaded yet</span>
                          )}
                        </td>

                        <td className="border-b p-2 bg-white text-left text-black font-bold">
                          {product.name}
                        </td>

                        <td className="border-b p-2 h-22 bg-white text-left">
                          {product.description}
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          {product.ingredient}
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          {product.caption}
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          {product.rating}
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          {product.id}
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          <span
                            onClick={() => handleEditProduct(product)}
                            className="cursor-pointer rounded flex items-center"
                          >
                            <FaEdit />
                            Edit
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex space-x-4 mt-[20px] justify-end">
        <section className=" inline-flex col row">
          <div className="flex">
            {currentPage > 1 && (
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="rounded-full h-8 px-1 font-semibol bg-white  ml-[10px]"
              >
                <BiChevronLeft size={30} className="text-black" />
              </button>
            )}

            {pageNumbers.map(
              (number, index) =>
                // Display only the relevant buttons for the current page
                currentPage <= index + 2 &&
                index + 1 <= currentPage + 2 && (
                  <div className="flex space-x-3" key={number}>
                    {<div></div>}
                    <button
                      className="rounded-full h-8 px-3 font-semibol text-white "
                      onClick={() => handlePageChange(number)}
                      style={{
                        backgroundColor:
                          number === currentPage ? "#75A7AD" : "white",
                        color: number === currentPage ? "white" : "black",
                      }}
                    >
                      {number}
                    </button>
                  </div>
                )
            )}

            {currentPage < pageNumbers.length && (
              <button
                className="rounded-full h-8 px-1 font-semibol bg-white  ml-[10px]"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <BiChevronRight size={30} className="text-black " />
              </button>
            )}
          </div>
        </section>
        <section className=" flex space-x-8  p-2">
          <h5 className="font-bold text-black text-xs mr-[-20px]">
            Product per page
          </h5>
          <select
            id="role"
            className="mt-[-8px] w-[90px] py-2  block rounded-md focus:outline-none text-black text-sm bg-white"
            name="role"
            value={currentPage}
            onChange={(e) => handlePageChange(Number(e.target.value))}
          >
            {pageNumbers.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </section>
      </div>
    </div>
  );
};

export default Products;
