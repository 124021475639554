import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import ModalForm from "../ModalForm/ModalForm";
import { FormNav } from "../../FormNav/FormNav";

function HomePage() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState<string>("");

  const handleEditProduct = (productImage: string) => {
    setImage(productImage || "");
    setShowModal(true);
  };

  const handleSaveChanges = () => {
    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="p-4 rounded-md w-3/4 mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-black">Home Page</h2>
        <div></div>
      </div>

      <div className="bg-white p-4 rounded-md w-5/4 mx-auto">
        <div className="overflow-hidden">
          <ul className="bg-white shadow overflow-hidden mx-auto">
            <li>
              <div className="px-4 py-5 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-black">Home Banner</p>
                  
                </div>
                <div className="mt-4 flex items-center justify-between">
                  <img
                    className="border rounded-lg"
                    style={{ width: "160px", height: "90px" }}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/cars-rental-2a2e9.appspot.com/o/MicrosoftTeams-image%20(3).png?alt=media&token=4001cd9a-2447-4d09-b21d-498834a8691e" ||
                      ""
                    }
                    alt=""
                  />
                  <span
                    onClick={() =>
                      handleEditProduct(
                        "https://firebasestorage.googleapis.com/v0/b/cars-rental-2a2e9.appspot.com/o/MicrosoftTeams-image%20(3).png?alt=media&token=4001cd9a-2447-4d09-b21d-498834a8691e"
                      )
                    }
                    className="cursor-pointer rounded flex items-center"
                  >
                    <FaEdit />
                    Edit
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto  lg:-mx-8">
            <div className="min-w-full py-2 sm:px-6 lg:px-8"></div>
          </div>
        </div>
      </div>
      <ModalForm isOpen={showModal} modalId={""} closeModal={closeModal}>
        <FormNav label="Edit Banner" onClick={closeModal} />
        <div className="mt-[26px]">
          <div>
            <img src={image} alt="Edit Imag" />
            <div className="mt-4 w-full ">
              <input
                className="block w-full text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                type="file"
                accept="image/*"
                onChange={(e) =>
                  setImage(URL.createObjectURL(e.target.files![0]))
                }
              />
              <button
                className="bg-teal-800 w-full mt-[17px]
                text-white font-semibold py-2  border
                   border-gray-400 rounded shadow"
                onClick={() => handleSaveChanges()}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </ModalForm>
    </div>
  );
}

export default HomePage;
