import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsPlusCircle } from 'react-icons/bs';

interface ModalProps {
  onClick?: () => void;
  label?: string;
  isFull?:Boolean;
  title: string
}


const ModalButton: React.FC<ModalProps> = ({ onClick, label = 'Submit', isFull = false, title}) => {
  return (
    <div className="flex justify-between items-center mb-4">
    <h4 className="font-semibold text-black">{title}</h4>
    <button
      className="bg-gray-300 text-black px-4 py-2 rounded-md flex items-center"
      onClick={onClick}
    >
      <BsPlusCircle className="mr-2" />
     {label}
    </button>
  </div>
  );
};

export default ModalButton;