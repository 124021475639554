import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Product from "../../components/Product/Product";
import ProductContent from "../../components/ProductContent/ProductContent";
import { Link } from "react-router-dom";
import { ProductService } from "../../APIs/index";
import { ProductsFlavours } from "../../@types/productInfo";
import { Helmet } from "react-helmet"
import Header from "../../components/Header/Header";

const Flavours: React.FC<ProductsFlavours> = () => {
  const [products, setProducts] = useState<ProductsFlavours[]>([]);
  const [originalName] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<ProductsFlavours[]>(
    []
  );
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [selectedCategory, setSelectedCategory] = useState("all");
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  function toUrlSlug(text: string) {
    const decoded = decodeURIComponent(text);
    const hyphenated = decoded.replace(/\s+/g, '-');
    return hyphenated;
  }

  // Create the method to fetch all products
  const fetchProductData = async () => {
    try {
      const { data, error } = await ProductService.getAllProducts();
      if (data) {
       
        const filteredProducts = data.filter(product => product.product_type === "flavour");
        setFilteredProducts(filteredProducts);
        setProducts(filteredProducts);
      } else {
        console.error("Error fetching products:", error);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  //create a condition to display product by category
  const productCaterogyDisplay = () => {
    if (selectedCategory === "ALL") {
      return products;
    } else {
      return products.filter(
        (product) => product.category === selectedCategory
      );
    }
  };

  // Call the method
  useEffect(() => {
    fetchProductData();
  }, []);

  useEffect(() => {
    let products = productCaterogyDisplay();
    console.log(products);
    setFilteredProducts(products);
  }, [selectedCategory]);

  //listen for window resize event, and update dimensions
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const FilterBar = () => (
    <div className="w-fit flex flex-row md:flex-row md:gap-10 gap-3 rounded-full bg-white px-10 py-2 items-center text-[10px]">
      <span>Filter by:</span>
      <button
        onClick={() => setSelectedCategory("ALL")}
        className={`cursor-pointer flex items-center justify-center py-2  ${
          selectedCategory === "ALL"
            ? "text-white rounded-full bg-[#75A7AD] p-5"
            : ""
        }`}
      >
        <span>ALL</span>
      </button>
      <button
        onClick={() => setSelectedCategory("PEPSI")}
        className={`cursor-pointer flex items-center justify-center py-2  ${
          selectedCategory === "PEPSI"
            ? "text-white rounded-full bg-[#75A7AD] p-5"
            : ""
        }`}
      >
        <span>PEPSI</span>
      </button>
      <button
        onClick={() => setSelectedCategory("CLASSIC")}
        className={`cursor-pointer flex items-center justify-center py-2  ${
          selectedCategory === "CLASSIC"
            ? "text-white rounded-full bg-[#75A7AD] p-5"
            : ""
        }`}
      >
        <span>CLASSIC</span>
      </button>
      <button
        onClick={() => setSelectedCategory("ZERO")}
        className={`cursor-pointer flex items-center justify-center py-2  ${
          selectedCategory === "ZERO"
            ? "text-white rounded-full bg-[#75A7AD] p-5"
            : ""
        }`}
      >
        <span>ZERO</span>
      </button>
      <button
        onClick={() => setSelectedCategory("FRUITS")}
        className={`cursor-pointer flex items-center justify-center py-2 ${
          selectedCategory === "FRUITS"
            ? "text-white rounded-full bg-[#75A7AD] p-5"
            : ""
        }`}
      >
        <span>FRUITS</span>
      </button>
    </div>
  );


  const capitalizeFirstLetter = (str:string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {"Sparkling Water Flavours - Sparkling Water Syrup | SodaStream SA"}
        </title>
        <meta
          name="description"
          content={
            "Discover SodaStream’s sparkling water syrup flavours & beverage mixes. Make your classic soda drink at home."
          }
        />
         <link rel="canonical" href="https://www.sodastream.co.za/flavours" />
        <meta
          name="keywords"
          content="home water carbonation system,
           home water carbonation system south africa, sparkling water machine for home"
        />

        <meta name="avg-search-volume" content="10 10 40" />

        <meta name="search-intent" content="Informational, Commercial, Commercial" />
      </Helmet>
      <Header title="Sparkling Water Flavours" textBackground="FLAVOURS" />
     
      <div className="relative min-h-[5rem] w-full mt-[-17%]">
        <div className="md:relative  gap-10 z-50 flex flex-col items-center justify-center w-full">
          {screenSize.width > 1060 && (
            <div style={{ marginTop: "3%" }}>
              <FilterBar />
            </div>
          )}

          {screenSize.width < 1060 && (
            <div>
              <FilterBar />
            </div>
          )}
          <div className="mt-5">
            <div className="grid lg:grid-cols-4 gap-10 px-10 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
              <div className="md:block hidden">
                <Product>
                  <p className="text-[#75A7AD] text-start text-[23px] p-20 font-bold">
                    YOUR DREAM FLAVOURS
                  </p>
                </Product>
              </div>

              {filteredProducts.map((product, index) => (
                <div key={index}>
                  <Product>
                    <ProductContent
                    productLink={`/product/${toUrlSlug(product.productlinkname)}`}
                      cta="VIEW PRODUCT"
                      title={capitalizeFirstLetter(product.name)}
                      image={product.image}
                    />
                  </Product>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Flavours;