import React, { useState, useContext, useEffect } from "react";
import {
  FaUserCircle,
  FaShoppingCart,
  FaBook,
  FaUtensils,
  FaWater,
  FaGasPump,
  FaBox,
  FaTshirt,
  FaBlog,
  FaGlassCheers,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { Images } from "../../../assets/assets";
import { AuthContext } from "../../AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import { BsPerson, BsPersonCircle } from "react-icons/bs";
import { BiHomeAlt, BiHomeAlt2 } from "react-icons/bi";
import HomePage from "../Home/HomePage";

interface NavbarProps {}

const Sidebar: React.FC<NavbarProps> = () => {
  const iconStyle: React.CSSProperties = { marginRight: "0.5rem" };
  const location = useLocation();
  const isLinkActive = (path: string) => {
    return location.pathname === path;
  };

  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen sticky top-0">
      <nav className="ml-[-20%] mt-[2%] ">
        <ul className="bg-white lg:w-64 md:w-64 flex-col overflow-y-auto ml-4">
          {/* Add margin to the left */}
          <div className="flex-col pt-5 flex overflow-y-auto">
            <div className="flex-col pt-5 flex overflow-y-auto">
              <div className="h-full flex-col justify-between px-4 flex">
                <div className="space-y-1">
                  <div className=" mb-[35px] ">
                    <img
                      src={Images.LogoGreen2}
                      alt=""
                      className="max-w-full h-auto mb-[2rem] md:mb-0 ml-[10px]"
                    />
                  </div>

                  <Link
                    to="/dashboard/dashboardhome"
                    className={`font-medium text-sm items-center rounded-lg px-4 py-2.5
                     flex transition-all duration-200 group cursor-pointer ${
                      isLinkActive("/dashboard/dashboardhome")
                        ? "bg-gray-200"
                        : ""
                    } ${
                      isLinkActive("/dashboard/dashboardhome")
                        ? "text-[#75A7AD]"
                        : "text-gray-900"
                    }`}
                  >
                    <span className="justify-center items-center flex">
                      <BiHomeAlt className="flex-shrink-0 w-5 h-5 mr-2" />
                    </span>
                    <span>Home</span>
                  </Link>

                  <Link
                    to="/dashboard/managemembers"
                    className={`font-medium text-sm items-center rounded-lg px-4 py-2.5 flex transition-all duration-200 group cursor-pointer ${
                      isLinkActive("/dashboard/managemembers")
                        ? "bg-gray-200"
                        : ""
                    } ${
                      isLinkActive("/dashboard/managemembers")
                        ? "text-[#75A7AD]"
                        : "text-gray-900"
                    }`}
                  >
                    <span className="justify-center items-center flex">
                      <FaUsers className="flex-shrink-0 w-5 h-5 mr-2" />
                    </span>
                    <span>Manage Members</span>
                  </Link>
                  <Link
                    to="/dashboard/blogs"
                    className={`font-medium text-sm items-center rounded-lg px-4 py-2.5 flex transition-all duration-200 group cursor-pointer ${
                      isLinkActive("/dashboard/blogs") ? "bg-gray-200" : ""
                    } ${
                      isLinkActive("/dashboard/blogs")
                        ? "text-[#75A7AD]"
                        : "text-gray-900"
                    }`}
                  >
                    <span className="justify-center items-center flex">
                      <FaBook className="flex-shrink-0 w-5 h-5 mr-2" />
                    </span>
                    <span>Blogs</span>
                  </Link>
                  <Link
                    to={"/dashboard/store"}
                    className={`font-medium text-sm items-center rounded-lg px-4 py-2.5 flex transition-all duration-200 group cursor-pointer ${
                      isLinkActive("/dashboard/store") ? "bg-gray-200" : ""
                    } ${
                      isLinkActive("/dashboard/store")
                        ? "text-[#75A7AD]"
                        : "text-gray-900"
                    }`} // Use Tailwind CSS classes for color and background
                  >
                    <span className="justify-center items-center flex">
                      <FaShoppingCart className="flex-shrink-0 w-5 h-5 mr-2" />
                    </span>
                    <span>Stores</span>
                  </Link>
                  <Link
                    to={"/dashboard/recipes"}
                    className={`font-medium text-sm items-center rounded-lg px-4 py-2.5 flex transition-all duration-200 group cursor-pointer ${
                      isLinkActive("/dashboard/recipes") ? "bg-gray-200" : ""
                    } ${
                      isLinkActive("/dashboard/recipes")
                        ? "text-[#75A7AD]"
                        : "text-gray-900"
                    }`}
                  >
                    <span className="justify-center items-center flex">
                      <FaUtensils className="flex-shrink-0 w-5 h-5 mr-2" />
                    </span>
                    <span>Recipes</span>
                  </Link>
                  <Link
                    to={"/dashboard/watermachines"}
                    className={`font-medium text-sm items-center rounded-lg px-4 py-2.5 flex transition-all duration-200 group cursor-pointer ${
                      isLinkActive("/dashboard/watermachines")
                        ? "bg-gray-200"
                        : ""
                    } ${
                      isLinkActive("/dashboard/watermachines")
                        ? "text-[#75A7AD]"
                        : "text-gray-900"
                    }`}
                  >
                    <span className="justify-center items-center flex">
                      <FaWater className="flex-shrink-0 w-5 h-5 mr-2" />
                    </span>
                    <span>Water Machines</span>
                  </Link>

                  <Link
                    to={"/dashboard/products"}
                    className={`font-medium text-sm items-center rounded-lg px-4 py-2.5 flex transition-all duration-200 group cursor-pointer ${
                      isLinkActive("/dashboard/products") ? "bg-gray-200" : ""
                    } ${
                      isLinkActive("/dashboard/products")
                        ? "text-[#75A7AD]"
                        : "text-gray-900"
                    }`}
                  >
                    <span className="justify-center items-center flex">
                      <FaBox className="flex-shrink-0 w-5 h-5 mr-2" />
                    </span>
                    <span>Products</span>
                  </Link>
                  <Link
                    to={"/dashboard/accessories"}
                    className={`font-medium text-sm items-center rounded-lg px-4 py-2.5 flex transition-all
              duration-200 group cursor-pointer ${
                isLinkActive("/dashboard/accessories") ? "bg-gray-200" : ""
              } ${
                      isLinkActive("/dashboard/accessories")
                        ? "text-[#75A7AD]"
                        : "text-gray-900"
                    }`}
                  >
                    <span className="justify-center items-center flex">
                      <FaGlassCheers className="flex-shrink-0 w-5 h-5 mr-2" />
                    </span>
                    <span>Accessories</span>
                  </Link>
                  <Link
                    to={"/"}
                    onClick={logout}
                    className=" text-gray-900 font-medium text-sm items-center rounded-lg px-4 py-2.5 flex transition-all duration-200 group 
                cursor-pointer"
                  >
                    <span className="justify-center items-center flex">
                      <BsPersonCircle className="flex-shrink-0 w-5 h-5 mr-2" />
                    </span>
                    <span>Log Out</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
