import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  useRef,
} from "react";
import { IsLoadingButton, SubmitButton } from "../../Loading/ButtonLoading";
import { StoreData, labelStyle } from "../../../@types/productInfo";
import { ImageUploadUtil } from "../../../APIs";

// @ts-ignore
import Select from "react-select";
interface FormComponentProps {
  isUpdate?: boolean;
  isUpdateDelete?: boolean;

  onClick?: (e: any) => void;
  formData: StoreData | (() => StoreData);
  onSubmit: (data: StoreData) => Promise<void>;
}

const StoreForm: React.FC<FormComponentProps> = ({
  isUpdate = false,
  isUpdateDelete = false,
  onClick,
  formData,
  onSubmit,
}) => {
  const isMounted = useRef(true);
  const [localFormData, setLocalFormData] = useState<StoreData>(() => {
    if (typeof formData === "function") {
      return formData();
    }
    return formData;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<string>("");
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  useEffect(() => {
    setLocalFormData((prevData) => {
      if (typeof formData === "function") {
        return formData();
      }
      return {
        ...prevData,
        ...formData,
      };
    });
  }, [formData]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      handleFileChangeImage(e as ChangeEvent<HTMLInputElement>);
    } else {
      // Handle other input changes
      setLocalFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSelectChange = (
    selectedOptions: { value: string; label: string }[]
  ) => {
    const selectedValues = selectedOptions.map((option) => option.value);

    setLocalFormData((prevData) => ({
      ...prevData,
      sell_machine: selectedValues.includes("sell_machine"),
      cylinder_exchange: selectedValues.includes("cylinder_exchange"),
    }));
  };

  const handleFileChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      try {
        const uploadedImage = await ImageUploadUtil.uploadImage(file);

        if (uploadedImage.data) {
          console.log("Uploaded image:", uploadedImage.data);
          setLocalFormData((prevData) => ({
            ...prevData,
            image: uploadedImage.data,
          }));

          setPreviewImage(uploadedImage.data);
        } else {
          console.error("Error uploading image:", uploadedImage.error);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    console.log("Image state:", image);
  }, [image]);

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("Form data for submission:", localFormData);
    await onSubmit(localFormData);
    setIsLoading(false);
  };

  const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setLocalFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const label = isUpdateDelete ? "Delete Store" : "Cancel";
  return (
    <div className="mt-[3%]">
      <div className="flex flex-col w-full py-2">
        <div>
          <div>
            <label
              className="text-gray-600 font-bold text-sm w-full "
              style={labelStyle as React.CSSProperties}
            >
              Choose options:
              <Select
                options={[
                  { value: "sell_machine", label: "Sell Machine" },
                  { value: "cylinder_exchange", label: "Cylinder Exchange" },
                ]}
                isMulti
                className="basic-multi-select mt-2 rounded-full text-black relative bg-transparent border-[#75A7AD] mb-4 md:mb-0  w-full"
                classNamePrefix="select"
                onChange={(selectedOptions) =>
                  handleSelectChange(
                    selectedOptions as { value: string; label: string }[]
                  )
                }
                defaultValue={
                  localFormData.sell_machine || localFormData.cylinder_exchange
                    ? [
                        localFormData.sell_machine
                          ? { value: "sell_machine", label: "Sell Machine" }
                          : null,
                        localFormData.cylinder_exchange
                          ? {
                              value: "cylinder_exchange",
                              label: "Cylinder Exchange",
                            }
                          : null,
                      ]
                    : []
                }
              />
            </label>
          </div>
        </div>
        <div></div>

        <label
          htmlFor="Store_Name"
          className="text-gray-600 font-bold text-sm"
          style={labelStyle as React.CSSProperties}
        >
          Store Name
        </label>
        <input
          type="text"
          id="Store_Name"
          name="storeName"
          value={localFormData.storeName}
          onChange={handleChange}
          placeholder="Store Name"
          required
          className="w-full px-3 py-2 border text-black rounded-[0.50rem] border-gray-400 bg-white"
        />
      </div>

      <div className="flex items-center space-x-4">
        <div className="flex flex-col w-1/2">
          <label
            style={labelStyle as React.CSSProperties}
            htmlFor=""
            className="text-gray-600 font-bold text-sm"
          >
            Store ID
          </label>

          <input
            type="text"
            name="storeId"
            value={localFormData.storeId}
            onChange={handleChange}
            placeholder="Store ID"
            required
            className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
          />
        </div>

        <div className="flex flex-col w-1/2">
          <label
            style={labelStyle as React.CSSProperties}
            htmlFor="Store_No"
            className="text-gray-600 font-bold text-sm"
          >
            Store No
          </label>

          <input
            type="text"
            name="storeNo"
            value={localFormData.storeNo}
            onChange={handleChange}
            placeholder="Store No"
            required
            className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
          />
        </div>
      </div>

      <div className="flex flex-col w-full py-2">
        <label
          htmlFor="Street_Address"
          className="text-gray-600 font-bold text-sm"
          style={labelStyle as React.CSSProperties}
        >
          Street Address
        </label>
        <input
          type="text"
          id="Street_Address"
          name="streetAddress"
          value={localFormData.streetAddress}
          onChange={handleChange}
          placeholder="Enter Street Address"
          required
          className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
        />
      </div>

      <div className="flex flex-col w-full py-2">
        <label
          htmlFor="City"
          className="text-gray-600 font-bold text-sm"
          style={labelStyle as React.CSSProperties}
        >
          City
        </label>
        <input
          type="text"
          id="City"
          name="city"
          value={localFormData.city}
          onChange={handleChange}
          placeholder="Enter City"
          required
          className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
        />
      </div>
      <div className="flex flex-col w-full py-2">
        <label
          htmlFor="caption"
          className="text-gray-600 font-bold text-sm"
          style={labelStyle as React.CSSProperties}
        >
          State
        </label>
        <input
          type="text"
          id="State"
          name="state"
          value={localFormData.state}
          onChange={handleChange}
          placeholder="State"
          required
          className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
        />
      </div>

      <div className="flex items-center space-x-4">
        <div className="flex flex-col w-1/2">
          <label
            style={labelStyle as React.CSSProperties}
            htmlFor="Zip"
            className="text-gray-600 font-bold text-sm"
          >
            Zip
          </label>
          <input
            type="text"
            name="zip"
            value={localFormData.zip}
            onChange={handleChange}
            placeholder="Enter Zip"
            required
            className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
          />
        </div>

        <div className="flex flex-col w-1/2">
          <label
            style={labelStyle as React.CSSProperties}
            htmlFor="National Services Dist Desc"
            className="text-gray-600 font-bold text-sm"
          >
            Phone No
          </label>
          <input
            type="text"
            name="phoneNo"
            value={localFormData.phoneNo}
            onChange={handleChange}
            placeholder="National Services Dist Desc"
            required
            className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
          />
        </div>
      </div>

      <div className="flex flex-col w-full py-2">
        <label
          htmlFor="Store_Name"
          className="text-gray-600 font-bold text-sm"
          style={labelStyle as React.CSSProperties}
        >
          Status
        </label>
        <input
          type="text"
          id="Status"
          name="status"
          value={localFormData.status}
          onChange={handleChange}
          placeholder="Status"
          required
          className="w-full px-3 py-2 border text-black rounded-[0.50rem] border-gray-400 bg-white"
        />
      </div>

      <div className="flex items-center space-x-4">
        <div className="flex flex-col w-1/2">
          <label
            style={labelStyle as React.CSSProperties}
            htmlFor="lat"
            className="text-gray-600 font-bold text-sm"
          >
            Latitude
          </label>
          <input
            type="text"
            name="lat"
            value={localFormData.lat}
            onChange={handleChange}
            placeholder="Latitude"
            required
            className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
          />
        </div>

        <div className="flex flex-col w-1/2">
          <label
            style={labelStyle as React.CSSProperties}
            htmlFor="National Services Dist Desc"
            className="text-gray-600 font-bold text-sm"
          >
            Longitude
          </label>
          <input
            type="text"
            name="lng"
            value={localFormData.lng}
            onChange={handleChange}
            placeholder="Longitude"
            required
            className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
          />
        </div>
      </div>

      <div className="flex flex-col w-full py-2">
        <label
          htmlFor="Address Type"
          className="text-gray-600 font-bold text-sm"
          style={labelStyle as React.CSSProperties}
        >
          Address Type
        </label>
        <input
          type="text"
          id="Address_Type"
          name="addressType"
          value={localFormData.addressType}
          onChange={handleChange}
          placeholder="Address Type"
          required
          className="w-full px-3 py-2 border text-black rounded-[0.50rem] border-gray-400 bg-white"
        />
      </div>

      <div className="flex flex-col w-full py-2">
        <label
          htmlFor="Address Type"
          className="text-gray-600 font-bold text-sm"
          style={labelStyle as React.CSSProperties}
        >
          Location Type
        </label>
        <input
          type="text"
          id="Location_Type"
          name="locationType"
          value={localFormData.locationType}
          onChange={handleChange}
          placeholder="Address Type"
          required
          className="w-full px-3 py-2 border text-black rounded-[0.50rem] border-gray-400 bg-white"
        />
      </div>

      <div>
        <div className=" mt-[25px]">
          {!isLoading ? (
            <SubmitButton
              type="submit"
              onClick={handleFormSubmit}
              disabled={isLoading}
              label={isUpdate ? "Update Store" : "Add Store"}
            />
          ) : (
            <IsLoadingButton type="submit" label="Loading..." />
          )}
          <button
            onClick={onClick}
            type="button"
            className="bg-white w-full mt-[7px]
            hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border
             border-gray-400 rounded shadow"
          >
            {label}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoreForm;
