import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { Images } from '../../assets/assets';
import SecondaryButton from '../Button/SecondaryButton';

interface DropdownProps {
  label: string | ReactNode;
  items: { path: string, label: string }[];
  isShopMenu?: boolean;
  path: string;
  isOpen:boolean;
  setIsOpen:Function;
  toggle?:Function;
}

const Dropdown: React.FC<DropdownProps> = ({ label, items, isShopMenu, toggle, isOpen, setIsOpen }) => {
   
  // const [isOpen, setIsOpen] = useState(false);

  // rounce


  return (
    <div className="relative w-full">
      <button onClick={()=>toggle!()} className="btn bg-transparent border-none hover:bg-transparent">
        {label}
      </button>
      {isOpen && !!items.length && !isShopMenu && (
        <ul className="p-2 shadow menu dropdown-content z-50 bg-white text-black rounded-box w-52 absolute top-full right-0">
          {items.map((item, index) => (
            <li key={index}><Link to={item.path}>{item.label}</Link></li>
          ))}
        </ul>
      )}
      {
        isOpen && isShopMenu && !!items.length && (
          <div className='p-4 shadow z-50 bg-white text-black rounded-box absolute top-16 left-[-15rem] w-[60vw]'>
            <div className='flex w-full gap-4'>
              <Link to={'/water-makers'} className='bg-[#005D6E] basis-[24%] rounded-md px-2 pt-4'>
                <h4 className='text-white text-[12px] mb-2 font-bold'>Sparkling Water Makers</h4>
                <img src={Images.TerraBlackNavbar} alt='Terra Black Nav' />
              </Link>

              <Link to={'/co2-gas-cylinders'} className='bg-[#EEEAE5] rounded-md basis-[24%] px-2 pt-4 relative'>
                <h4 className='text-[#005D6E] text-[12px] mb-2 font-bold'>Gas</h4>
                <div className='flex gap-0 absolute bottom-0 left-1/2' style={{ transform: 'translateX(-50%)' }}>
                  <img src={Images.GasNavbar1} alt='Gas Navbar' style={{ width: "50%" }} />
                  <img src={Images.GasNavbar2} alt='Gas Navbar' style={{ width: "50%" }} />
                </div>
              </Link>

              <Link to={'/flavours'} className='bg-[#E3E3E3] rounded-md basis-[24%] relative px-2 pt-4'>
                <h4 className='text-[#005D6E] text-[12px] mb-2 font-bold'>Flavours</h4>
                <div className='flex gap-0 absolute bottom-0 left-1/2' style={{ transform: 'translateX(-50%)' }}>
                  <img src={Images.Navbar01} alt='Gas Navbar' style={{ width: "50%" }} />
                  <img src={Images.Navbar02} alt='Gas Navbar' style={{ width: "50%" }} />
                </div>
              </Link>

              <Link to={'/accessories'} className='bg-[#75A7AD] rounded-md basis-[24%] relative px-2 pt-4'>
                <h4 className='text-white text-[12px] mb-2 font-bold'>Bottles</h4>
                <div className='flex gap-0 absolute bottom-0 left-1/2' style={{ transform: 'translateX(-50%)' }}>
                  <img src={Images.Navbar4} alt='Gas Navbar' style={{ width: "50%" }} />
                  <img src={Images.Navbar42} alt='Gas Navbar' style={{ width: "50%" }} />
                </div>
              </Link>


              {/* <Link to={'/accessories'} className='bg-[#EEEAE5] rounded-md basis-[40%] relative px-2 pt-4'>
                <span className='text-[#75A7AD] text-[10px]'>Check out our new</span>
                <h4 className='text-[#75A7AD] font-bold'>90-Day Carbonation Bundle!</h4>
                <div className='flex gap-0 absolute bottom-0 left-2 items-end'>
                  <img src={Images.Navbar51} alt='Gas Navbar' style={{ width: "20%", height: "20%" }} />
                  <img src={Images.Navbar52} alt='Gas Navbar' style={{ width: "40%" }} />
                </div>

                <div className='flex gap-0 absolute top-1/2 left-1/2' style={{ transform: 'translate(-50%, 50%)' }}><SecondaryButton label='SHOP NOW' /></div>

                <div className='flex gap-0 absolute bottom-0 right-2'>
                  <img src={Images.Navbar53} alt='Gas Navbar' style={{ width: "50%" }} />
                  <img src={Images.Navbar53} alt='Gas Navbar' style={{ width: "50%" }} />
                  <img src={Images.Navbar53} alt='Gas Navbar' style={{ width: "50%" }} />
                </div>
              </Link> */}
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Dropdown;
