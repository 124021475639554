import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom"; // Assurez-vous d'importer le hook useLocation
import { IComment, ISection, WaterMachine } from "../../../@types/productInfo";
import waterMachineServiceInstance from "../../../APIs/watermachines";
import ProductInfoPanel from "../../ProductInfoPanel/ProductInfoPanel";
import Footer from "../../Footer/Footer";
import Loading from "./../../Loading/Loading";
import { Icons, Images } from "../../../assets/assets";
import RecommendationItem from "../../RecommendationItem/RecommendationItem";
import { Link } from "react-router-dom";
import SecondaryButton from "../../Button/SecondaryButton";
import { RoundedCircleComponent } from "../../../pages/SparklingWaterMaker/SparklingWaterMaker";
import PlainVideo from "../../VideoPreview/PlainVideo";
import { DefaultCarousel } from "../../ImgCarousel/Carousel";
import { BsImages, BsPlayCircleFill } from "react-icons/bs";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Navbar from "../../Navbar/Navbar";
const recommendations = [
  { image: Images.ArtStep1, title: "Quick Connect" },
  { image: Images.ArtStep2, title: "Click" },
  { image: Images.ArtStep3, title: "Fizz" },
  { image: Images.ArtStep4, title: "Enjoy" },
];


const sections: ISection[] = [
  {
    title: "Why should I purchase Art?",
    content:
      "The ART is our new premium Sparkling Water Maker and is all about your unlimited personal creation. With just a few pushes of the lever, you can craft your own homemade sparkly drinks, turning H2O into H2Wow. You can purchase the ART online or at a store near you, and enjoy bubbly masterpieces every day.",
  },
  {
    title:
      "What is the difference between the pink-handle systems, like in the ART, and the other existing Sparkling Water Maker systems?",
    content:
      "We made changes to our cylinder system to ensure our consumers get the most comfortable experience. The new Quick Connect Cylinder was designed with an advanced mechanism for simple insertion in one click, thus making the cylinder inserting process faster and more user-friendly for the consumer.",
  },
  {
    title: "Do you have a downloadable instruction manual available?",
    content: (
      <>
        You can download the{" "}
        <a
          href="https://drive.google.com/file/d/1ZjOc_DD4ZwFRBVZMZJrzuQ5gc43-05nn/view"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          manual here
        </a>
      </>
    ),
  },
  {
    title:
      "What are the differences between ART and the other Sparkling Water Makers?",
    content:
      "The ART Sparkling Water Maker offers a premium experience with enhanced features, such as the Quick Connect Cylinder and a more modern design. It's aimed at users who are looking for a premium Sparkling Water Maker.",
    image: (
      <>
        <img className="mt-5" src={Images.ManualImg} alt="manual" />
      </>
    ),
  },
  {
    title: "Do SodaStream products come with a warranty?",
    content: (
      <>
        Yes, all SodaStream Sparkling Water Makers come with at least a 2-year
        limited warranty, valid from the purchase date. If your product
        malfunctions within the warranty period, we will send you a replacement
        of new or comparable value, free of charge.
        <br />
        <br />
        For purchases in retail or from other online vendors, please check your
        Sparkling Water Maker User Manual for more warranty information.
        <br />
        <br />
        *Warranty Limitations: This warranty is given on the condition that the
        machine has been correctly used strictly in accordance with SodaStream
        instructions as detailed in your Sparkling Water Maker User Manual.
      </>
    ),
  },
];

const whyARTItems = [
  "Sleek and stylish retro design with empowering carbonating lever for a unique experience",
  "Premium, stainless-steel trim",
  "Dishwasher safe, BPA free, reusable bottles",
  "Cordless and compact design to fit every kitchen",
  'Quick Connect C02 cylinder: for fast, "one click" cylinder insertion makes up to 60L * of sparkling water * depending on the degree of carbonation.',
];

const comments: IComment[] = [
  {
    name: "Martin",
    isVerified: true,
    rating: 4,
    date: "2023-08-24",
    message:
      "Excellent replacement for my now retired SodaStream. I am looking forward to many happy PSSSST moments during the summer days ahead.",
    numLikes: 2,
    numDislikes: 1,
  },
  {
    name: "Darren",
    isVerified: true,
    rating: 5,
    date: "2022-04-18",
    message: `This is one of the few items I could recommend for every kitchen or office anywhere. Great quality product. Very easy to set up and use.  
          Makes sparkling water in seconds and with the large syrups range, you are able to have a decent sparkling beverage in seconds. 
          Also, the cylinders can be swapped out at Pick n Pay, which makes the product very affordable and easy to replace the CO2 canisters when depleted`,
    numLikes: 0,
    numDislikes: 0,
  },
];

const breadcrumbItems = [
  { text: "SHOP", href: "/water-makers" },
  { text: "SPARKLING WATER MAKERS", href: "/water-makers" },
  { text: "ART" },
];

const WaterMachinePreview: React.FC = () => {
  const [color, setColor] = React.useState("white");
  const [selectedView, setSelectedView] = React.useState("images");
  const [product, setProduct] = useState({});
  //gets id from url

  const [selectedProduct, setWaterMachineData] = useState<any>(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get("data");

    if (data) {
      setWaterMachineData(JSON.parse(decodeURIComponent(data)));
    }
  }, [location]);

  //   const { id } = useParams();
  //   const fetchProductById = async (productId: any) => {
  //     try {
  //       const { data, error } =
  //         await waterMachineServiceInstance.getWaterMachineById(productId);

  //       if (data) {
  //         setSelectedProduct(data);
  //         console.log("data:", data)
  //         console.log("Product by ID:", data);
  //       } else {
  //         console.error("Error fetching product by ID:", error);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching product by ID:", error);
  //     }
  //   };

  //   useEffect(() => {
  //     if (id) {
  //       fetchProductById(id);
  //     }
  //   }, [id]);

  return (
    <div>
      {selectedProduct ? (
        <div>
          {/* <Header isHalf textBackground='WATTER MAKERS' title='Sparkling Water Makers' backgroundImg={Images.WaterMakerProductBg} /> */}
          <Navbar />
          <section>
            <div className="bg-[#FFFFFF] text-start md:px-20 px-10 py-10 md:py-0">
              <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="flex flex-col md:flex-row md:gap-0 gap-5">
              <div className="basis-[50%] md:px-20 px-10 text-start">
                <div className="flex gap-4 items-center">
                  <div className="flex flex-col gap-4">
                    <div
                      onClick={() => setSelectedView("images")}
                      className="p-4 rounded-full bg-[#75A7AD]"
                      style={{
                        boxShadow: "0px 3px 6px #00000029",
                        backgroundColor:
                          selectedView === "images" ? "#75A7AD" : "#FFFFFF",
                      }}
                    >
                      <BsImages
                        fontSize={25}
                        color={selectedView === "images" ? "white" : "black"}
                      />
                    </div>

                    {/* 
                                           <div className='p-4 rounded-full bg-[#FFFFFF]' style={{ boxShadow: "0px 3px 6px #00000029"}}>
                                           <Tb360View fontSize={25} color='black' />
                                       </div> 
                                       */}

                    <div
                      onClick={() => setSelectedView("video")}
                      className="p-4 rounded-full bg-[#FFFFFF]"
                      style={{
                        boxShadow: "0px 3px 6px #00000029",
                        backgroundColor:
                          selectedView === "video" ? "#75A7AD" : "#FFFFFF",
                      }}
                    >
                      <BsPlayCircleFill
                        fontSize={25}
                        color={selectedView === "video" ? "white" : "black"}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    {selectedView === "images" ? (
                      <DefaultCarousel
                        images={
                          color === "black"
                            ? selectedProduct.images_black
                            : selectedProduct.images_white
                        }
                      />
                    ) : (
                      <PlainVideo
                        height="220"
                        video={selectedProduct.video || ""}
                      />
                    )}
                    {/* <p className='text-[#005D6E] text-extrabold text-center'>1 out of 6</p> */}
                  </div>
                </div>
              </div>
              <div className="basis-[50%] text-start md:px-20 px-10 flex flex-col gap-4 text-[#005D6E]">
                <div className="flex flex-col gap-4">
                  <p className="font-semibold">Select your colour:</p>
                  <div className="flex gap-4 justify-start">
                    <div
                      onClick={(e) => {
                        setColor("white");
                      }}
                    >
                      <RoundedCircleComponent bgColor="white" />
                    </div>
                    <div
                      onClick={(e) => {
                        setColor("black");
                      }}
                    >
                      <RoundedCircleComponent bgColor="black" />
                    </div>
                  </div>
                </div>
                <h2 className="text-[30px] font-bold">
                  {selectedProduct.name}
                </h2>
                <span>⭐️⭐️⭐️⭐️⭐️</span>
                <p className="text-[12px] font-light">
                  {selectedProduct.description}
                </p>
                <p className="text-[12px] font-light">
                  The Art features a retro design along with a unique
                  carbonating lever.{" "}
                </p>
                <p className="text-[12px] font-light">
                  Available in two classic colours, black and white, the machine
                  seamlessly blends into any kitchen style. Make an Art-istic
                  statement with endless possibilities.{" "}
                </p>
                <div className="flex gap-2 justify-start">
                  <div className="flex flex-col gap-2 text-center items-center basis-[20%]">
                    <img
                      src={Icons.IconBottle}
                      alt="bottle"
                      style={{ width: "35%" }}
                    />
                    <span className="text-[10px]">
                      Bottle Snap & Lock unique technology
                    </span>
                  </div>
                  <div className="flex flex-col gap-2 text-center items-center basis-[20%]">
                    <img
                      src={Icons.IconCordless}
                      alt="bottle"
                      style={{ width: "35%" }}
                    />
                    <span className="text-[10px]">Cordless</span>
                  </div>
                  <div className="flex flex-col gap-2 text-center items-center basis-[20%]">
                    <img
                      src={Icons.Icondishwasher}
                      alt="bottle"
                      style={{ width: "35%" }}
                    />
                    <span className="text-[10px]">Dishwasher safe bottle</span>
                  </div>
                  <div className="flex flex-col gap-2 text-center items-center basis-[20%]">
                    <img
                      src={Icons.IconEgronomicButton}
                      alt="bottle"
                      style={{ width: "35%" }}
                    />
                    <span className="text-[10px]">
                      Ergonomic carbonation lever
                    </span>
                  </div>
                  <div className="flex flex-col gap-2 text-center items-center basis-[20%]">
                    <img
                      src={Icons.IconPatentedButton}
                      alt="bottle"
                      style={{ width: "35%" }}
                    />
                    <span className="text-[10px]">
                      Patented Quick Connect system
                    </span>
                  </div>
                </div>

                <div className="flex gap-2 justify-center border-y border-gray-300 py-5 mt-2 items-center">
                  <img
                    src={Images.BottleClassic1L}
                    alt="bottle"
                    className="h-7 w-5"
                  />
                  <span className="font-semibold text-[#005D6E] text-[12px]">
                    1L Reusable Plastic Bottle
                  </span>
                </div>

                <div className="mt-2 ">
                  <Link to="/store-locator">
                    <SecondaryButton label="Store Locator" />
                  </Link>
                </div>

                <div>
                  <p className="font-semibold">Included in box</p>

                  <div
                    className="rounded-2xl mt-5 overflow-hidden"
                    style={{ boxShadow: "0px 7px 20px #0000001A" }}
                  >
                    {/* pack header */}
                    <div className="flex border">
                      {/* <div className='basis-[50%] px-10 py-5 border-b border-gray-200'>
                                           <h2 className='text-[24px] font-bold'>Hydration Pack</h2>
                                           <span>Rxxxx.xx Rxxxx.xx</span>
                                       </div> */}
                      <div className="basis-[100%] bg-[#EEEAE5] px-10 py-5">
                        <h2 className="text-[24px] font-bold">Starter Kit</h2>
                        {/* <span>Rxxxx.xx Rxxxx.xx</span> */}
                      </div>
                    </div>

                    {/* pack content */}
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4 text-[12px] px-10 py-4">
                      <div className="flex gap-2 items-center justify-start">
                        <span className="bg-[#005D6E] text-white px-2 py-1 text-[10px] rounded-full flex items-center justify-center">
                          1X
                        </span>
                        <span>Art Sparkling Water Maker</span>
                      </div>
                      <div className="flex gap-2 items-center justify-start">
                        <span className="bg-[#005D6E] text-white px-2 py-1 text-[10px] rounded-full flex items-center justify-center">
                          1X
                        </span>
                        <span>Quick Connect CO2 cylinder</span>
                      </div>
                      <div className="flex gap-2 items-center justify-start">
                        <span className="bg-[#005D6E] text-white px-2 py-1 text-[10px] rounded-full flex items-center justify-center">
                          1X
                        </span>
                        <span>1L Dishwasher Safe, PET bottle</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5"></div>
                <div className="my-5">
                  <div className="flex items-center justify-center flex-col gap-2">
                    <img src={Icons.IconCertification} alt="delivery" />
                    <span className="text-center w-32">2 year warranty </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:px-20 px-10 py-10 flex gap-4 md:flex-row flex-col">
              {recommendations.map((recommendation, index) => (
                <RecommendationItem
                  key={index}
                  image={recommendation.image}
                  title={recommendation.title}
                />
              ))}
            </div>

            <div className="md:px-20 px-10 pb-10 pt-5 flex gap-4 md:gap-20 items-center justify-center">
              <div className="flex flex-col gap-2 items-center">
                <img
                  src={Icons.IconFreshWater}
                  alt="bg"
                  style={{ width: "70%" }}
                />
                <span className="text-[14px] text-[#005D6E] font-bold">
                  Stay hydrated
                </span>
              </div>
              <div className="flex flex-col gap-2 items-center">
                <img
                  src={Icons.IconHeathier}
                  alt="bg"
                  style={{ width: "70%" }}
                />
                <span className="text-[14px] text-[#005D6E] font-bold">
                  Limitless taste
                </span>
              </div>
              <div className="flex flex-col gap-2 items-center">
                <img
                  src={Icons.IconSustainability}
                  alt="bg"
                  style={{ width: "70%" }}
                />
                <span className="text-[14px] text-[#005D6E] font-bold">
                  Save the planet
                </span>
              </div>
            </div>
          </section>

          <ProductInfoPanel
            sections={sections}
            comments={comments}
            specifications={{ title: "Why ART?", items: whyARTItems }}
          />
          <Footer />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default WaterMachinePreview;
