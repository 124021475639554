import React, { ReactNode } from 'react'

const WaterMakerCard = ({ children, isInfo, isNew }: { children: ReactNode, isInfo?: boolean, isNew?: boolean}) => {
  return (
    <div
    className="bg-white rounded-xl border border-gray-300 relative"
    style={{
      boxShadow: '0px 7px 40px rgba(0, 0, 0, 0.03)',
      opacity: 0.8,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',

    }}
  >
    {
          isInfo && (
              <div className='absolute -top-5 -right-5 bg-[#75A7AD] w-[70px] h-[70px] rounded-full flex items-center justify-center p-2'>
                  <p className='uppercase text-white font-bold text-[12px]'>{ isNew ? 'New': 'Save Up to 25%'}</p>
              </div>
          )
      }
    { children }
  </div>
  )
}

export default WaterMakerCard;