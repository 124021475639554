// Import necessary dependencies from React and React Router
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext/AuthContext"; // Importing AuthContext
import { Images } from "../../assets/assets";
import { Link } from "react-router-dom";
import { UserService } from "../../APIs";
import LoadingButton from "../Button/LoadingButton";
import { IsLoadingButton, SubmitButton } from "../Loading/ButtonLoading";
import { ToastSuccess } from "../Loading/Tost";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { labelStyle } from "../../@types/productInfo";

export {};
//
// Functional component for Signup
const Signin: React.FC = () => {
  // Define the UserData type
  type UserData = {
    email: string;
    password: string;
    token: string | null;
  };

  // Use the useNavigate hook to get the navigation function
  const navigate = useNavigate();

  // State variables for form data, errors, and user context
  const [formData, setFormData] = useState<UserData>({
    email: "",
    password: "",
    token: null,
  });
  const { login, user } = useContext(AuthContext);
  const [errors, setErrors] = useState<{ email?: string; password?: string }>(
    {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);

  // Function to check if the entered email is valid
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignin = async () => {
    // Input validation
    const newErrors: { email?: string; password?: string } = {};

    if (!formData.email) {
      newErrors.email = "Please enter an email.";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!formData.password) {
      newErrors.password = "Please enter a password.";
    }

    // Check if there are validation errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Clear previous errors
    setErrors({});

    try {
      setIsLoading(true);
      // Save email and password using AuthContext
      const loginData: UserData = {
        email: formData.email,
        password: formData.password,
        token: null,
      };

      login(loginData);

      // Perform login operation using UserService
      const response = await UserService.loginUser({
        email: formData.email,
        password: formData.password,
      });

      if (response.error) {
        // Handle login error
        setErrors({
          email: "Authentication failed. Please check your credentials.",
        });

        if (!navigator.onLine){

          setErrors({
            email:'Please Check your internet connection and try again'
          })
  
        }
      
        setIsLoading(false);
      } 
      
      
      
      else {
        console.log("Logged in successfully:", response.data);

        setIsLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          navigate("/dashboard/managemembers");
        }, 3000);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Error during login:", error);
      setErrors({ email: "An unexpected error occurred during login." });
      setIsLoading(false);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword: boolean) => !prevShowPassword);
  };


  return (
    <div className="min-h-screen py-10">
      <div className="container mx-auto">
        <div
          className="flex flex-col lg:flex-row w-11/12 lg:w-10/12 bg-white rounded-xl mx-auto 
        shadow-lg overflow-hidden"
        >
          <div
            className="w-full lg:w-1/2 flex flex-col items-center p-8 bg-no-repeat bg-cover bg-center relative"
            style={{
              backgroundImage:
                "url('https://firebasestorage.googleapis.com/v0/b/cars-rental-2a2e9.appspot.com/o/loginpage.png?alt=media&token=ca2ca663-df5a-4e31-af2f-48bdb1fe07d3')",
            }}
          ></div>

          <div className="w-full lg:w-1/2 py-8 px-8 bg-white">
            <h3 className="text-xl lg:text-3xl font-semibold text-purple-500">
              SIGN IN
            </h3>

            <img
              src={Images.LogoGreen2}
              alt=""
              className="w-40 h-40 object-contain mx-auto block"
            />
            {success && <ToastSuccess label="User successfully logged!" />}
            <form>
              {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
              {errors.password && (
                <p style={{ color: "red" }}>{errors.password}</p>
              )}
              <div className="mt-3">
                <label
                  htmlFor="email"
                  className="text-gray-600 font-bold mr-2 text-sm"
                  style={labelStyle as React.CSSProperties}
                >
                  Email
                </label>

                <input
                  type="text"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  className="border rounded-[0.50rem] border-gray-400 py-1 px-2 w-full bg-white"
                />
              </div>
              <div className="mt-3">
                <label
                  htmlFor="email"
                  className="text-gray-600 font-bold mr-2 text-sm"
                  style={labelStyle as React.CSSProperties}
                >
                  Password
                </label>

               
                 <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                placeholder="Password"
                required
                className="w-full px-3 py-2 border text-black rounded-[0.50rem] border-gray-400 bg-white pr-10"
              />
              <div
                className="absolute inset-y-0 mt-6 right-0 flex items-center pr-2 cursor-pointer"
                onClick={handleTogglePassword}
              >
                {showPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
              </div>
            </div>
              </div>
              <div className="flex items-center mt-4">
                <Link
                  to={"/forgotpassword"}
                  className="text-gray-600 font-bold mr-2 text-sm ml-auto"
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="mt-3">
                {!isLoading && (
                  <SubmitButton
                    type="submit"
                    onClick={handleSignin}
                    disabled={isLoading}
                    label="Sign In"
                  />
                )}

                {isLoading && (
                  <IsLoadingButton type="submit" label="Sign in..." />
                )}
              </div>
              
            
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
