import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Icons, Images } from "../../assets/assets";
import "./styles.css";
import { Helmet } from "react-helmet";
const TermsCompetition = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Competition Terms | SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Familiarise yourself with the terms and conditions of SodaStream competitions. Join us to win prizes!"
          }
        />
         <link rel="canonical" href="https://www.sodastream.co.za/competition-terms" />
      </Helmet>
      <Header
        title="Terms & Conditions"
        subTitle=""
        textBackground="Ts & Cs"
        backgroundImg={Images.Page27BG}
      />
      <div className="relative min-h-[100rem] w-full">
        <div className="relative md:top-[-5rem] top-0 gap-10 md:z-50 z-9 flex flex-col items-center justify-center  w-full">
          <div className="mt-5 lg:px-60 md:px-40 sm:px-20 px-5 w-full">
            <div className="bg-white rounded-xl border border-gray-300 relative w-full opacity-80 px-10 py-20 text-[#005D6E]">
              <div className="flex flex-col gap-4 text-[12px] text-start">
                <h1 className="text-4xl">Terms and Conditions</h1>
                <h2 className="text-lg">
                  By entering the SodaStream Kitchen Makeover Competition,
                  entrants agree to the rules set out below:
                </h2>
                <ul>
                  <li className="">
                    The promotional competition is open to all South African
                    residents residing in South Africa in all nine (9) Provinces
                    and are in possession of a valid identity document, valid
                    South African permit, except any director, member, partner,
                    employee, agent or consultant of the Promoter,{" "}
                    <b>SodaStream South Africa</b>. The marketing service
                    providers or any person who directly or indirectly controls
                    or is controlled by these entities, including their spouses,
                    life partners, business partners or immediate family members
                  </li>
                  <li>
                    Any participant under the age of 18 years must be fully
                    assisted by his/her guardian, who approves of and consents
                    to the participant’s participation in the competition and
                    the participant’s receipt/possession of the prize.
                  </li>
                  <li>
                    To participate in this competition, Participants must adhere
                    to the following:
                  </li>

                  <div className="ml-10">
                    <p>- Buy ANY SodaStream Participating products</p>
                    <p>- SodaStream Art Machine</p>
                    <p>- SodaStream Terra Machine</p>
                    <p>
                      - SodaStream Source Firework Carbonating Fuse Bottles,
                      Pack of 3
                    </p>
                    <p>- My only bottle 0.5L Icy Blue</p>
                    <p>- SodaStream 1L Carbonating Bottle Pack of 2</p>
                    <p>- Gas cylinders</p>
                    <p>- SodaStream Syrups</p>
                  </div>

                  <li>
                    {" "}
                    Dial *120*1333# and follow the prompts to Stand A Chance To
                    Win Yourself a Kitchen Makeover worth R100 000
                  </li>
                  <li> USSD costs 20c/20sec.</li>
                  <li>
                    {" "}
                    Grand Prize: A total of one (1) winners will be drawn at
                    random by the USSD service provider, the winner receiving
                    R100 000.00 to be used towards a kitchen makeover.
                    Participants may enter the competition as many times as they
                    wish over the promotional period 01 March – 05 May 2024 if
                    participants purchase the participating products each time
                    and complete the competition entry, subject to the following
                    conditions:
                  </li>
                  <div className="ml-10">
                    <p>
                      - All entries from disqualified participants’ cell phone
                      numbers will be rejected and blacklisted for the remainder
                      of the campaign.
                    </p>
                    <p>- No computer-based entries will be accepted</p>
                    <p>
                      - Blocked Participants have an opportunity to call the
                      Client Call Centre on 011 514 0160 (Tradeway number)
                      between 09h00 - 16h30 Monday to Friday should there be a
                      valid reason, which is accepted by the Promoter, for the
                      Blocked Participants entering an incorrect code 5 (five)
                      times in succession.
                    </p>

                    <p>- Entries will not be recognised from 11pm to 5am.</p>

                    <p>
                      This promotional competition is open from 01 March 2024
                      and ends at midnight on 5 May 2024. Any entries received
                      after the closing date will not be considered.
                    </p>
                    <p>
                      The judges&#39; decision is final, and no correspondence
                      will be entered into.
                    </p>
                  </div>

                  <li>
                    No substitution or cash redemption of non-cash prizes is
                    permitted. Prizes are not transferable.
                  </li>

                  <li>
                    Participants must enter on a phone that is owned and
                    registered to that person. No other person can use another
                    person’s phone for competition entry purposes. If it is
                    discovered that participants have entered of a phone that is
                    not registered to them, they will be disqualified, and the
                    entrant will be requested to refund the prizes to promoter.
                    This is to avoid fraud and to ensure compliance to the CPA.
                  </li>
                  <li>
                    Therefore, each entrant is expected to be the owner of that
                    phone and no singular person can enter from another mobile
                    phone number. This is to avoid additional fraud being
                    conducted by multiple SIM card owners.
                    <div className="ml-10">
                      - The cell phone number is key to all entries, if the
                      entrant has won on a cell phone number, they cannot use
                      another number to be contacted further, this number needs
                      to be a number that is contactable during office hours.
                    </div>
                  </li>
                  <li>
                    Each entrant is expected to have purchased the products (2)
                    and be able to produce; their unique code, a valid till
                    slip, a copy of their ID, bank account confirmation and
                    proof of residence, if requested by{" "}
                    <b>SodaStream South Africa</b>.
                  </li>
                  <li>
                    Although the competition is executed by Tradeway (“the
                    Agency”), The promoter is
                    <b>SodaStream South Africa</b>. (“the Promoter”). By
                    entering this competition, you authorise{" "}
                    <b>SodaStream South Africa</b>. (“the Promoter”), to
                    collect, store and use (not share) personal information of
                    entrants for communication or statistical purposes.
                  </li>

                  <li>
                    The personal information you submit to enter this promotion
                    will be used only for purposes of administering the
                    promotion. Please see SodasStream South Africa’s website or
                    social media, available on www.SodaStream.co.za / Instagram:
                    SodaStreamsouthafrica for contact information and further
                    details.
                  </li>
                  <li>
                    The Promoter reserves the right to use the images taken of
                    the winner for publicity purposes in any manner they deem
                    fit, without remuneration being made payable to the winner.
                    However, the winner has the right to object to these images
                    being used by written notification to the Promoter at{" "}
                    <b>SodaStream South Africa</b> , 22 Ealing Cres, Bryanston,
                    Sandton, 2191. Attention: Reception Tel: 012 345 9220 For
                    further information or enquiries please email our consumer
                    services
                    <a href="mailto:customercare-sa@SodaStream.com">
                      {" "}
                      customercare-sa@SodaStream.com
                    </a>{" "}
                    or call Customer homecare line: +27 (0) 8000 2291.
                  </li>
                  <li>
                    <b>SodaStream South Africa</b>. reserves the right to
                    extend, shorten or suspend the competition period due to
                    technical, commercial, or operational reasons, public
                    interest, events beyond its control or generally for any
                    reason whatsoever within its sole discretion. In the event
                    that the competition period is extended, shortened, or
                    suspended, participants will be notified by{" "}
                    <b>SodaStream South Africa</b>. thereof as soon as
                    reasonably possible through a notice on its website. All
                    participants who have received confirmation of a prize prior
                    to any suspension or shortening of the competition will
                    remain eligible to receive the prizes that have been awarded
                    to them in accordance with these terms and conditions.
                  </li>
                  <li>
                    No participants shall have any recourse or claim against{" "}
                    <b>SodaStream South Africa</b>. as a result of:
                  </li>
                  <div className="ml-10">
                    <p>a) any amendment to these terms and conditions,</p>
                    <p>
                      b) <b>SodaStream South Africa</b>. cancellation,
                      termination or suspension of the competition in line with
                      these terms and conditions and/or
                    </p>
                    <p>
                      c) <b>SodaStream South Africa</b> inability, despite
                      attempts in line with these terms and conditions, to reach
                      a participant.
                    </p>
                  </div>

                  <li>
                    There is 1 winner to win R100 000 towards a Kitchen
                    Makeover:
                  </li>

                  <div className="ml-10">
                    - The following process will be followed to enter the
                    competition: the participant must <b>dial **120*1333#</b>{" "}
                    and follow the prompts. They will be asked to send through,
                    their unique code, a valid till slip, a copy of their ID and
                    proof of residence. Winners will be notified via phone call,
                    to let them know they have won.
                  </div>
                  <li>Kitchen Makeover:</li>
                  <div className="ml-10">
                    - 1 winner for the kitchen makeover to be drawn/selected 15
                    May 2024
                  </div>
                  <div className="ml-10">
                    - Should the winner not be reachable after three (3)
                    attempts made within three (3) working days of their name
                    being drawn, for whatsoever reason, including: incorrect
                    telephone numbers or inoperative telephone numbers, such
                    winner will be disqualified and a runner up finalist will be
                    deemed the potential prize winner, subject to the T’s &amp;
                    C’s herein
                  </div>
                  <div className="ml-10">
                    - Failure to claim the Prize or a refusal or inability to
                    required documentation or comply with any of the competition
                    requirements within 1 month will disqualify the Winner and a
                    new Winner will be drawn in terms of these rules.
                  </div>
                  <div className="ml-10">
                    - The R100 000 prize will be paid over directly to Tradeway
                    Promotion’s appointed contractor, and contractor will supply
                    a render to the winner on kitchen inspiration of choice.
                  </div>

                  <li>
                    Once <b>SodaStream South Africa</b>. and the Agency have
                    confirmed the dispatch of prize to the winning number and
                    confirmed the redemption of the prize from the Agency, the
                    consumer will have no further claim against the Promoter or
                    the Agency regarding the Prize. If the consumer did not
                    personally accept the reward, the consumer must open a case
                    to investigate further.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsCompetition;
