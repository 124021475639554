import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { IsLoadingButton, SubmitButton } from "../../Loading/ButtonLoading";
import {
  AccessData,
  AccessProps,
  AccessRecipe,
  AccessoryData,
  DataRecipe,
  FormData,
  Product,
  Recipe,
} from "../../../@types/productInfo";
import { LabelStyle } from "../../../@types/productInfo";
import { ImageUploadUtil, ProductService } from "../../../APIs";
import { FaTimesCircle } from "react-icons/fa";
import { BsPlusCircle } from "react-icons/bs";
import { labelStyle } from "../../../@types/productInfo";

const FormRecipe: React.FC<AccessRecipe> = ({
  isUpdate = false,
  isUpdateDelete = false,
  onClick,

  formData,
  onSubmit,
}) => {
  const [localFormData, setLocalFormData] = useState<DataRecipe>(formData);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [showPercentage, setShowPercentage] = useState(false);
  //call the method
  
  useEffect(() => {
    fetchProductData();
  }, []);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setLocalFormData((prevData) => {
      if (name === "newMethod") {
        return {
          ...prevData,
          newMethod: value,
        };
      } else if (name === "newIngredient") {
        return {
          ...prevData,
          newIngredient: value,
        };
      } else if (name === "method") {
        return {
          ...prevData,
          methods: [...prevData.methods, value],
        };
      } else if (name === "ingredient") {
        return {
          ...prevData,
          ingredients: [...prevData.ingredients, value],
        };
      } else if (name === "productId") {
        return {
          ...prevData,
          productId: parseInt(value, 10),
        };
      } else {
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
  
    await onSubmit(localFormData);
    setIsLoading(false);
  };

  const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setLocalFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setShowPercentage(true);
    if (file) {
      setShowPercentage(true);
      try {
        const uploadedImage = await ImageUploadUtil.uploadImage(file);
        setShowPercentage(false);
        if (uploadedImage.data) {
          setLocalFormData((prevData) => ({
            ...prevData,
            image: uploadedImage.data,
          }));

          setPreviewImage(uploadedImage.data);
        } else {
          console.error("Error uploading image:", uploadedImage.error);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleAddIngredient = () => {
    if (localFormData.newIngredient.trim() !== "") {
      setLocalFormData((prevData) => ({
        ...prevData,
        ingredients: [
          ...prevData.ingredients,
          localFormData.newIngredient.trim(),
        ],
        newIngredient: "",
      }));
    }
  };

  const handleAddMethod = () => {
    if (localFormData.newMethod.trim() !== "") {
      setLocalFormData((prevData) => ({
        ...prevData,
        methods: [...prevData.methods, localFormData.newMethod.trim()],
        newMethod: "",
      }));
    }
  };

  const handleRemoveMethod = (index: any) => {
    setLocalFormData((prevData) => {
      const updatedMethods = prevData.methods.filter((_, i) => i !== index);
      return { ...prevData, methods: updatedMethods };
    });
  };

  const handleRemoveIngredient = (index: any) => {
    setLocalFormData((prevData) => {
      const updatedIngredients = prevData.ingredients.filter(
        (_, i) => i !== index
      );
      return { ...prevData, ingredients: updatedIngredients };
    });
  };

  const fetchProductData = async () => {
    try {
      const { data, error } = await ProductService.getAllProducts();

      if (data) {
        setProducts(data);
        console.log("data:", products);
      } else {
        console.error("Error fetching products:", error);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handlePreview = () => {
    const previewUrl = `/recipe-preview?data=${encodeURIComponent(
      JSON.stringify(localFormData)
    )}`;
    window.open(previewUrl, "_blank");
  };

  const label = isUpdateDelete ? "Delete Recipe" : "Cancel";
  return (
    <form className="mt-[3%]">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col">
          <label
            htmlFor="recipeName"
            style={labelStyle as React.CSSProperties}
            className="text-gray-600 font-bold text-sm mb-2"
          >
            Recipe Name
          </label>
          <input
            id="recipeName"
            name="name"
            type="text"
            value={localFormData.name}
            onChange={handleChange}
            placeholder="Enter Recipe Name"
            required
            className="w-full px-3 py-2 mb-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
          />
        </div>

        <div className="flex items-center space-x-4">
          <div className="flex flex-col w-[85%]">
            <label
              style={labelStyle as React.CSSProperties}
              htmlFor="Method"
              className="text-gray-600 font-bold text-sm"
            >
              Method
            </label>

            <input
              id="method"
              name="newMethod"
              value={localFormData.newMethod}
              onChange={handleChange}
              placeholder="Enter Method"
              required
              className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
            />
          </div>
          <div className="flex flex-col w-[15%] mt-[24px]">
            <button
              type="button"
              className="bg-teal-500  text-white px-2 py-[7px] rounded-md flex items-center cursor-pointer"
              onClick={handleAddMethod}
            >
              <BsPlusCircle size={20} className="mr-1" />
              Add
            </button>
          </div>
        </div>

        {localFormData.methods.length > 0 && (
          <div
            className="border p-1 bg-white rounded "
            style={{ maxHeight: "70px", overflowY: "auto" }}
          >
            <ul className="max-w-md divide-y divide-gray-200 dark:divide-gray-700 flex flex-wrap">
              {localFormData.methods.map((method, index) => (
                <li
                  key={index}
                  className="w-full sm:w-1/2 border p-1 flex justify-between items-center space-x-4"
                  style={{ marginBottom: "1px" }}
                >
                  <p className="text-xs font-medium text-gray-900 truncate dark:text-white">
                    {method}
                  </p>
                  <div className="text-red-500 cursor-pointer">
                    <FaTimesCircle
                      size={19}
                      onClick={() => handleRemoveMethod(index)}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="flex items-center space-x-4">
          <div className="flex flex-col w-[85%]">
            <label
              htmlFor="Ingredient"
              style={labelStyle as React.CSSProperties}
              className="text-gray-600 font-bold text-sm "
            >
              Ingredient
            </label>

            <input
              id="ingredient"
              name="newIngredient"
              value={localFormData.newIngredient}
              onChange={handleChange}
              placeholder="Enter Ingredient"
              required
              className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
            />
          </div>
          <div className="flex flex-col w-[15%] mt-[26px]">
            <button
              type="button"
              className="bg-teal-500  text-white px-2 py-[7px] rounded-md flex items-center cursor-pointer"
              onClick={handleAddIngredient}
            >
              <BsPlusCircle size={20} className="mr-1" />
              Add
            </button>
          </div>
        </div>

        {localFormData.ingredients.length > 0 && (
          <div
            className="border p-1 bg-white rounded "
            style={{ maxHeight: "70px", overflowY: "auto" }}
          >
            <ul className="max-w-md divide-y divide-gray-200 dark:divide-gray-700 flex flex-wrap">
              {localFormData.ingredients.map((ingredient, index) => (
                <li
                  key={index}
                  className="w-full sm:w-1/2 border p-1 flex justify-between items-center space-x-4"
                  style={{ marginBottom: "1px" }}
                >
                  <p className="text-xs font-medium text-gray-900 truncate dark:text-white">
                    {ingredient}
                  </p>
                  <div className="text-red-500 cursor-pointer">
                    <FaTimesCircle
                      size={19}
                      onClick={() => handleRemoveIngredient(index)}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        <label
          htmlFor="Ingredient"
          style={labelStyle as React.CSSProperties}
          className="text-gray-600 font-bold text-sm"
        >
          Flavour
        </label>

        <div>
          <select
            id="category"
            name="productId"
            className="w-full mt-[-20%] px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
            value={localFormData.productId || ""}
            onChange={handleChange}
          >
            <option disabled className="text-gray-500">
              Flavour
            </option>
            {products.map((product) => (
              <option key={product.id} value={String(product.id)}>
                {product.name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col w-full ">
          <div className="flex flex-col w-full ">
            <label
              style={labelStyle as React.CSSProperties}
              htmlFor="firstName"
              className="text-gray-600 font-bold text-sm"
            >
              Description
            </label>
            <textarea
              name="description"
              value={localFormData.description}
              onChange={handleChangeTextArea}
              placeholder="Describe the product"
              required
              className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between space-x-4 mt-[4%] ">
        {localFormData.image && (
          <div className="w-[50%] float-left mt-[6%] items-center justify-center h-[120px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 ">
            <div className="p-4 w-full">
              <div className="w-auto">
                <div className=" py-0 rounded justify-center ">
                  <a href="#">
                    <img
                      className=" border rounded justify-center mt-[-2%] "
                      src={localFormData.image || ""}
                      alt=""
                      style={{
                        width: "auto",
                        maxHeight: "100px",
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="w-[50%] h-[auto] float-right">
          <label
            htmlFor="Ingredient"
            style={labelStyle as React.CSSProperties}
            className="text-gray-600 font-bold text-sm mb-2"
          >
            Image
          </label>
          <div className="flex justify-center w-full mb-[2%]">
            <label
              htmlFor="dropzone-file-1"
              className="flex flex-col items-center justify-center w-full h-[120px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pt-2 pb-3">
                {showPercentage && (
                  <div className="flex gap-2 mb-4 mt-2">
                    <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                    <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                    <div className="w-4 h-4 rounded-full animate-pulse bg-teal-600"></div>
                  </div>
                )}
                <svg
                  className="w-6 h-6 mb-2 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-1 text-xss text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop icon
                </p>
              </div>

              <input
                id="dropzone-file-1"
                type="file"
                onChange={handleFileChangeImage}
                className="hidden"
              />
            </label>
          </div>
        </div>
      </div>
      <div>

        <div className=" items-center ">
          <div className="flex flex-col w-full">
            <label
              style={labelStyle as React.CSSProperties}
              htmlFor="Meta Title"
              className="text-gray-600 font-bold text-sm"
            >
              Meta Title
            </label>
            <input
              type="text"
              id="meta_title"
              name="meta_title"
              value={localFormData.meta_title}
              onChange={handleChange}
              placeholder="Caption"
              required
              className="w-full px-3 py-2 mb-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
            />
          </div>

        </div>
        <div className="flex flex-col w-full mt-[12px]">
          <div className="flex flex-col w-full ">
            <label
              style={labelStyle as React.CSSProperties}
              htmlFor="firstName"
              className="text-gray-600 font-bold text-sm"
            >
              Meta Description
            </label>
            <textarea
              name="meta_description"
              value={localFormData.meta_description}
              onChange={handleChangeTextArea}
              placeholder="Describe the product"
              required
              className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
            />
          </div>
          <div className="flex flex-col mt-3">
          <label
            htmlFor="recipeName"
            style={labelStyle as React.CSSProperties}
            className="text-gray-600 font-bold text-sm mb-2"
          >
            Recipe Link
          </label>
          <input
            id="recipeName"
            name="recipelink"
            type="text"
            value={localFormData.recipelink}
            onChange={handleChange}
            placeholder="Enter Recipe Link"
            required
            className="w-full px-3 py-2 mb-2 text-black border rounded-[0.50rem] border-gray-400 bg-white"
          />
        </div>
        </div>
        <div className=" mt-[5px]">
          {!isLoading ? (
            <SubmitButton
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
              label={isUpdate ? "Update Recipe" : "Add Recipe"}
            />
          ) : (
            <IsLoadingButton type="submit" label="Loading..." />
          )}
          <button
            onClick={onClick}
            type="button"
            className="bg-white w-full mt-[7px]
            hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border
             border-gray-400 rounded shadow"
          >
            {label}
          </button>

          <button
            onClick={handlePreview}
            type="button"
            className="bg-white w-full mt-[7px]
            hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border
             border-gray-400 rounded shadow"
          >
            Preview Recipe
          </button>
        </div>
      </div>
    </form>
  );
};

export default FormRecipe;
