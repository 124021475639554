import React from 'react';
import { FaShareSquare } from "react-icons/fa";
import { AiFillLike, AiFillDislike } from "react-icons/ai";

interface CommentFooterProps {
  numLikes: number;
  numDislikes: number;
}

const CommentFooter: React.FC<CommentFooterProps> = ({ numLikes, numDislikes }) => {
  return (
    <div className='flex justify-between text-[#005D6E]'>
      <div className='flex gap-2 items-center'>
        <FaShareSquare />
        <span>Share</span>
      </div>
      <div className='flex gap-2 items-center'>
        <p>Was this Review Helpful?</p>
        <AiFillLike color='black' />
        <span className='text-black'>{numLikes}</span>
        <AiFillDislike color='black' />
        <span className='text-black'>{numDislikes}</span>
      </div>
    </div>
  );
};

export default CommentFooter;
