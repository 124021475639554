import React from 'react';

interface SecondaryButtonProps {
  onClick?: () => void;
  label?: string;
}

const OutlinedButton: React.FC<SecondaryButtonProps> = ({ onClick, label = 'Submit' }) => {
  return (
    <button
      onClick={onClick}
      className="bg-transparent rounded-[46px] opacity-100 flex items-center justify-center cursor-pointer px-4 border border-white"
    >
      <span className="text-[12px] leading-[29px] text-white uppercase font-bold">
        {label}
      </span>
    </button>
  );
};

export default OutlinedButton;
