import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Images } from "../../assets/assets";
// import RecommendationItem from '../../components/RecommendationItem/RecommendationItem';
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { ProductService, RecipeService } from "../../APIs";
import Loading from "../../components/Loading/Loading";
import { Mixology } from "../../@types/productInfo";
import SocialIcon from "../../components/SocialIcon/SocialIcon";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { BiLogoFacebook } from "react-icons/bi";

const MixologyPage: React.FC<Mixology> = () => {
  const [selectedRecipe, setSelectedRecipe] = useState<Mixology | null>(null);
  const [productId, setProductId] = useState<any>(null);

  const [product, setProduct] = useState<any>();

  const breadcrumbItems = [
    { text: "HOME", href: "/" },
    { text: "MIXOLOGY", href: "/mixology" },
    { text: selectedRecipe ? selectedRecipe.name : "Loading..." },
  ];

  const { recipelink } = useParams<{ recipelink: any }>();

  const encodedUrl = recipelink ? recipelink.replace(/-/g, " ") : "";

  const fetchRecipeById = async (recipelink: any) => {
    try {
      const { data, error } = await RecipeService.getRecipeByLink(recipelink);

      if (data) {
        setSelectedRecipe(data);
        setProductId(data.id);
      } else {
        console.error("Error fetching Recipe by Name:", error);
      }
    } catch (error) {
      console.error("Error fetching Recipe by Name:", error);
    }
  };

  const fetchProductData = async (productId?: any) => {
    try {
      const { data, error } = await ProductService.getProductById(productId);

      if (data) {
        setProduct(data);
      } else {
        console.error("Error fetching product:", error);
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  useEffect(() => {
    if (encodedUrl) {
      fetchRecipeById(encodedUrl);
    }
  }, [encodedUrl]);

  //call the method
  useEffect(() => {
    if (selectedRecipe != null && selectedRecipe.productId != null) {
      fetchProductData(selectedRecipe.productId);
    }
  }, [selectedRecipe]);

  const capitalizeFirstLetter = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  function toUrlSlug(text: string) {
    const decoded = decodeURIComponent(text);
    const hyphenated = decoded.replace(/\s+/g, "-");
    return hyphenated;
  }

  return (
    <div>
      {selectedRecipe ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{selectedRecipe.meta_title}</title>
            <meta
              name="description"
              content={selectedRecipe.meta_description}
            />
              <link rel="canonical" href={`https://www.sodastream.co.za/${toUrlSlug(selectedRecipe.recipelink)}`} />
          </Helmet>
          <Header
            title={selectedRecipe.name === "You're A Jam" ? " You're A Jam Mixology" :
             selectedRecipe.name === "Autumn Elixir" ? "Autumn Elixir Mixology" : selectedRecipe.name}
            subTitle="Cocktails, mocktails and more!"
            backgroundImg={Images.RecommendationPageBG}
            textBackground="MIXOLOGY"
          />

          <section>
            <div>
              <div className="bg-[#FFFFFF] text-start px-5 md:px-20 pt-5">
                <Breadcrumb items={breadcrumbItems} />
              </div>
            </div>
            <div className="grid lg:grid-cols-2 gap-0 md:grid-cols-1">
              <div className="bg-[#E3E3E3] p-20 relative w-full">
                <div className="flex items-center justify-center">
                  <img
                    src={selectedRecipe?.image || ""}
                    alt="full glass"
                    className="rounded-[7%]"
                  />
                </div>

                <>
                  {product?.image && (
                    <img
                      className="absolute bottom-5 right-[0]"
                      src={product?.image}
                      alt="sodastream flavour"
                      width={"10%"}
                    />
                  )}
                  {product ? (
                    <Link
                      to={"" || "#"}
                      className="absolute bottom-5 right-20 text-[#75A7AD]"
                    >
                      VIEW FLAVOUR
                    </Link>
                  ) : (
                    ""
                  )}
                </>
              </div>
              <div className="bg-[#75A7AD] px-20 py-10 text-white text-start flex flex-col gap-5">
                <h3 className="text-left text-white font-bold opacity-100 text-[35px]">
                  {selectedRecipe.name}
                </h3>
                <div className="flex flex-col gap-0">
                  <h4 className="font-semibold mb-1">INGREDIENTS</h4>
                  {selectedRecipe.ingredients.map((ingredient) => (
                    <p key={ingredient}>{ingredient}</p>
                  ))}
                </div>
                <div>
                  <h4 className="font-semibold mb-1">METHOD</h4>
                  {selectedRecipe.methods.map((method) => (
                    <p key={method}>{method}</p>
                  ))}
                </div>
                <div className="flex md:gap-2 items-center flex-wrap">
                  <p className="text-[10px]">SHARE THIS RECIPE WITH FRIENDS</p>
                  <div className="mt-1">
                    <div className="flex gap-4 items-center justify-start">
                      <SocialIcon>
                        <a
                          className="twitter-share-button"
                          href="https://twitter.com/intent/tweet?text=Hello%20world"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <BsTwitter />
                        </a>
                      </SocialIcon>
                      <SocialIcon>
                        <a
                          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.sodastream.co.za%2F&amp;quote=Votre%20texte%20ici"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <BiLogoFacebook />
                        </a>
                      </SocialIcon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default MixologyPage;
