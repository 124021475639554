import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbItem {
  text: string;
  href?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <div className='text-[12px] text-[#75A7AD] h-[50px]' style={{ fontSize: '16px' }}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item.href ? (
            <Link className='cursor-pointer pt-7' to={item.href} >{item.text.toLowerCase()} </Link>
          ) : (
            item.text.toLowerCase()
          )}
          {index < items.length - 1 && ' > '}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
