import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Images } from "../../assets/assets";
import RecommendationItem from "../../components/RecommendationItem/RecommendationItem";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { BlogsService } from "../../APIs";
import { Helmet } from "react-helmet";
import queryString from "query-string";
const recommendations = [
  {
    image: Images.Benefits1GallonWater,
    title: "The Benefits of Drinking 8 Glasses of Water a Day",
    link: "/blog/1",
    action: "READ MORE",
  },
  {
    image: Images.StayHydratedPost,
    title: "Staying Hydrated at Work",
    link: "/blog/2",
    action: "READ MORE",
  },
  {
    image: Images.Post2,
    title: "Sparkling Water",
    link: "/blog/3",
    action: "READ MORE",
  },
];

const BlogsPage = () => {
  const [blogs, setBlogs] = useState<any[]>([]);

  const fetchBlogsData = async () => {
    try {
      const { data, error } = await BlogsService.getAllBlogs();
      if (data) {
        setBlogs(data);
      } else {
        console.error("Error fetching blogs:");
      }
    } catch (error) {
      console.error("Error fetching :");
    }
  };

  // Call the method
  useEffect(() => {
    fetchBlogsData();
  }, []);

  function toUrlSlug(text: string) {
    const decoded = decodeURIComponent(text);
    const hyphenated = decoded.replace(/\s+/g, "-");
    return hyphenated;
  }

  const capitalizeFirstLetter = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"SodaStream Blog | SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Stay updated with SodaStream's latest news, tips, and recipes. Explore our sparkling blog for exciting articles!"
          }
        />
        <link rel="canonical" href={"https://www.sodastream.co.za/blog"} />
      </Helmet>
      {blogs.length > 0 && (
        <div>
          <Header
            textBackground="SPARKLING"
            backgroundImg={"http://res.cloudinary.com/brabenjos/image/upload/v1723093154/sodastream/1723093153077.png"}
            title={"The Sparkling Blog"}
          />
        </div>
      )}

<section className="relative w-full mt-[-8%] z-50 h-auto ">
        <div className="px-7 py-8 flex flex-col gap-4">
          <section>
            <section>
        <div>
   
          <div className="mt-4">
            
            
            <div>
            <div className="flex flex-wrap md:flex-nowrap w-full">
  {blogs.map((card) => (
    <div key={card.id} className="w-full md:w-1/3 flex items-center justify-center mb-6 md:mb-0 md:mr-6 last:mr-0">
      <Link to={`/blog/${toUrlSlug(card.bloglink)}`}>
        <div className="max-w-sm dark:bg-gray-800 flex flex-col h-full">
        <div className="h-[300px] mx-auto w-full sm:w-[300px]">
  <img className="rounded-[15px] w-full h-full object-cover" src={card.image} alt={card.title} />
  </div>


          <div className="mt-[15px] flex-grow flex flex-col justify-between">
            <div className="flex-grow">
              <p className="box-border font-bold text-[#005D6E] text-[18px] h-[70px] 
              leading-normal mb-0 mt-0 mr-1.5 ml-1.5 text-left font-poppins">
                {card.title}
              </p>
            </div>

            <button className='text-[#005D6E] w-full max-w-[120px] border-[2px] border-[#75A7AD] h-[30px]
             text-[16px] font-semibold
               rounded-full bg-white flex items-center 
               justify-center hover:bg-[#005D6E] hover:text-white focus:outline-none mt-4'>
              Read More
            </button>
          </div>
        </div>
      </Link>
    </div>
  ))}
</div>

 </div>
          </div>
        </div>
      </section>
         
          </section>
        </div>
      </section>
     


      <section>
        <section>
          <div className="relative w-full">
            <div className="relative  gap-10 md:z-50 z-9 flex flex-col items-center justify-center w-full">
              <div className="mt-5">
                <div className="grid md:grid-cols-4 gap-10 px-10 mb-4">
                  {blogs.map((recommendation, index) => (
                    <Link
                      to={`/blog/${toUrlSlug(recommendation.bloglink)}`}
                      key={index}
                    >
                      {/* <RecommendationItem
                        image={recommendation.image}
                        title={capitalizeFirstLetter(recommendation.title)}
                        action={"Read More"}
                        subTitle={recommendation?.blogText}
                      /> */}
                      
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <Footer />
    </div>
  );
};

export default BlogsPage;