import React, { ReactNode } from 'react'

const SocialIcon = ({ children }: { children: ReactNode }) => {
  return (
    <div className='rounded-full bg-white text-[#75A7AD] p-3 w-fit'>
        { children }
    </div>
  )
}

export default SocialIcon;