import React, { ReactNode, useEffect, useRef } from 'react';

interface ModalProps {
  isOpen: boolean;
  modalId: string;
  closeModal: () => void;
  children:ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, modalId, closeModal, children }) => {
  const modalRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    if (modalRef.current) {
      if (isOpen) {
        modalRef.current.showModal();
      } else {
        modalRef.current.close();
      }
    }
  }, [isOpen]);

  const handleClose = () => {
    closeModal();
  };

  return (
    <>
      <dialog id={modalId} className="modal" ref={modalRef}>
        <div className="modal-box h-full">
          {children}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={handleClose}>Close</button>
        </form>
      </dialog>
    </>
  );
};

export default Modal;
