import axios, { AxiosResponse } from 'axios';


export interface Blog {
  id: number;
  meta_title:string;
  meta_description:string;
  title: string;
  image: string;
  content: string;
  bloglink:string;
}

// Class for handling blog-related operations
class BlogService {
  // Base URL for the blogs API
  private BASE_URL = "https://sodastreamapi.azurewebsites.net/blogs";
  
  // Method to fetch all blogs
  async getAllBlogs(): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<any> = await axios.get(this.BASE_URL);
      if (response.status >= 200 && response.status < 300) {
        const blogs: Blog[] = response.data;
        console.log('Blogs:', blogs);
        return { data: blogs, error: null };
      } else {
        const error = 'Error fetching blogs';
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error('Error fetching blogs:', error);
      return { data: null, error };
    }
  }

  // Method to fetch a blog by ID
  async getBlogById(blogId: any): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<Blog> = await axios.get(`${this.BASE_URL}/id/${blogId}`);
      if (response.status >= 200 && response.status < 300) {
        const blog: Blog = response.data;
        console.log('Blog:', blog);
        return { data: blog, error: null };
      } else {
        const error = 'Error fetching blog by ID';
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error('Error fetching blog by ID:', error);
      return { data: null, error };
    }
  }

  async getBlogByLink(bloglink: string): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<Blog> = await axios.get(`${this.BASE_URL}/${bloglink}`);
      if (response.status >= 200 && response.status < 300) {
        const blog: Blog = response.data;
        console.log('Blog:', blog);
        return { data: blog, error: null };
      } else {
       
        const error = 'Error fetching blog by link';
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error('Error fetching blog by link:', error);
      return { data: null, error: error.message };
    }
  }


  // Method to create a new blog
  async createBlog(blogData: any): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<Blog> = await axios.post(this.BASE_URL, blogData);
      if (response.status >= 200 && response.status < 300) {
        const createdBlog: Blog = response.data;
        console.log('Created Blog:', createdBlog);
        return { data: createdBlog, error: null };
      } else {
        const error = `Error creating blog - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error('Error creating blog:', error.message);
      // Log additional information if available
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }

  // Method to edit an existing blog's data
  async editBlog(blogId: any, updatedBlogData: any): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<Blog> = await axios.put(`${this.BASE_URL}/id/${blogId}`, updatedBlogData);
      if (response.status >= 200 && response.status < 300) {
        const updatedBlog: Blog = response.data;
        console.log('Updated Blog:', updatedBlog);
        return { data: updatedBlog, error: null };
      } else {
        const error = `Error updating blog - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error('Error updating blog:', error.message);
      // Log additional information if available
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }

  // Method to delete a blog by ID
  async deleteBlog(blogId: number): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<any> = await axios.delete(`${this.BASE_URL}/id/${blogId}`);

      if (response.status >= 200 && response.status < 300) {
        console.log('Blog deleted successfully:', response.data);
        return { data: response.data, error: null };
      } else {
        const error = `Error deleting blog - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error('Error deleting blog:', error.message);
      // Log additional information if available
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }
}

// Creating an instance of the BlogService class
const blogServiceInstance = new BlogService();

// Exporting the instance for use in other parts of the application
export default blogServiceInstance;
