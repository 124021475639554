import { Link } from 'react-router-dom';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { useLocation } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { BlogState } from '../../../@types/productInfo';

const BlogPreview = () => {
  const location = useLocation();
  const blogData = location.state as BlogState;

  if (!blogData) {
    return <div>No data available for preview</div>;
  }

  const { title, blogText, link, image } = blogData;

  // Assainir le texte HTML
  const sanitizedHtml = sanitizeHtml(blogText, {
    allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'p', 'br', 'ul', 'li', 'ol' ], // Personnalisez les balises autorisées
    allowedAttributes: {
      a: [ 'href' ]
    }
  });

  return (
    <div>
      <Header
        title={title}
        subTitle=''
        textBackground='BLOG'
        backgroundImg={image}
      />
      <div className='relative min-h-[auto] w-full'>
        <div className='relative md:top-[-20rem] top-0 gap-10 md:z-50 z-9 flex flex-col items-center justify-center w-full'>
          <div className='mt-5 lg:px-60 md:px-40 sm:px-20 px-5 w-full'>
            <div className='bg-white rounded-xl border border-gray-300 
                        relative w-full opacity-80 px-10 py-20 text-[#005D6E]'>
              <div className='flex flex-col gap-4 text-start'>
                {/* Utilisation de dangerouslySetInnerHTML pour injecter le HTML assaini */}
                <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPreview;