import React,{useState} from 'react';
import SecondaryButton from '../Button/SecondaryButton';
import { Images } from '../../assets/assets';
import { Link } from 'react-router-dom';
import SocialLinks from '../SocialLinks/SocialLinks';
import { ISection } from '../../@types/productInfo';
import Modal from '../../components/Modal/Modal';


const Footer = () => {
    type IOpenSections = { [key: number]: boolean };
    
    const [openSections, setOpenSections] = useState<IOpenSections>({});
    const [showModal, setShowModal] = useState<boolean>(false);
  
    const toggleSection = (index: number) => {
      setOpenSections((prevState) => ({
        ...prevState,
        [index]: !Boolean(prevState[index]),
      }));
    };

    const openModal = () => {
        setShowModal(true)
    }

    const clsoeModal = () => {
        setShowModal(false)
    }
  
    const sections:ISection[] = [
        {
            title: "Why should I purchase Art?",
            content: "The ART is our new premium Sparkling Water Maker and is all about your unlimited personal creation. With just a few pushes of the lever, you can craft your own homemade sparkly drinks, turning H2O into H2Wow. You can purchase the ART online or at a store near you, and enjoy bubbly masterpieces every day."
        },
        {
            title: "What is the difference between the pink-handle systems, like in the ART, and the other existing Sparkling Water Maker systems?",
            content: "We made changes to our cylinder system to ensure our consumers get the most comfortable experience. The new Quick Connect Cylinder was designed with an advanced mechanism for simple insertion in one click, thus making the cylinder inserting process faster and more user-friendly for the consumer."
        },
        {
            title: "Do you have a downloadable instruction manual available?",
            content: (
                <>You can download the {" "}
                  <a 
                    href='https://drive.google.com/file/d/1ZjOc_DD4ZwFRBVZMZJrzuQ5gc43-05nn/view' 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="underline"
                  >
                    manual here
                  </a>
                </>
            ),
        },
        {
            title: "What are the differences between ART and the other Sparkling Water Makers?",
            content: "The ART Sparkling Water Maker offers a premium experience with enhanced features, such as the Quick Connect Cylinder and a more modern design. It's aimed at users who are looking for a premium Sparkling Water Maker.",
            image: <><img className='mt-5' src={Images.ManualImg } alt='manual' /></>
        },
        {
            title: "Do SodaStream products come with a warranty?",
            content: <>
                Yes, all SodaStream Sparkling Water Makers come with at least a 2-year limited warranty, valid from the purchase date. If your product malfunctions within the warranty period, we will send you a replacement of new or comparable value, free of charge.<br/><br/>
                For purchases in retail or from other online vendors, please check your Sparkling Water Maker User Manual for more warranty information.<br /><br/>
                *Warranty Limitations: This warranty is given on the condition that the machine has been correctly used strictly in accordance with SodaStream instructions as detailed in your Sparkling Water Maker User Manual.
            </>
        }
    ];


const FAQSection = ({ sections,  toggleSection, openSections}: { sections: ISection[], toggleSection: (index: number) => void, openSections: IOpenSections}) => (
    <div className='flex flex-col gap-5'>
        {sections.map((section: ISection, index: number) => (
            <div className="collapse text-start p-0 mt-5" key={index}>
                <input type="checkbox" onClick={() => toggleSection(index)} /> 
                <div className="collapse-title text-xl font-medium p-0">
                    <div className="flex justify-between items-start">
                        <h2 className="font-bold text-[18px] text-[#75A7AD]">{section.title}</h2>
                        <button 
                            className="bg-[#75A7AD] px-2 flex items-center justify-center rounded-full text-white w-8 h-8" 
                        >
                            {openSections[index] ? "-" : "+"}
                        </button>
                    </div>
                </div>
                <div className="collapse-content p-0">
                    <p>{section.content}</p>
                    {section?.image}
                </div>
                {/* {
                    section.isImage ? <div className="collapse-content p-0"><img src={section.content} alt={section.title} style={{ width: "50%", margin: "0 auto"}} /></div> : (

                    )
                } */}
            </div>
        ))}
    </div>
)
  return (
    <div className='bg-[#80A6AC] mt-0 md:px-20 px-10 py-10'>
        <div className='flex gap-20 flex-wrap'>
            <div>
                <div className='flex flex-col gap-6 text-start border-white border p-5'>
                    <p className='text-white font-medium text-sm'>Register to hear about our latest offers and promotions</p>
                    {/* <div className='flex gap-2 w-full'>
                        <input className='rounded-full flex-grow pl-10 font-bold py-4' placeholder='Email Address' />
                    </div> */}
                    <div className='flex gap-3 items-end md:flex-row flex-col'>
                        <div className='flex-grow'>
                            <p className='text-white font-light text-sm'>You can withdraw your consent and unsubscribe at any time</p>
                        </div>
                    </div>
                    <Link target="_blank" to="https://comms21.everlytic.net/public/forms/h/v5nSbOO6Ky4EEZag/Yjk3MWNjM2EyYWVkOTNhZjBjZmEyYzcwNTUxNTRmOTY1MjE1Y2Y3ZQ==" className='basis-[100%] w-full'>
                        <SecondaryButton isFull={true} label='SIGN ME UP'/>
                    </Link>
                    <Link to='/terms'><p className='text-white font-light text-sm'>VIEW TERMS AND PRIVACY</p></Link> 
                </div>
                <div className='hidden md:flex text-start flex-col gap-10 mt-10'>
                    <img src={Images.LogoLight} alt='logo' width={"60%"} height="100%" />
                    <p className='text-white font-medium text-sm'>© 2023 SodaStream SA Pty Ltd All Rights Reserved.</p>
                    <SocialLinks />
                </div>
            </div>
            {/* <FAQSection sections={sections} toggleSection={toggleSection} openSections={openSections} /> */}

            {/*  */}
            <div className='flex-grow text-start'>
                <div className='flex gap-10 justify-between flex-wrap lg:flex-nowrap'>
                    <div className='flex flex-col gap-2'>
                        <h4 className='text-white font-bold'>Products</h4>
                        <Link className='text-white' to={"/water-makers"}>Sparkling Water Makers</Link>
                        <Link className='text-white' to={"/flavours"}>Flavours</Link>
                        <Link className='text-white' to={"/accessories"}>Accessories</Link>
                        {/* <Link className='text-white' to={"/#"}>Gifts</Link> */}
                        {/* <Link className='text-white' to={"/"}>Get a Refill</Link> */}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <h4 className='text-white font-bold'>Information</h4>
                        <Link className='text-white' to={"/support"}>Support</Link>
                        <Link className='text-white' to={"/register-machine"}>Register your machine</Link>
                        <Link className='text-white' to={"/blog"}>Blogs</Link>
                        <Link className='text-white' to={"/terms-and-conditions"}>Legal Terms</Link>
                        <Link className='text-white' to={"/competition-terms"}>Competition Terms</Link>
                        {/* <Link className='text-white' to={"/#"}>Do not sell my information</Link> */}
                        {/* <Link className='text-white' to={"/#"}>Sodastream CO2 Support</Link> */}
                        <Link className='text-white' to={"/mixology"}>SodaStream Recipes</Link>
                        {/* <Link className='text-white' to={"/blog"}>Blog</Link> */}
                        {/* <Link className='text-white' to={"/#"}>Careers</Link> */}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <h4 className='text-white font-bold'>More</h4>
                        <Link className='text-white' to={"/store-locator"}>Find a Store Near You</Link>
                        <div className='flex gap-4 text-white items-center justify-start'>
                            <span>🇿🇦</span>
                            <p>South Africa</p>
                        </div>
                    </div>
                    {/* Repeat for other columns */}
                    <div className='flex md:hidden text-start flex-col gap-10 mt-10'>
                        <img src={Images.LogoLight} alt='logo' width={"60%"} height="100%" />
                        <p className='text-white font-medium text-sm'>© 2023 SodaStream SA Pty Ltd All Rights Reserved.</p>
                        <SocialLinks />
                    </div>
                </div>
            </div>
        </div>
        <Modal isOpen={showModal} modalId={''} closeModal={clsoeModal}>
                <div className='text-center h-full md:px-5 md:py-5'>
                {/* <iframe title='Sign Up' src="https://comms21.everlytic.net/public/forms/h/v5nSbOO6Ky4EEZag/Yjk3MWNjM2EyYWVkOTNhZjBjZmEyYzcwNTUxNTRmOTY1MjE1Y2Y3ZQ=="
                style={{width: "100%", height: '100%', border: 0, overflowX:'auto', overflowY: 'auto'}}
                ></iframe> */}
                </div>
            </Modal>
    </div>
  );
}

export default Footer;
