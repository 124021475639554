import React from 'react'
import Header from '../../components/Header/Header';
import { Icons, Images } from '../../assets/assets';
import Footer from '../../components/Footer/Footer';
import { BsImages, BsPlayCircleFill } from 'react-icons/bs';
import { Tb360View } from 'react-icons/tb';
import RecommendationItem from '../../components/RecommendationItem/RecommendationItem';
import ProductInfoPanel from '../../components/ProductInfoPanel/ProductInfoPanel';
import { IComment, ISection } from '../../@types/productInfo';
import PlainVideo from '../../components/VideoPreview/PlainVideo';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { RoundedCircleComponent } from '../SparklingWaterMaker/SparklingWaterMaker';
import { DefaultCarousel } from '../../components/ImgCarousel/Carousel';
import Navbar from '../../components/Navbar/Navbar';
import PrimaryButton from '../../components/Button/PrimaryButton';

import SecondaryButton from '../../components/Button/SecondaryButton';

import { Link, useParams } from 'react-router-dom';

const recommendations = [
    { image: Images.ArtStep1, title: 'Quick Connect' },
    { image: Images.ArtStep2, title: 'Click' },
    { image: Images.ArtStep3, title: 'Fizz' },
    { image: Images.ArtStep4, title: 'Enjoy' },
];

const whiteImages = [Images.TerraWhiteFront, Images.TerraWhiteLeft, Images.TerraWhiteRight, Images.TerraWhiteFront]
const blackImages = [Images.TerraBlackFront, Images.TerraBlackLeft, Images.TerraBlackRight, Images.TerraBlackFront]

const sections: ISection[] = [
    {
        title: "Why should I purchase Terra?",
        content: "We’re glad you asked! The TERRA Sparkling Water Maker is an extremely convenient Sparkling Water Maker. The new CQC patent-pending technology makes your cylinder connection easy and intuitive, and the updated modern design along with a bigger carbonation button make bubbling quick and easy. The TERRA also comes with an easy-to-clean, BPA free carbonated bottle, dishwasher-safe carbonating bottle."
    },
    {
        title: "What is the difference between the pink-handle systems, like in the TERRA, and the other existing Sparkling Water Maker systems?",
        content: "We made changes to our cylinder system to ensure our consumers get the most comfortable experience. The new Quick Connect Cylinder was designed with an advanced mechanism for simple insertion in one click, thus making the cylinder inserting process faster and more user-friendly for the consumer."
    },
    {
        title: "Do you have a downloadable instruction manual available?",
        content: (
            <>You can download the {" "}
                <a
                    href='https://drive.google.com/file/d/1uKGHJTjNDr7t4l_gDgzjQuRKVI9_tpzo/view?usp=sharing'
                    target="_blank"
                    rel="noopener noreferrer"
                   
                    className="underline"
                >
                    manual here
                </a>
            </>
        ),
    },
    {
        title: "What are the differences between TERRA and the other Sparkling Water Makers?",
        content: "TERRA is an extremely convenient Sparkling Water Maker. The new quick connect patent protected technology makes your cylinder connection easy and intuitive, and the updated modern design along with a bigger carbonation button make bubbling quick and easy. The TERRA also comes with an easy-to-clean, dishwasher-safe carbonating bottle.",
        image: <><img className='mt-5' src={Images.ManualImg} alt='manual' /></>,
    },
    {
        title: "Do SodaStream products come with a warranty?",
        content: <>
            Sure do! All SodaStream Sparkling Water Makers have at least a 2-year limited warranty, valid from the purchase date*. If your product malfunctions within the warranty period, and we determine the product is due to faulty workmanship or materials, SodaStream will cover the return shipping and send you a replacement of new or comparable value, free of charge. <br /><br />
            *Warranty Limitations: This warranty is given on the condition that the machine has been correctly used strictly in accordance with SodaStream instructions as detailed in your Sparkling Water Maker User Manual.
        </>
    }
];

const comments: IComment[] = [
    {
        name: "Karen-Anne V. [Yuppiechef]",
        isVerified: true,
        rating: 5,
        date: "2022-04-18",
        message: `Love the retro look; looks and works amazingly. From a light sparkle to a more gassy drink, it's all in one machine. I am definitely going to try some flavours as well.`,
        numLikes: 0,
        numDislikes: 0
    },
];

const whyTerraItems = [
    'The best way to make fresh sparkling water and more user-friendly than ever',
    'Dishwasher-safe, BPA-free, reusable bottles',
    'Compact and sleek with a wider carbonating button',
    'NEW Quick Connect CO2 technology for fast "one click" cylinder insertion'
];


const breadcrumbItems = [
    { text: 'HOME', href: '/' },
    { text: 'SPARKLING WATER MAKERS', href: '/water-makers' },
    { text: 'TERRA' },
];

const TerraWaterMakerPage = () => {
    const [color, setColor] = React.useState("white")
    const [selectedView, setSelectedView] = React.useState("images")

    return (
        <div>
            {/* <Header isHalf textBackground='WATTER MAKERS' title='Sparkling Water Makers' backgroundImg={Images.WaterMakerProductBg} /> */}
            <Navbar />
            <section>
                <div className='bg-[#FFFFFF] text-start  md:px-20 px-10 py-10 md:py-0'>
                    <Breadcrumb items={breadcrumbItems} />
                </div>
                <div className='flex flex-col md:flex-row md:gap-0 gap-5'>
                    <div className='basis-[50%] md:px-20 px-10 text-start'>
                        <div className='flex gap-4 items-center'>
                            <div className='flex flex-col gap-4'>
                                <div onClick={() => setSelectedView("images")} className='p-4 rounded-full bg-[#75A7AD]' style={{ boxShadow: "0px 3px 6px #00000029", backgroundColor: selectedView === 'images' ? "#75A7AD" : "#FFFFFF" }}>
                                    <BsImages fontSize={25} color={selectedView === 'images' ? 'white' : 'black'} />
                                </div>

                                {/* 
                                    <div className='p-4 rounded-full bg-[#FFFFFF]' style={{ boxShadow: "0px 3px 6px #00000029"}}>
                                    <Tb360View fontSize={25} color='black' />
                                </div> 
                                */}

                                <div onClick={() => setSelectedView("video")} className='p-4 rounded-full bg-[#FFFFFF]' style={{ boxShadow: "0px 3px 6px #00000029", backgroundColor: selectedView === 'video' ? "#75A7AD" : "#FFFFFF" }}>
                                    <BsPlayCircleFill fontSize={25} color={selectedView === 'video' ? 'white' : 'black'} />
                                </div>
                            </div>
                            <div className='w-full'>
                                {selectedView === 'images' ?
                                    <DefaultCarousel images={color === 'black' ? blackImages : whiteImages} />
                                    :
                                    <PlainVideo height='220' video={"https://res.cloudinary.com/brabenjos/video/upload/v1698156508/sodastream/images/TerraVideo.mp4"} bg={Images.WatterMakerSectionBg} />
                                }
                                {/* <p className='text-[#005D6E] text-extrabold text-center'>1 out of 6</p> */}
                            </div>
                        </div>
                    </div>


                    <div className='basis-[50%] text-start md:px-20 px-10 flex flex-col gap-4 text-[#005D6E]'>
                        <div className='flex flex-col gap-4'>
                            <p className='font-semibold'>Select your colour:</p>
                            <div className='flex gap-4 justify-start'>
                                <div onClick={(e) => { setColor("white") }}><RoundedCircleComponent bgColor='white' /></div>
                                <div onClick={(e) => { setColor("black") }}><RoundedCircleComponent bgColor='black' /></div>
                            </div>
                        </div>
                        <h2 className='text-[30px] font-bold'>TERRA</h2>
                        <span>⭐️⭐️⭐️⭐️⭐️</span>
                        <p className='text-[12px] font-light'>Presenting the Terra, SodaStream's best-selling Sparkling Water Maker. Embrace sustainability and experience the future of sparkling water with SodaStream Terra. Bubble up your normal water into fresh sparkling water with just a push of a button. Designed for the modern kitchen, it features new Quick Connect technology for fast and easy CO2 cylinder insertion. Say goodbye to single-use plastic bottles and hello to a greener way of enjoying sparkling water right from the comfort of your home.</p>
                        {/* <p className='text-[12px] font-light'>The Terra features a retro design along with a unique carbonating lever.</p> */}
                        <div className='flex gap-2 justify-start'>
                            <div className='flex flex-col gap-2 text-center items-center basis-[20%]'>
                                <img src={Icons.IconBottle} alt='bottle' style={{ width: "35%" }} />
                                <span className='text-[10px]'>Bottle Snap & Lock unique technology</span>
                            </div>
                            <div className='flex flex-col gap-2 text-center items-center basis-[20%]'>
                                <img src={Icons.IconCordless} alt='bottle' style={{ width: "35%" }} />
                                <span className='text-[10px]'>Cordless</span>
                            </div>
                            <div className='flex flex-col gap-2 text-center items-center basis-[20%]'>
                                <img src={Icons.Icondishwasher} alt='bottle' style={{ width: "35%" }} />
                                <span className='text-[10px]'>Dishwasher safe bottle</span>
                            </div>
                            <div className='flex flex-col gap-2 text-center items-center basis-[20%]'>
                                <img src={Icons.IconEgronomicButton} alt='bottle' style={{ width: "35%" }} />
                                <span className='text-[10px]'>Ergonomic carbonation lever</span>
                            </div>
                            <div className='flex flex-col gap-2 text-center items-center basis-[20%]'>
                                <img src={Icons.IconPatentedButton} alt='bottle' style={{ width: "35%" }} />
                                <span className='text-[10px]'>Patented Quick Connect system</span>
                            </div>
                        </div>

                        <div className='flex gap-2 justify-center border-y border-gray-300 py-5 mt-2'>
                            <span></span>
                            <span className='font-semibold text-[#005D6E] text-[12px]'>1L Reusable Plastic Bottle</span>
                        </div>

                        <div className="mt-2 ">
                            <Link to='/store-locator'><SecondaryButton label='Store Locator' /></Link>
                        </div>

                        <div>
                            <p className='font-semibold'>Included in box</p>

                            <div className='rounded-2xl mt-5 overflow-hidden' style={{ boxShadow: "0px 7px 20px #0000001A" }}>
                                <div className='flex border'>
                                    {/* <div className='basis-[50%] px-10 py-5 border-b border-gray-200'>
                                    <h2 className='text-[24px] font-bold'>Hydration Pack</h2>
                                    <span>Rxxxx.xx Rxxxx.xx</span>
                                </div> */}
                                    <div className='basis-[100%] bg-[#EEEAE5] px-10 py-5'>
                                        <h2 className='text-[24px] font-bold'>Starter Kit</h2>
                                        {/* <span>Rxxxx.xx Rxxxx.xx</span> */}
                                    </div>
                                </div>

                                <div className='grid md:grid-cols-2 grid-cols-1 gap-4 text-[12px] px-10 py-4'>
                                    <div className='flex gap-2 items-center justify-start'>
                                        <span className='bg-[#005D6E] text-white px-2 py-1 text-[10px] rounded-full flex items-center justify-center'>
                                            1X
                                        </span>
                                        <span>Terra Sparkling Water Maker</span>
                                    </div>
                                    <div className='flex gap-2 items-center justify-start'>
                                        <span className='bg-[#005D6E] text-white px-2 py-1 text-[10px] rounded-full flex items-center justify-center'>
                                            1X
                                        </span>
                                        <span>Quick Connect CO2 cylinder</span>
                                    </div>
                                    <div className='flex gap-2 items-center justify-start'>
                                        <span className='bg-[#005D6E] text-white px-2 py-1 text-[10px] rounded-full flex items-center justify-center'>
                                            1X
                                        </span>
                                        <span>1L PET bottles</span>
                                    </div>
                                    {/* <div className='flex gap-2 items-center justify-start'>
                                        <span className='bg-[#005D6E] text-white px-2 py-1 text-[10px] rounded-full flex items-center justify-center'>
                                            1X
                                        </span>
                                        <span>0.5L Dishwasher Safe plastic bottles</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            {/* <SecondaryButton label='Find it in-store' /> */}
                        </div>
                        <div className='my-5'>
                            <div className='flex items-center justify-center flex-col gap-2'>
                                <img src={Icons.IconCertification} alt="delivrary" />
                                <span className='text-center w-32'>2 year warranty</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Recommendations stuff */}
                <div className='md:px-20 px-10 py-10 flex gap-4 md:flex-row flex-col'>
                    {recommendations.map((recommendation, index) => (
                        <RecommendationItem key={index} image={recommendation.image} title={recommendation.title} />
                    ))}
                </div>

                <div className='md:px-20 px-10 pb-10 pt-5 flex gap-4 md:gap-20 items-center justify-center'>
                    <div className='flex flex-col gap-2 items-center'>
                        <img src={Icons.IconFreshWater} alt="bg" style={{ width: "70%" }} />
                        <span className='text-[14px] text-[#005D6E] font-bold'>Stay hydrated</span>
                    </div>
                    <div className='flex flex-col gap-2 items-center'>
                        <img src={Icons.IconHeathier} alt="bg" style={{ width: "70%" }} />
                        <span className='text-[14px] text-[#005D6E] font-bold'>Limitless taste</span>
                    </div>
                    <div className='flex flex-col gap-2 items-center'>
                        <img src={Icons.IconSustainability} alt="bg" style={{ width: "70%" }} />
                        <span className='text-[14px] text-[#005D6E] font-bold'>Save the planet</span>
                    </div>
                </div>
            </section>
            {/* <VideoPreview video={"https://drive.google.com/file/d/1Pl7k5cyZ8orY32KQcz8e-OeGVe6dQhn0/preview"} bg={Images.WatterMakerSectionBg} /> */}
            <ProductInfoPanel sections={sections} comments={comments} specifications={{ title: 'Why TERRA?', items: whyTerraItems }} />
            <Footer />
        </div>
    )
}

export default TerraWaterMakerPage;