import React, { useEffect, useRef, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { ImageUploadUtil, ProductService } from "../../../APIs/index";
import {
  DeleteButton,
  IsLoadingButton,
  ModalDeleteCancel,
  ModalDeleteConfirm,
  ModalDeleteHeader,
  ModalDeleteTitle,
  SubmitButton,
} from "../../Loading/ButtonLoading";
import { ToastDanger, ToastSuccess, ToastWarning } from "../../Loading/Tost";
import { Product, WaterMachine } from "../../../@types/productInfo";
import waterMachineServiceInstance from "../../../APIs/watermachines";
import WaterMachineForm from "../watermachineform/WaterMachineForm";
import ModalForm from "../ModalForm/ModalForm";
import { FormNav } from "../../FormNav/FormNav";
import ModalButton from "../../Button/ModalButton";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const Watermachines: React.FC = () => {
  // State for handling machine-related data
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [meta_title, setMetaTitle] = useState<string>("");
  const [meta_description, setMetaDescription] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [images_black, setimages_black] = useState<string[]>([]);
  const [images_white, setimages_white] = useState<string[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState("all");
  const [selectedMachine, setSelectedMachine] = useState<WaterMachine | null>(
    null
  );
  const [machinelink, setMachineLink] = useState("")
  const [watermachines, setWatermachines] = useState<WaterMachine[]>([]);
  const [isSuccessMessageVisible, setSuccessMessageVisible] = useState(false);
  const [isSuccessProduct, setSuccessProduct] = useState(false);
  const [isEditSuccess, setEditSuccess] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMachines, setFilteredMachines] = useState<WaterMachine[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const [currentPage, setCurrentPage] = useState(1);
  const machinePerPage = 8;
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);

  const handleButtonClickk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const closeModalEdit = () => {
    setShowModalEdit(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [formData, setFormData] = useState<WaterMachine>({
    name: "",
    description: "",
    meta_title:"",
  meta_description:"",
    images_black: [],
    images_white: [],
    video: "",
    machinelink
  });

  const handleSearchTermChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const filterProductsFromSearch = (users: any[]) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const tempMachine: any[] = users.filter((user: any) => {
      const machineProperties = Object.values(user).map((value) =>
        String(value).toLowerCase()
      );
      return (
        (selectedRole === "all" || user.role === selectedRole) &&
        machineProperties.some((property) =>
          property.includes(lowerCaseSearchTerm)
        )
      );
    });

    console.log("temp: ", tempMachine, lowerCaseSearchTerm);
    setFilteredMachines(tempMachine);
    setCurrentPage(1);

  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };



  useEffect(() => {
    if (searchTerm.length > 0) {
      filterProductsFromSearch(watermachines);
    } else {
      setFilteredMachines(watermachines);
    }
  }, [searchTerm, watermachines]);

  const totalPages = Math.ceil(filteredMachines.length / machinePerPage);
  const indexOfLastMachine = currentPage * machinePerPage;
  const indexOfFirstMachine = indexOfLastMachine - machinePerPage;
  const currentMachines = filteredMachines.slice(
    indexOfFirstMachine,
    indexOfLastMachine
  );

  // Create an array of page numbers using map
  const generatePageNumbers = () => {
    const numbers = [];
    for (let i = 1; i <= totalPages; i++) {
      numbers.push(i);
      console.log("TotalPage", totalPages);
      console.log("ff", totalPages);
    }
    setPageNumbers(numbers);
  };

  useEffect(() => {
    generatePageNumbers();
  }, [totalPages]);

  // Method to create a new machine
  const handleFormSubmit = async (data: any) => {
    setIsLoading(true);
    // Assuming WaterMachine interface is in scope

    const machineData: WaterMachine = {
      name: data.name,
      meta_title: data.meta_title,
      meta_description: data.meta_description,
      description: data.description,
      images_white: data.images_white,
      images_black: data.images_black,
      video: data.video,
      machinelink: data.machinelink
    };

    try {
      const { data, error } =
        await waterMachineServiceInstance.createWaterMachine(machineData);

      if (data) {
        console.log("Machine created successfully:", data);
        fetchMachineData();
        setSuccessProduct(true);
        setShowModal(false);
        setSelectedMachine(null);
        setTimeout(() => {
          setSuccessProduct(false);
        }, 3000);
        setName("");
        setDescription("");
      } else {
        console.error("Error creating machine:", error);
      }
    } catch (error) {
      console.error("Error creating machine:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //fill the edit form
  const handleEditProduct = (machine: WaterMachine) => {
    console.log("Selected Machine:", machine);
    setSelectedMachine(machine);

    setName(machine.name || "");
    setDescription(machine.description || "");
    setMetaTitle(machine.meta_title)
    setMetaDescription(machine.meta_description)
    setVideo(machine.video || "");
    setimages_black(machine.images_black || "");
    setimages_white(machine.images_white || "");
    setMachineLink(machine.machinelink)
    setShowModalEdit(true);
  };

  //edit machine
  const handleSaveEdit = async (formData: WaterMachine) => {
    setIsLoadingUpdate(true);
    try {
      const machineId = selectedMachine?.id;
    
      if (machineId) {
        const updatedMachineData: WaterMachine = {
          name: formData.name,
          description: formData.description,
          meta_title:formData.meta_title,
          meta_description: formData.meta_description,
          images_white: formData.images_white,
          images_black: formData.images_black,
          video: formData.video,
          machinelink: formData.machinelink
        };

        const { data, error } =
          await waterMachineServiceInstance.editWaterMachine(
            machineId,
            updatedMachineData
          );

        console.log("API Response:", data, error);

        if (data) {
          fetchMachineData();
          setShowModalEdit(false);
          setEditSuccess(true);
          setSelectedMachine(null);
          setTimeout(() => {
            setEditSuccess(false);
          }, 3000);
        } else {
          console.error("Error updating machine:", error);
        }
      } else {
        console.error("Selected machine or machine id is undefined");
      }
    } catch (error) {
      console.error("Error uploading images or updating machine:", error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  //create the method to fetch all machine
  const fetchMachineData = async () => {
    try {
      const { data, error } =
        await waterMachineServiceInstance.getAllWaterMachines();

      if (data) {
        setWatermachines(data);
        setFilteredMachines(data);
      } else {
        console.error("Error fetching machines:", error);
      }
    } catch (error) {
      console.error("Error fetching machines:", error);
    }
  };

  //call the method
  useEffect(() => {
    fetchMachineData();
  }, []);

  //delete product
  const handleDeleteMachine = async (machineId: number) => {
    if (machineId) {
      try {
        setIsLoadingDelete(true);
        const { data, error } =
          await waterMachineServiceInstance.deleteWaterMachine(machineId);

        if (data) {
          setWatermachines((prevMachine) =>
            prevMachine.filter((machine) => machine.id !== machineId)
          );
          console.log("Water Machine deleted successfully:", data);
          setSuccessMessageVisible(true);
          fetchMachineData();
          setShowModalEdit(false);
          setIsModalVisible(false);
          setSelectedMachine(null);
          setTimeout(() => {
            setSuccessMessageVisible(false);
          }, 3000);
        } else {
          console.error("Error deleting water machine:", error);
        }
      } catch (error) {
        console.error("Error deleting water machine:", error);
      } finally {
        setIsLoadingDelete(false);
      }
    }
  };

  //component
  return (
    <div className="p-4 rounded-md w-5/4 mx-auto">
      {isSuccessMessageVisible && (
        <ToastSuccess label="Water Machine successfully deleted!" />
      )}
      {isEditSuccess && (
        <ToastSuccess label="Water Machine successfully updated!" />
      )}
      {isSuccessProduct && (
        <ToastSuccess label="Water Machine successfully created!" />
      )}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-black">Water Machine</h2>

        <div>
          {showModal && (
            <div>
              <ModalForm
                isOpen={showModal}
                modalId={""}
                closeModal={closeModal}
              >
                <FormNav label="Add Water Machine" onClick={closeModal} />

                <div>
                  <WaterMachineForm
                    formData={formData}
                    onSubmit={handleFormSubmit}
                    onClick={closeModal}
                  />
                </div>
              </ModalForm>
            </div>
          )}
        </div>
      </div>

      <div>
        {showModalEdit && selectedMachine && (
          <ModalForm
            isOpen={showModalEdit}
            modalId={""}
            closeModal={closeModalEdit}
          >
            <FormNav label="Edit Water Machine" onClick={closeModalEdit} />
            <div>
              <div className=" ">
                <WaterMachineForm
                  isUpdate={true}
                  isUpdateDelete
                  formData={{
                    name,
                    description,
                    meta_title,
                    meta_description,
                    images_white,
                    images_black,
                    video,
                    machinelink
                  }}
                  onSubmit={handleSaveEdit}
                  onClick={handleButtonClickk}
                />

                {isModalVisible && (
                 <div
                 className="
              backdrop-blur-sm h-[230%] top-0 fixed
               left-0 w-full bg-gray-800 opacity-30 z-50"
               ></div>
                )}

                {isModalVisible && (
                  <div
                    id="popup-modal"
                    className="overflow-y-auto grid overflow-x-hidden
                   mt-[100%] fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                  >
                    <div className="relative p-4 w-full text-black max-w-md max-h-full">
                      <div
                        className="relative bg-white rounded-lg text-black shadow dark:bg-gray-700"
                        style={{ zIndex: 999 }}
                      >
                        <ModalDeleteHeader
                          onClick={handleModalClose}
                          label="close Modal"
                        />

                        <div className="p-4 md:p-5 text-center">
                          <ModalDeleteTitle label=" Are you sure you want to delete this water machine ?" />
                          {!isLoadingDelete && (
                            <div>
                              <ModalDeleteConfirm
                                label="Confirm"
                                onClick={() =>
                                  selectedMachine?.id &&
                                  handleDeleteMachine(selectedMachine?.id)
                                }
                              />

                              <ModalDeleteCancel
                                label="Cancel"
                                onClick={handleModalClose}
                              />
                            </div>
                          )}

                          {isLoadingDelete && (
                            <IsLoadingButton
                              type="submit"
                              label="Deleting Water Machine..."
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ModalForm>
        )}
      </div>
      <div className="bg-white p-4 rounded-md w-5/4 mx-auto">
        <ModalButton
          title="All Water Machines"
          label="Add Water Machine"
          onClick={openModal}
        />
        <div className="flex items-center mb-4">
          <input
            className="border rounded-[0.50rem] border-gray-400 py-1 px-2 w-1/4 mr-4 bg-white"
            placeholder="Search Water Machine"
            onChange={handleSearchTermChange}
            value={searchTerm}
          />
          <div className="flex-grow"></div>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className=" min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <div>
                  <table className="w-full text-xs">
                    <thead>
                      <tr>
                        <th className="border-b p-2 bg-white text-left">
                          Water Machine Name
                        </th>
                        <th className="border-b p-2 bg-white text-left">
                          Description
                        </th>

                        <th className="border-b p-2 bg-white text-left">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentMachines.map((machine, index) => (
                        <tr key={index}>
                          <td className="border-b p-2 bg-white text-left text-black font-bold">
                            {machine.name}
                          </td>
                          <td className="border-b p-2 bg-white text-left">
                            {machine.description}
                          </td>

                          <td className="border-b p-2 bg-white text-left">
                            <span
                              onClick={() => handleEditProduct(machine)}
                              className="cursor-pointer rounded flex items-center"
                            >
                              <FaEdit />
                              Edit
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {watermachines.length > 7 && (
        <div className="flex space-x-4 mt-[20px] justify-end">
        <section className=" inline-flex col row">
          <div className="flex">
            {currentPage > 1 && (
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="rounded-full h-8 px-1 font-semibol bg-white  ml-[10px]"
              >
                <BiChevronLeft size={30} className="text-black" />
              </button>
            )}

            {pageNumbers.map(
              (number, index) =>
                // Display only the relevant buttons for the current page
                currentPage <= index + 2 &&
                index + 1 <= currentPage + 2 && (
                  <div className="flex space-x-3" key={number}>
                    {<div></div>}
                    <button
                      className="rounded-full h-8 px-3 font-semibol text-white "
                      onClick={() => handlePageChange(number)}
                      style={{
                        backgroundColor:
                          number === currentPage ? "#75A7AD" : "white",
                        color: number === currentPage ? "white" : "black",
                      }}
                    >
                      {number}
                    </button>
                  </div>
                )
            )}

            {currentPage < pageNumbers.length && (
              <button
                className="rounded-full h-8 px-1 font-semibol bg-white  ml-[10px]"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <BiChevronRight size={30} className="text-black " />
              </button>
            )}
          </div>
        </section>
        <section className=" flex space-x-8  p-2">
          <h5 className="font-bold text-black text-xs mr-[-20px]">
            Water machine per page
          </h5>
          <select
            id="role"
            className="mt-[-8px] w-[90px] py-2  block rounded-md focus:outline-none text-black text-sm bg-white"
            name="role"
            value={currentPage}
            onChange={(e) => handlePageChange(Number(e.target.value))}
          >
            {pageNumbers.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </section>
      </div>
    )}
    </div>
  );
};

export default Watermachines;
