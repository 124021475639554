import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Images } from "../../assets/assets";

import { Link } from "react-router-dom";

import { RecipeService } from "../../APIs";
import { Mixologies } from "../../@types/productInfo";
import { Helmet } from "react-helmet";

const MixologyRecommendationItem = ({
  image,
  title,
  // subTitle,
  action,
}: {
  image: string;
  title: string;
  // subTitle?: string;
  action?: string;
}) => (
<div className="gap-4 md:w-[300px] md:h-[300px] w-full space-x-5">
<img 
  src={image} 
  alt={title} 
  className="w-full h-auto rounded-[5%] mt-[0px] md:mt-[50px]" 
/>

  <div className="flex flex-col items-center justify-center gap-2">
   <div >
   <p className="mt-[10px]  text-[18px] font-semibold text-[#002238] 
    cursor-pointer font-normal 
    leading-normal  text-left font-poppins">
      {title}
    </p>
   </div>
  </div>
</div>


);

const MixologiesPage = (props: Mixologies) => {
  //Todo: fix typescript
  const [recipes, setRecipes] = useState<any[]>([]);
  function toUrlSlug(text: string) {
    const decoded = decodeURIComponent(text);
    const hyphenated = decoded.replace(/\s+/g, '-');
    return hyphenated;
  }

  const fetchRecipeData = async () => {
    try {
      const { data, error } = await RecipeService.getAllRecipes();
      if (data) {
        setRecipes(data);

        console.log("Products:", data);
      } else {
        console.error("Error fetching products:", error);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchRecipeData();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Sparkling Water Drink Recipes | SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Elevate your sparkling water game with SodaStream mixology. Explore our recipes and create refreshing drinks!"
          }
        />
        <link rel="canonical" href="https://www.sodastream.co.za/mixology" />
       
      </Helmet>
      <Header
        title="Mixology"
        subTitle="Cocktails, mocktails and more!"
        backgroundImg={Images.RecommendationPageBG}
        textBackground="MIXOLOGY"
      />

<section className="px-5  w-full mt-[-12%] z-[40] h-auto mb-[60px]">
  <div className="relative min-h-[5rem] w-full">
    <div className="relative gap-10 flex flex-col items-center justify-center w-full h-auto">
      <div className="mt-5">
        <div className="grid md:grid-cols-3 grid-cols-2 gap-5 px-2">
     
          {recipes.map((recipe) => (
            <Link to={`/mixology/${toUrlSlug(recipe.recipelink)}`} key={recipe.id}>
              <MixologyRecommendationItem
                image={recipe.image}
                title={recipe.name}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  </div>
</section>

      <Footer />
    </div>
  );
};

export default MixologiesPage;