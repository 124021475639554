import axios, { AxiosResponse } from "axios";

// Base URL for the product service API
const BASE_URL = "https://sodastreamapi.azurewebsites.net/products";

// Interface representing the structure of a product object
export interface Product {
  id: number;
  banner: string;
  name: string;
  description: string;
  meta_description: string;
  meta_title: string;
  caption: string;
  ingredient: string;
  price: number;
  rating: number;
  image: string;
  category: string;
  productlinkname:string;
  productaltbanner:string;
  product_type:string
}

// Class for handling product-related operations
class ProductService {
  // Method to fetch all products
  async getAllProducts(): Promise<{
    data: Product[] | null;
    error: string | null;
  }> {
    try {
      const response: AxiosResponse<Product[]> = await axios.get(BASE_URL);
      if (response.status >= 200 && response.status < 300) {
        const products: Product[] = response.data;
        console.log("Products:", products);
        return { data: products, error: null };
      } else {
        const error = "Error fetching products";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching products:", error);
      return { data: null, error };
    }
  }

  // Method to fetch a product by name
async getProductByName(
  productName: string
): Promise<{ data: Product | null; error: string | null }> {
  try {
    const response: AxiosResponse<Product> = await axios.get(
      `${BASE_URL}/name/${productName}`
    );
    if (response.status >= 200 && response.status < 300) {
      const product: Product = response.data;
      console.log("Product:", product);
      return { data: product, error: null };
    } else {
      const error = "Error fetching product by name";
      console.error(error);
      return { data: null, error };
    }
  } catch (error: any) {
    console.error("Error fetching product by name:", error);
    return { data: null, error };
  }
}

async getProductByLinkName(
  productlinkname: string
): Promise<{ data: Product | null; error: string | null }> {
  try {
    const response: AxiosResponse<Product> = await axios.get(
      `${BASE_URL}/${productlinkname}`
    );
    if (response.status >= 200 && response.status < 300) {
      const product: Product = response.data;
      console.log("Product:", product);
      return { data: product, error: null };
    } else {
      const error = "Error fetching product by productLinkName";
      console.error(error);
      return { data: null, error };
    }
  } catch (error: any) {
    console.error("Error fetching product by productLinkName:", error);
    return { data: null, error };
  }
}

  // Method to fetch a product by ID
  async getProductById(
    productId?: number
  ): Promise<{ data: Product | null; error: string | null }> {
    try {
      const response: AxiosResponse<Product> = await axios.get(
        `${BASE_URL}/id/${productId}`
      );
      if (response.status >= 200 && response.status < 300) {
        const product: Product = response.data;
        console.log("Product:", product);
        return { data: product, error: null };
      } else {
        const error = "Error fetching product by ID";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching product by ID:", error);
      return { data: null, error };
    }
  }

  // Method to create a new product
  async createProduct(
    productData: any
  ): Promise<{ data: Product | null; error: string | null }> {
    try {
      const response: AxiosResponse<Product> = await axios.post(
        BASE_URL,
        productData
      );
      if (response.status >= 200 && response.status < 300) {
        const createdProduct: Product = response.data;
        console.log("Created Product:", createdProduct);
        return { data: createdProduct, error: null };
      } else {
        const error = `Error creating product - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error creating product:", error.message);
      // Log additional information if available
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }

  // Method to edit an existing product's data
  async editProduct(
    productId: number,
    updatedProductData: any
  ): Promise<{ data: Product | null; error: string | null }> {
    try {
      const response: AxiosResponse<Product> = await axios.put(
        `${BASE_URL}/id/${productId}`,
        updatedProductData
      );
      if (response.status >= 200 && response.status < 300) {
        const updatedProduct: Product = response.data;
        console.log("Updated Product:", updatedProduct);
        return { data: updatedProduct, error: null };
      } else {
        const error = `Error updating product - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error updating product:", error.message);
      // Log additional information if available
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }

  // Method to delete a product by ID
  async deleteProduct(
    productId: number
  ): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<any> = await axios.delete(
        `${BASE_URL}/id/${productId}`
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("Product deleted successfully:", response.data);
        return { data: response.data, error: null };
      } else {
        const error = `Error deleting product - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error deleting product:", error.message);
      // Log additional information if available
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }
}

// Creating an instance of the ProductService class
const productServiceInstance = new ProductService();

// Exporting the instance for use in other parts of the application
export default productServiceInstance;
