import React from "react";
import { AiOutlineClose } from "react-icons/ai";
interface Button {
  type: string;
  onClick?: (e: any) => void;

  label?: string;
  status?: string;
}

interface Loading {
  type: string;
  onClick?: (e: any) => void;
  disabled: boolean;
  label?: string;
  status?: string;
}

interface DeleteTitle {
  label?: string;
}

interface DeleteConfirm {
  type?: string;
  label?: string;
  onClick?: (e: any) => void;
}

interface ModalHeader {
  type?: string;
  onClick?: (e: any) => void;
  label?: string;
}

const IsLoadingButton: React.FC<Button> = ({ onClick, label = "Submit" }) => {
  return (
    <button
      data-modal-hide="popup-modal"
      type="button"
      onClick={onClick}
      className="bg-black w-full mt-[7px]
      text-white font-semibold py-2 px-4 border
         border-gray-400 rounded shadow"
    >
      <svg
        aria-hidden="true"
        role="status"
        className="inline w-4 h-4 me-3 text-white animate-spin"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#E5E7EB"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor"
        />
      </svg>
      {label}
    </button>
  );
};

const SubmitButton: React.FC<Loading> = ({
  onClick,
  label = "Submit",
  disabled,
  status,
}) => {
  return (
    <div className="">
      <button
        data-modal-hide="popup-modal"
        type="submit"
        disabled={disabled}
        onClick={onClick}
        className="bg-teal-800 w-full mt-[7px]
          text-white font-semibold py-2 px-4 border
             border-gray-400 rounded shadow"
      >
        {disabled ? status : label}
      </button>
    </div>
  );
};

const DeleteButton: React.FC<Loading> = ({
  onClick,
  label = "Submit",
  disabled,
  status,
}) => {
  return (
    <button
      className=" bg-teal-black hover:bg-gray-400 text-white font-bold
       py-2 px-4 rounded inline-flex items-center"
      type="submit"
      onClick={onClick}
      disabled={disabled}
    >
      {disabled ? status : label}
    </button>
  );
};

const ModalDeleteCancel: React.FC<DeleteConfirm> = ({
  onClick,
  label = "Cancel",
}) => {
  return (
    <button type="button" onClick={onClick} className="bg-white
     hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
    {label}
    </button>
  );
};

const ModalDeleteConfirm: React.FC<DeleteConfirm> = ({
  onClick,
  label = "Submit",
}) => {
  return (
    <button type="button" onClick={onClick} className="bg-teal-800 mr-3
     text-white font-semibold py-2 px-4 border border-gray-400 rounded shadow">
   {label}
   </button>
  );
};

const ModalDeleteTitle: React.FC<DeleteTitle> = ({ label = "Submit" }) => {
  return (
    <div>
      <svg
        className="mx-auto mb-4 text-black w-12 h-12 dark:text-gray-200"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
      <h3 className="mb-5 text-lg font-normal text-black dark:text-gray-400">
        {label}
      </h3>
    </div>
  );
};

const ModalDeleteHeader: React.FC<ModalHeader> = ({ onClick, label }) => {
  return (
    <div>
      <button
        type="button"
        className="absolute end-2.5 text-gray-400 bg-transparent
         hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8
          ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600
           dark:hover:text-white"
        onClick={onClick}
      >
       
       <button
          type="button"
          onClick={onClick}
          className="flex items-center text-white justify-center  w-7 h-7 bg-teal-500 rounded-full"
        >
        x
        </button>
        <span className="sr-only">{label}<AiOutlineClose size={5} className="text-white text-sm" /></span>
      </button>
    </div>
  );
};

export {
  IsLoadingButton,
  SubmitButton,
  DeleteButton,
  ModalDeleteHeader,
  ModalDeleteTitle,
  ModalDeleteConfirm,
  ModalDeleteCancel,
};
