import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { labelStyle } from "../../@types/productInfo";

interface FormTitle {
  onClick?: (e: any) => void;
  label?: string;
}

const FormNav: React.FC<FormTitle> = ({ onClick, label = "Submit" }) => {
  
  return (
  <div className=" ... sticky top-[-30px]  ">
      <div
      className={`... relative h-[50px] w-[full]
       transition-transform transfor bg-white`}
    >
      <div className="flex items-center space-x-4 mt-[-6%] ">
        <div className="flex flex-row w-1/2">
          <h6 className="justify-start font-semibold text-gray-900 dark:text-white">
            {label}
          </h6>
        </div>
        <div className="flex flex-row w-1/2 justify-end mt-[10px]">
          <label
            style={labelStyle as React.CSSProperties}
            htmlFor="Ingredient"
            className="text-gray-600 font-bold text-sm"
          >
            <button
              type="button"
              onClick={onClick}
              className="flex items-center justify-center w-7 h-7 bg-teal-500 rounded-full"
            >
              <AiOutlineClose className="text-white text-sm" />
            </button>
          </label>
        </div>
      </div>
    
    </div>
  </div>
  );
};
export { FormNav };
