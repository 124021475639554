import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Icons, Images } from "../../assets/assets";
import OutlinedButton from "../../components/Button/OutlinedButton";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const GasCylinders = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"CO2 Gas Cylinders | SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Never run out of gas on your sparkling water machines. Explore SodaStream CO2 gas cylinders, how they work, and where to buy them."
          }
        />
         <link rel="canonical" href={"https://www.sodastream.co.za/co2-gas-cylinders"} />
      </Helmet>
      <Header
        title="The World of CO₂ Gas Cylinders"
        textBackground="GAS CYLINDERS"
        backgroundImg={Images.Page6BG}
        subTitle="Fast and reliable gas options to keep your sparkling water maker fizzing!"
      />
      <section className="lg:mt-[-16vh] md:mt-[-10vh] sm:mt-[-5vh]">
        <div className="flex md:flex-row flex-col gap-0 w-full">
          <div className="basis-[50%] px-20 py-10 text-[#005D6E] bg-[#75A7AD80] text-start flex flex-col gap-5">
            <h2 className="text-[24px] font-normal">Exchange your cylinder</h2>
            <span className="text-[14px]">
              Exchange your empty cylinders for a full one and pay only for the
              refill.
            </span>
            <div className="flex gap-1 items-center justify-start">
              {/* <p className='text-[20px]'>How does it work?</p> */}
              {/* <BiChevronRight fontSize={25} /> */}
            </div>
            <div className="flex md:flex-row flex-col gap-4">
              {/* <PrimaryButton label='BUY ONLINE' /> */}
              <Link to="/store-locator">
                <OutlinedButton label="FIND A STORE" />
              </Link>
            </div>
            {/* remove both cta buttons */}
            {/* <div className='flex gap-4'>
                        <PrimaryButton label='EXCHANGE ONLINE' />
                        <OutlinedButton label='FIND A STORE' />
                    </div> */}
            <span className="text-white text-[10px]">
              The cylinder is property of SodaStream and provided for use under
              license.
            </span>
          </div>
          <div className="basis-[50%] bg-[#75A7AD] text-white px-20 py-10 text-start flex flex-col gap-5">
            <h2 className="text-[#005D6E] font-normal text-[24px]">
              Get a spare
            </h2>
            <span className="text-white text-[14px]">
              Always have a spare on hand to keep your water sparkling
            </span>
            <div className="flex gap-1 items-center justify-start">
              {/* <p className='text-[20px]'>Why should I get a spare?</p> */}
              {/* <BiChevronRight fontSize={25} /> */}
            </div>
            <div className="flex md:flex-row flex-col gap-4">
              {/* <PrimaryButton label='BUY ONLINE' /> */}
              <a href="/gas-refills">
                <OutlinedButton label="Learn More" />
              </a>
            </div>
            <span className="text-[10px]">
              The cylinder is property of SodaStream and provided for use under
              license.
            </span>
          </div>
        </div>
      </section>

      <section>
        <div className="text-[#005D6E] flex flex-col gap-10 grid-cols-2 items-center justify-center py-5 md:px-20 md:py-20 md:pb-40">
          <h2 className="text-[20px]">SodaStream benefits</h2>
          <div className="md:flex md:flex-nowrap flex-wrap gap-5 grid grid-cols-2 md:gap-0 justify-between items-center">
            <div className="flex flex-col gap-4 items-center justify-center">
              <img
                className="md:w-[40%] w-[20%]"
                src={Icons.IconSparklingSmall}
                alt="sparkling water"
              />
              <span className="md:font-bold text-[14px]">
                SUSTAINABLE SPARKLE
              </span>
            </div>

            <div className="flex flex-col gap-4 items-center justify-center">
              <img
                className="md:w-[40%] w-[20%]"
                src={Icons.IconQuickyEasy}
                alt="sparkling water"
              />
              <span className="md:font-bold text-[14px]">QUICK & EASY</span>
            </div>

            <div className="flex flex-col gap-4 items-center justify-center">
              <img
                className="md:w-[40%] w-[20%]"
                src={Icons.IconQualityFirst}
                alt="sparkling water"
              />
              <span className="md:font-bold text-[14px]">QUALITY FIRST</span>
            </div>

            <div className="flex flex-col gap-4 items-center justify-center">
              <img
                className="md:w-[40%] w-[20%]"
                src={Icons.IconFreshCertified}
                alt="sparkling water"
              />
              <span className="md:font-bold text-[14px]">
                FRESH & CERTIFIED
              </span>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex md:flex-row flex-col gap-0 relative">
          <div className="basis-[70%] bg-[#EEEAE5] md:px-20 md:py-20 p-10 flex text-[#005D6E] text-start">
            <div className="basis-[55%] hidden md:block">
              <div className="flex items-center justify-center relative bg-[#75A7AD] w-16 h-16 rounded-full left-[7rem] z-30">
                <span className="text-white text-[8px] break-words text-center font-bold">
                  QUICK CONNECT
                </span>
                <img
                  src={Icons.IconGroup80}
                  alt="i"
                  style={{ width: "50%" }}
                  className="absolute bottom-[-1rem] left-8"
                />
              </div>
              <img
                className="absolute top-[-3rem] left-[15rem]"
                src={Images.Group11Img}
                alt="Co2 gas cylinders"
                style={{ width: "11%" }}
              />
              <div className="flex items-center justify-center relative bg-[#75A7AD] w-16 h-16 rounded-full left-[17.5rem] z-30">
                <span className="text-white text-[8px] break-words text-center font-bold">
                  SCREW IN
                </span>
                <img
                  src={Icons.IconGroup80}
                  alt="i"
                  style={{ width: "50%" }}
                  className="absolute top-[-1rem] left-8"
                />
              </div>
            </div>
            <div className="basis-[100%] md:basis-[45%] flex flex-col gap-4">
              <h2 className="text-[24px] font-bold">Our gas cylinders</h2>
              <p className="text-[12px] font-light">
                Each sparkling water maker is compatible with a specific
                cylinder: either the Classic screw-in system or the Quick
                Connect system.
              </p>
              {/* <p className='text-[12px] font-bold'>Which cylinder do I need?</p> */}
            </div>
          </div>
          <div className="basis-[30%]">
            <img src={Images.FuseBottleImg} alt="fuse bottle" />
          </div>
        </div>
      </section>

      <section className="relative">
        <div className={`flex text-[#005D6E]`}>
          <div className="basis-[20%]"></div>
          <div className="flex flex-col p-10 md:p-20 gap-5 md:basis-[50%] text-start">
            {/* <div className='flex gap-1'>
                        <BiChevronLeft fontSize={25}/>
                        <BiChevronRight fontSize={25}/>
                    </div> */}
            <p className="font-bold text-[20px]">“</p>
            <p className="text-[24px] font-bold">
              It was simple to return used cylinders. Would definitely
              recommend.
            </p>
            <p className="font-light">Judith W.</p>
            <p className="font-bold text-[20px]">“</p>
          </div>
          <div className="basis-[30%] hidden md:block">
            <img src={Images.ReviewsImg} alt="reviews" className="z-0" />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default GasCylinders;
