import React, { ReactNode } from "react";

const Product = ({
  children,
  isNew,
  isMixed,
  isDishWashedShape,
}: {
  children: ReactNode;
  isNew?: boolean;
  isMixed?: boolean;
  isDishWashedShape?: boolean;
}) => {
  return (
    <div
      className="bg-white rounded-xl border border-gray-300 relative py-2 overflow-hidden z-120 "
      style={{
        boxShadow: "0px 7px 40px rgba(0, 0, 0, 0.03)",
        opacity: 0.9,
        maxHeight: "370px",
        maxWidth: "100%",
        height:'330px',
        width:'600px',
        marginBottom:'30px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      }}
    >
      {isNew && (
        <div className="absolute -top-5 -right-5 bg-[#E56C5C] w-[70px] h-[70px] rounded-full flex items-center justify-center p-2">
          <p className="uppercase text-white font-bold text-[12px]">New</p>
        </div>
      )}
      {isMixed && (
        <div className="absolute -top-5 -right-5 bg-[#F9C645] w-[70px] h-[70px] rounded-full flex items-center justify-center p-2">
          <p className="uppercase text-white font-bold text-[12px]">
            MIX & MATCH!
          </p>
        </div>
      )}
      {isDishWashedShape && (
        <div className="absolute -top-5 -right-5 bg-[#75A7AD] w-[70px] h-[70px] rounded-full flex items-center justify-center p-2">
          <p className="uppercase text-white font-bold text-[6px]">
            DISHWASHER SAFE
          </p>
        </div>
      )}
      {children}
    </div>
  );
};

export default Product;
