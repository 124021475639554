import React, { useState, useEffect } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

interface SwiperProps {
  images: { src: string, alt: string }[];
}

const Swiper: React.FC<SwiperProps> = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());


  const slidesToShow = screenSize.width > 420 ? 5 : 3;

  const nextSlide = () => {
    if (currentSlide + slidesToShow < images.length) {
      setCurrentSlide(currentSlide + slidesToShow);
    }
    console.log(currentSlide)
  };

  const prevSlide = () => {
    if (currentSlide - slidesToShow >= 0) {
      setCurrentSlide(currentSlide - slidesToShow);
    }
    console.log(currentSlide)
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  //listen for window resize event, and update dimensions 
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    
    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])


  return (
    <div className="overflow-hidden">
      <div className='flex gap-0 items-center justify-center'>
        <button className="" onClick={prevSlide}>
          <BiChevronLeft fontSize={80} color='white' />
        </button>
        <div
          // className="flex transition-transform duration-500 ease-in-out"
          className='flex w-full md:mr-[10px] m-auto'
        >
          {images.slice(currentSlide, (currentSlide + slidesToShow)).map((image, index) => (
            <div key={index} className='basis-[20%] mr-[20px]'>
              <img
                style={{maxWidth:"none"}}
                className='md:h-[200px] h-[100px]'
                src={image.src}
                alt={image.alt}
              />
            </div>
          ))}
        </div>
        <button className="" onClick={nextSlide}>
          <BiChevronRight fontSize={80} color='white' />
        </button>
      </div>
    </div>
  );
};

export default Swiper;
