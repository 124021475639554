import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

// Define the structure of user data
export interface UserData {
  email: string;
  password: string;
  token: string | null;
  
}

// Define the structure of the authentication context
interface AuthContextType {
  user: UserData | null;
  login: (userData: UserData) => void;
  logout: () => void;
}

const initialAuthContext: AuthContextType = {
  user: null,
  login: () => {},
  logout: () => {}
}

// Create the authentication context using createContext
export const AuthContext = createContext<AuthContextType>(initialAuthContext);

// Define the expected props for AuthProvider
interface AuthProviderProps {
  children: ReactNode;
}

// Implement the AuthProvider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // State to manage the user's authentication details
  const [user, setUser] = useState<UserData | null>(null);

  // Function to handle user login
  const login = (userData: UserData) => {
 
    const combinedInfo = `${userData.email}:${userData.password}`;
    const token = btoa(combinedInfo);

    // Set user details and token
    setUser({ ...userData, token });

    // Store user information in local storage
    localStorage.setItem('userData', JSON.stringify({ ...userData, token }));
  };

  // Function to handle user logout
  const logout = () => {
    // Clear user details
    setUser(null);

    // Clear user information from local storage
    localStorage.removeItem('userData');
  };

  useEffect(() => {
    // Load the user data from local storage on mount
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUser(JSON.parse(storedUserData));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = (): AuthContextType => {

  const context = useContext(AuthContext);

  // If the context is not available, throw an error
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

 
  return context;
};
