import React from 'react';

interface PrimaryButtonProps {
  onClick?: () => void;
  label?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ onClick, label = 'Submit' }) => {
  return (
    <button
      onClick={onClick}
      className="w-fit px-8 py-1 bg-white rounded-[46px] opacity-100 flex items-center justify-center cursor-pointer"
    >
      <span className="md:text-[16px] text-[14px] leading-2 md:leading-[29px] text-[#75A7AD] uppercase font-bold">
        {label}
      </span>
    </button>
  );
};

export default PrimaryButton;
