import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BlogsService } from "../../../APIs/index";
import sanitizeHtml from "sanitize-html";
import { Blog } from "../../../@types/productInfo";
import Loading from "../../Loading/Loading";

const Blogs: React.FC = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);

  const fetchBlogData = async () => {
    try {
      console.log("Fetching blogs...");
      const { data, error } = await BlogsService.getAllBlogs();

      if (data) {
        console.log("Blogs fetched successfully:", data);
        setBlogs(data);
      } else {
        console.error("Error fetching blogs:", error);
      }
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    fetchBlogData();
  }, []);

  // Assainir le texte HTML
  const sanitizeBlogText = (html: string) => {
    return sanitizeHtml(html, {
      allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'p', 'br', 'ul', 'li', 'ol' ], // Personnalisez les balises autorisées
      allowedAttributes: {
        a: [ 'href' ]
      }
    });
  };

  return (
    <div className="p-4 rounded-md w-[90%] mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-black">Blogs</h2>
      </div>

      <div className="bg-white p-4 rounded-md w-5/4 mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h4 className="font-semibold text-black">Blog</h4>
          <Link
            to={"/dashboard/addblog"}
            className="bg-gray-300 text-black px-4 py-2 rounded-md flex items-center"
          >
            <BsPlusCircle className="mr-2" />
            Blog
          </Link>
        </div>
        <div className="flex items-center mb-4">
          <input
            className="border rounded-[0.50rem] border-gray-400 py-1 px-2 w-1/4 mr-4 bg-white"
            placeholder="Search Blogs"
          />
          <div className="flex-grow"></div>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="w-full text-xs">
                  <thead>
                    <tr>
                      <th className="border-b p-2 bg-white text-left">Image</th>
                      <th className="border-b p-2 bg-white text-left text-black">Title</th>
                      <th className="border-b p-2 bg-white text-left">Paragraph</th>
                      <th className="border-b p-2 bg-white text-left">Link</th>
                      <th className="border-b p-2 bg-white text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {navigator.onLine ? blogs.map((blog, index) => (
                      <tr key={index}>
                        <td className="w-auto h-auto">
                          <img
                            src={blog.image}
                            alt={blog.image}
                            className="object-cover w-30 h-20 rounded border border-solid border-gray-500"
                          />
                        </td>
                        <td className="border-b bg-white text-left text-black font-bold">
                          {blog.title ? blog.title : ""}
                        </td>
                        <td className="border-b bg-white text-left">
                          <div className="relative gap-10">
                            <div className="bg-white border-gray-300 relative w-full opacity-80 text-[#005D6E]">
                              <div className="flex flex-col gap-4 py-8 text-start">
                                {/* Utilisation de dangerouslySetInnerHTML pour injecter le HTML assaini */}
                                <div dangerouslySetInnerHTML={{ __html: sanitizeBlogText(blog.blogText) }} />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          {blog.link}
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          <span className="cursor-pointer rounded flex items-center">
                            <FaEdit />
                            <Link to={`/dashboard/edit/${blog.id}`}>Edit</Link>
                          </span>
                        </td>
                      </tr>
                    )) : <Loading />}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;