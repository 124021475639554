import React, { ReactNode, useEffect, useRef } from 'react';
import './ModalForm.css'
interface ModalProps {
  isOpen: boolean;
  modalId: string;
  closeModal: () => void;
  children:ReactNode;
}


const ModalForm: React.FC<ModalProps> = ({ isOpen, modalId, closeModal, children }) => {
  const modalRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    if (modalRef.current) {
      if (isOpen) {
        modalRef.current.showModal();
      } else {
        modalRef.current.close();
      }
    }
  }, [isOpen]);

  return (
    <>
      <div className=''>
      <dialog id={modalId} className="modal"  ref={modalRef}>
        <div className="modal-box h-full bg-white">
          {children}
        </div>
       
      </dialog>
      </div>
    </>
  );
};

export default ModalForm;