import React from 'react';
import AppRoute from './route';

import './App.css';

function App() {
  return (
      <AppRoute />
  );
}

export default App;
