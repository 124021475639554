import axios, { AxiosResponse } from "axios";

const BASE_URL = "https://sodastreamapi.azurewebsites.net/users";

export interface User {
  id: number;
  name: string;
  firstName: string;
  role: string;
  password: string;
}

export interface LoginCredentials {
  email: string;
  password: string;
}


class UserService {
  // Method to fetch all users
  async getAllUsers(): Promise<{ data: User[] | null; error: string | null }> {
    try {
      const response: AxiosResponse<User[]> = await axios.get(BASE_URL);
      if (response.status >= 200 && response.status < 300) {
        const users: User[] = response.data;
        console.log("Users:", users);
        return { data: users, error: null };
      } else {
        const error = "Error fetching users";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching users:", error);
      return { data: null, error };
    }
  }

  // Method to fetch a user by ID
  async getUserById(
    userId: number
  ): Promise<{ data: User | null; error: string | null }> {
    try {
      const response: AxiosResponse<User> = await axios.get(
        `${BASE_URL}/${userId}`
      );
      if (response.status >= 200 && response.status < 300) {
        const user: User = response.data;
        console.log("User:", user);
        return { data: user, error: null };
      } else {
        const error = "Error fetching user by ID";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching user by ID:", error);
      return { data: null, error };
    }
  }

  // Method to create a new user
  async createUser(
    userData: any
  ): Promise<{ data: User | null; error: string | null }> {
    try {
      const response: AxiosResponse<User> = await axios.post(
        BASE_URL,
        userData
      );
      if (response.status >= 200 && response.status < 300) {
        const createdUser: User = response.data;
        console.log("Created User:", createdUser);
        return { data: createdUser, error: null };
      } else {
        const error = `Error creating user - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error creating user:", error.message);
      // Log additional information if available
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }

  // Method to edit an existing user's data
  async editUser(
    userId: number,
    updatedUserData: any
  ): Promise<{ data: User | null; error: string | null }> {
    try {
      const response: AxiosResponse<User> = await axios.put(
        `${BASE_URL}/${userId}`,
        updatedUserData
      );
      if (response.status >= 200 && response.status < 300) {
        const updatedUser: User = response.data;
        console.log("Updated User:", updatedUser);
        return { data: updatedUser, error: null };
      } else {
        const error = `Error updating user - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error updating user:", error.message);
      // Log additional information if available
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }

  // Method to delete a user by ID
  async deleteUser(
    userId: number
  ): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<any> = await axios.delete(
        `${BASE_URL}/${userId}`
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("User deleted successfully:", response.data);
        return { data: response.data, error: null };
      } else {
        const error = `Error deleting user - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error deleting user:", error.message);
      // Log additional information if available
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }

  async loginUser(
    credentials: LoginCredentials
  ): Promise<{ data: User | null; error: string | null }> {
    try {
      const response: AxiosResponse<{ message: string }> = await axios.post(
        `${BASE_URL}/login`,
        credentials
      );

      console.log("Login Response:", response);

      if (response.status === 200) {
        const { message } = response.data;
        console.log("Login Message:", message);

        if (message === "User logged in successfully") {
          // If the message indicates successful login, you can consider it as successful
          return { data: null, error: null };
        } else {
          // If the message indicates an authentication failure, handle it accordingly
          return {
            data: null,
            error: "Authentication failed. Please check your credentials.",
          };
        }
      } else {
        const error = `Error logging in - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error logging in:", error.message);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }
}

// Creating an instance of the UserService class
const userServiceInstance = new UserService();

// Exporting the instance for use in other parts of the application
export default userServiceInstance;

// import axios from 'axios';

// const BASE_URL = 'http://localhost:5005/users';

// class UserService {
//   async getAllUsers() {
//     try {
//       const response = await axios.get(BASE_URL);
//       if (response.status >= 200 && response.status < 300) {
//         const users = response.data;
//         console.log('Users:', users);
//         return { data: users, error: null };
//       } else {
//         const error = 'Error fetching users';
//         console.error(error);
//         return { data: null, error };
//       }
//     } catch (error) {
//       console.error('Error fetching users:', error);
//       return { data: null, error };
//     }
//   }

//   async getUserById(userId: number) {
//     try {
//       const response = await axios.get(`${BASE_URL}/${userId}`);
//       if (response.status >= 200 && response.status < 300) {
//         const user = response.data;
//         console.log('User:', user);
//         return { data: user, error: null };
//       } else {
//         const error = 'Error fetching user by ID';
//         console.error(error);
//         return { data: null, error };
//       }
//     } catch (error) {
//       console.error('Error fetching user by ID:', error);
//       return { data: null, error };
//     }
//   }

//   // Add more methods as needed (e.g., createUser, editUser, deleteUser)
// }

// const userServiceInstance = new UserService();

// export { userServiceInstance as UserService };

// import axios from 'axios';

// const BASE_URL = 'http://localhost:5005/users';

// class UserService {
//   async getAllUsers() {
//     try {
//       const response = await axios.get(BASE_URL);
//       if (response.status >= 200 && response.status < 300) {
//         const users = response.data;
//         console.log('Users:', users);
//         return { data: users, error: null };
//       } else {
//         const error = 'Error fetching users';
//         console.error(error);
//         return { data: null, error };
//       }
//     } catch (error) {
//       console.error('Error fetching users:', error);
//       return { data: null, error };
//     }
//   }

//   async getUserById(userId: number) {
//     try {
//       const response = await axios.get(`${BASE_URL}/${userId}`);
//       if (response.status >= 200 && response.status < 300) {
//         const user = response.data;
//         console.log('User:', user);
//         return { data: user, error: null };
//       } else {
//         const error = 'Error fetching user by ID';
//         console.error(error);
//         return { data: null, error };
//       }
//     } catch (error) {
//       console.error('Error fetching user by ID:', error);
//       return { data: null, error };
//     }
//   }

//   //crud operations
// }

// var user = new UserService();
// export default user;
