import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { FaCloudUploadAlt, FaEdit, FaUpload } from "react-icons/fa";
import { AccessoriesClass } from "../../../APIs/index";
import { useReducer } from "react";
import {
  DeleteButton,
  IsLoadingButton,
  ModalDeleteCancel,
  ModalDeleteConfirm,
  ModalDeleteHeader,
  ModalDeleteTitle,
  SubmitButton,
} from "../../Loading/ButtonLoading";
import { ToastSuccess, ToastWarning } from "../../Loading/Tost";
import FormComponent from "../accesscontent/AccessContent";
import {
  AccessData,
  AccessProps,
  AccessoryData,
  StoreData,
  StoreDateType,
} from "../../../@types/productInfo";
import ModalForm from "../ModalForm/ModalForm";
import { FormNav } from "../../FormNav/FormNav";
import ModalButton from "../../Button/ModalButton";
import StoreForm from "../storeform/StoreForm";
import storeServiceInstance from "../../../APIs/store";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const Store: React.FC = () => {
  const [storeName, setStoreName] = useState<string>("");
  const [storeId, setStoreId] = useState<string>("");
  const [meta_title, setMetaTitle] = useState<string>("");
  const [meta_description, setMetaDescription] = useState<string>("");
  const [storeNo, setStoreNo] = useState<string>("");
  const [streetAddress, setStreetAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [phoneNo, setPhoneNo] = useState<number>(0);
  const [status, setStatus] = useState<string>("");
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<string>("");
  const [addressType, setAddressType] = useState<string>("");
  const [locationType, setLocationType] = useState<string>("");
  const [sellmachine, setSellMachine] = useState<boolean>(false);
  const [exchange, setExchange] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const storesPerPage = 6;
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);

  const handleRoleChange = (event: any) => {
    setSelectedRole(event.target.value);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState("all");
  const [selectedStore, setSelectedStore] = useState<StoreDateType | null>(
    null
  );

  const [stores, setStores] = useState<StoreDateType[]>([]);
  const [isSuccessMessageVisible, setSuccessMessageVisible] = useState(false);

  const [isEditSuccess, setEditSuccess] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStore, setFilteredStore] = useState<StoreDateType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isSuccessStore, setSuccessStore] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [products, setProducts] = useState<StoreData[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<StoreData[]>([]);

  const [formData, setFormData] = useState<StoreDateType>({
    storeName: "",
    storeId: "",
    storeNo: "",
    streetAddress: "",
    city: "",
    state: "",
    zip: "",
    phoneNo: 0,
    status: "",
    lat: 0,
    lng: "",
    addressType: "",
    locationType: "",
    sell_machine: false, 
    cylinder_exchange: false, 
    meta_title:"",
    meta_description:""
  });

  const handleButtonClickk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const closeModalEdit = () => {
    setShowModalEdit(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSearchTermChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const filterStoreFromSearch = (users: any[]) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const tempStore = users.filter((user) => {
      const storeProperties = Object.values(user).map((value) =>
        String(value).toLowerCase()
      );

      const isSelectedRoleValid =
        selectedRole === "all" ||
        (selectedRole === "sell_machine" && user.sell_machine === true) || 
        (selectedRole === "cylinder_exchange" &&
          user.cylinder_exchange === true);

      return (
        isSelectedRoleValid &&
        storeProperties.some((property) =>
          property.includes(lowerCaseSearchTerm)
        )
      );
    });

    setFilteredStore(tempStore);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterStoreFromSearch(stores);
    } else {
      setFilteredStore(stores);
    }
  }, [searchTerm, stores]);

  const totalPages = Math.ceil(filteredStore.length / storesPerPage);
  const indexOfLastStore = currentPage * storesPerPage;
  const indexOfFirstStore = indexOfLastStore - storesPerPage;
  const currentStores = filteredStore.slice(
    indexOfFirstStore,
    indexOfLastStore
  );

  // Create an array of page numbers using map
  const generatePageNumbers = () => {
    const numbers = [];
    for (let i = 1; i <= totalPages; i++) {
      numbers.push(i);
      console.log("TotalPage", totalPages);
      console.log("ff", totalPages);
    }
    setPageNumbers(numbers);
  };

  useEffect(() => {
    generatePageNumbers();
  }, [totalPages]);

  // Function to handle form submission

  const handleFormSubmit = async (data: StoreData) => {
    console.log("format", data);
    const userData: StoreDateType = {
      storeName: data.storeName,
      storeId: data.storeId,
      storeNo: data.storeNo,
      streetAddress: data.streetAddress,
      city: data.city,
      state: data.state,
      zip: data.zip,
      phoneNo: data.phoneNo,
      status: data.status,
      lat: data.lat,
      lng: data.lng,
      addressType: data.addressType,
      locationType: data.locationType,
      sell_machine: data.sell_machine,
      cylinder_exchange: data.cylinder_exchange,
      meta_title:data.meta_title,
      meta_description:data.meta_description
    };

    try {
      setIsLoading(true);
      const { data, error } = await storeServiceInstance.createStore(userData);

      if (data) {
        console.log("Store created successfully:", data);
        fetchStoreData();
        setShowModal(false);
        setSelectedRole("");
        setSuccessStore(true);
        setTimeout(() => {
          setSuccessStore(false);
        }, 3000);
      } else {
        console.error("Error creating store:", error);
      }
    } catch (error) {
      console.error("Error creating store:", error);
    } finally {
      //set loading to false
      setIsLoading(false);
    }
  };

  // Function to fill the edit form
  const handleEditAcc = (store: any) => {
    console.log("Selected Store:", store);
    setSelectedStore(store);

    setStoreName(store.storeName || "");
    setStoreId(store.storeId || "");
    setStoreNo(store.storeNo || "");
    setStreetAddress(store.streetAddress || "");
    setCity(store.city || "");
    setState(store.state || "");
    setZip(store.zip || "");
    setPhoneNo(store.phoneNo || 0);
    setStatus(store.status || "");
    setLat(store.lat || 0);
    setLng(store.lng || "");
    setAddressType(store.addressType || "");
    setLocationType(store.locationType || "");
    setSellMachine(store.sell_machine);
    setExchange(store.cylinder_exchange);
    setMetaDescription(store.meta_description)
    setMetaTitle(store.meta_title)
    setShowModalEdit(true);

    console.log("store edit:", store);
  };

  const handleSaveEdit = async (storeData: StoreData) => {
    setIsLoadingUpdate(true);

    try {
      if (selectedStore && selectedStore.id) {
        const updatedAccData = {
          storeName: storeData.storeName,
          storeId: storeData.storeId,
          storeNo: storeData.storeNo,
          streetAddress: storeData.streetAddress,
          city: storeData.city,
          state: storeData.state,
          zip: storeData.zip,
          phoneNo: storeData.phoneNo,
          status: storeData.status,
          lat: storeData.lat,
          lng: storeData.lng,
          addressType: storeData.addressType,
          locationType: storeData.locationType,
          sell_machine: storeData.sell_machine,
          cylinder_exchange: storeData.cylinder_exchange,
          meta_title: storeData.meta_title,
          meta_description:storeData.meta_description
        };

        console.log("Updating store with data:", updatedAccData);

        const { data, error } = await storeServiceInstance.editStore(
          selectedStore.id,
          updatedAccData
        );

        console.log("API Response:", data, error);

        if (data) {
          fetchStoreData();
          setShowModalEdit(false);
          setEditSuccess(true);
          setSelectedStore(null);
          setTimeout(() => {
            setEditSuccess(false);
          }, 3000);
        } else {
          console.error("Error updating store:");
        }
      } else {
        console.error("Selected store or store id is undefined");
      }
    } catch (error) {
      console.error("Error updating store:", error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const fetchStoreData = async () => {
    try {
      const { data, error } = await storeServiceInstance.getAllStores();

      if (data) {
        const sortedData = data
          .slice()
          .sort((a, b) => a.storeName.localeCompare(b.storeName));

        setStores(sortedData);
        setFilteredStore(sortedData);
      } else {
        // console.error("Error fetching store:", error);
      }
    } catch (error) {
      console.error("Error fetching store:", error);
    }
  };

  //Call the method on component mount
  useEffect(() => {
    fetchStoreData();
  }, []);

  // Function to handle deleting store
  const handleDeleteStore = async (storeId: number) => {
    if (storeId) {
      try {
        setIsLoadingDelete(true);
        const { data, error } = await storeServiceInstance.deleteStore(storeId);

        if (data) {
          setStores((prev) => prev.filter((store) => store.id !== storeId));
          console.log("store deleted successfully:", data);
          setSuccessMessageVisible(true);
          fetchStoreData();
          setShowModalEdit(false);
          setSelectedStore(null);
          setIsModalVisible(false);
          setTimeout(() => {
            setSuccessMessageVisible(false);
          }, 3000);
        } else {
          console.error("Error deleting store:", error);
        }
      } catch (error) {
        console.error("Error deleting store:", error);
      } finally {
        setIsLoadingDelete(false);
      }
    }
  };

  return (
    <div className="p-4 rounded-md w-5/4 mx-auto">
      {isSuccessMessageVisible && (
        <ToastSuccess label="Store Delete Successfully!" />
      )}
      {isEditSuccess && <ToastSuccess label="Store Updated Successfully!" />}
      {isSuccessStore && <ToastSuccess label="Store added Successfully!" />}

      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-black">Store</h2>
      </div>

      <div className="relative mt-4">
        {showModal && (
          <div>
            <ModalForm isOpen={showModal} modalId={""} closeModal={closeModal}>
              <FormNav label="Add Store" onClick={closeModal} />

              <div>
                <StoreForm
                  formData={formData}
                  onSubmit={handleFormSubmit}
                  onClick={closeModal}
                />
              </div>
            </ModalForm>
          </div>
        )}
      </div>

      <div className="relative mt-4">
        {showModalEdit && selectedStore && (
          <ModalForm
            isOpen={showModalEdit}
            modalId={""}
            closeModal={closeModalEdit}
          >
            <FormNav label="Edit Store" onClick={closeModalEdit} />

            <div>
              <StoreForm
                formData={{
                  storeName,
                  storeId,
                  storeNo,
                  streetAddress,
                  city,
                  state,
                  zip,
                  phoneNo,
                  status,
                  lat,
                  lng,
                  addressType,
                  locationType,
                  sell_machine: sellmachine,
                  cylinder_exchange: exchange,
                  meta_title,
                  meta_description
                }}
                onSubmit={handleSaveEdit}
                isUpdate
                isUpdateDelete
                onClick={handleButtonClickk}
              />

              {isModalVisible && (
                <div
                  className="
                 backdrop-blur-sm h-[237%] max-h-[1000%] top-0 fixed
                  left-0 w-full bg-gray-800 opacity-30 z-100"
                ></div>
              )}

              {isModalVisible && (
                <div
                  id="popup-modal"
                  tabIndex={-1}
                  className="overflow-y-auto grid overflow-x-hidden mt-[100%] fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                  <div className="relative p-4 w-full text-black max-w-md max-h-full">
                    <div
                      className="relative bg-white rounded-lg text-black shadow dark:bg-gray-700"
                      style={{ zIndex: 999 }}
                    >
                      <ModalDeleteHeader
                        onClick={handleModalClose}
                        label="close Modal"
                      />

                      <div className="p-4 md:p-5 text-center">
                        <ModalDeleteTitle label=" Are you sure you want to delete this store ?" />
                        {!isLoadingDelete && (
                          <div>
                            <ModalDeleteConfirm
                              label="Confirm"
                              onClick={() =>
                                selectedStore?.id &&
                                handleDeleteStore(selectedStore.id)
                              }
                            />

                            <ModalDeleteCancel
                              label="Cancel"
                              onClick={handleModalClose}
                            />
                          </div>
                        )}

                        {isLoadingDelete && (
                          <IsLoadingButton
                            type="submit"
                            label="Deleting Store..."
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ModalForm>
        )}
      </div>
      <div className="bg-white p-4 rounded-md w-5/4 mx-auto">
        <ModalButton
          title="All Stores "
          label="Add Store"
          onClick={openModal}
        />

        {/*  */}
        <div className="flex items-center mb-4">
          <input
            className="border rounded-[0.50rem] border-gray-400 py-1 px-2 w-1/4 mr-4 bg-white"
            placeholder="Search Member"
            onChange={handleSearchTermChange}
            value={searchTerm}
          />
          <div className="flex-grow"></div>
          <div className="form-group gender text-gray-500 p-2 rounded-md w-auto border-2">
            <select
              id="category"
              name="category"
              className="mt-1 block w-full rounded-md focus:outline-none text-sm bg-white"
              onChange={handleRoleChange} // Assurez-vous que la fonction de gestion du changement est correctement liée ici
              value={selectedRole}
            >
              <option value="all">All</option>
              <option value="sell_machine">Sell Machine</option>
              <option value="cylinder_exchange">Cylinder Exchange</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className=" min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="w-full text-xs">
                  <thead>
                    <tr>
                      <th className="border-b p-2 bg-white text-left">
                        Store Name
                      </th>

                      <th className="border-b p-2 bg-white text-left">
                        Street Address
                      </th>

                      <th className="border-b p-2 bg-white text-left">City</th>

                      <th className="border-b p-2 bg-white text-left">State</th>

                      <th className="border-b p-2 bg-white text-left">
                        Phone Number
                      </th>

                      <th className="border-b p-2 bg-white text-left">
                        Status
                      </th>

                      <th className="border-b p-2 bg-white text-left">
                        Location
                      </th>

                      <th className="border-b p-2 bg-white text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentStores.map((store, index) => (
                      <tr key={index}>
                        <td className="border-b p-2 bg-white text-left text-black font-bold">
                          {store.storeName}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          {store.streetAddress}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          {store.city}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          {store.state}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          {store.phoneNo}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          {store.status}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          {store.locationType}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          <span
                            onClick={() => handleEditAcc(store)}
                            className="cursor-pointer rounded flex items-center"
                          >
                            <FaEdit />
                            Edit
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex space-x-4 mt-[20px] justify-end">
        <section className=" inline-flex col row">
          <div className="flex">
            {currentPage > 1 && (
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="rounded-full h-8 px-1 font-semibol bg-white  ml-[10px]"
              >
                <BiChevronLeft size={30} className="text-black" />
              </button>
            )}

            {pageNumbers.map(
              (number, index) =>
                // Display only the relevant buttons for the current page
                currentPage <= index + 2 &&
                index + 1 <= currentPage + 2 && (
                  <div className="flex space-x-3" key={number}>
                    {<div></div>}
                    <button
                      className="rounded-full h-8 px-3 font-semibol text-white "
                      onClick={() => handlePageChange(number)}
                      style={{
                        backgroundColor:
                          number === currentPage ? "#75A7AD" : "white",
                        color: number === currentPage ? "white" : "black",
                      }}
                    >
                      {number}
                    </button>
                  </div>
                )
            )}

            {currentPage < pageNumbers.length && (
              <button
                className="rounded-full h-8 px-1 font-semibol bg-white  ml-[10px]"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <BiChevronRight size={30} className="text-black " />
              </button>
            )}
          </div>
        </section>
        <section className=" flex space-x-8  p-2">
          <h5 className="font-bold text-black text-xs mr-[-20px]">
            Store per page
          </h5>
          <select
            id="role"
            className="mt-[-8px] w-[90px] py-2  block rounded-md focus:outline-none text-black text-sm bg-white"
            name="role"
            value={currentPage}
            onChange={(e) => handlePageChange(Number(e.target.value))}
          >
            {pageNumbers.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </section>
      </div>
    </div>
  );
};

export default Store;
