import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Icons, Images } from "../../assets/assets";
import SecondaryButton from "../../components/Button/SecondaryButton";
import PrimaryButton from "../../components/Button/PrimaryButton";
import Product from "../../components/Product/Product";
import Modal from "../../components/Modal/Modal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const SpareCylinder = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const clsoeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Spare CO2 Gas Cylinders | SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Never run out of bubbles! Buy a spare CO2 gas cylinder for your SodaStream machine and keep the fizz flowing."
          }
        />
         <link rel="canonical" href={"https://www.sodastream.co.za/spare-cylinders"} />
      </Helmet>
      <Modal isOpen={isModalOpen} modalId={""} closeModal={clsoeModal}>
        <div className="text-center px-20 py-20">
          <div className="flex gap-10 ">
            <div className="flex flex-col gap-4 items-center justify-center">
              <img
                src={Images.TerraBlack}
                alt="bottles"
                style={{ width: "100%" }}
              />
              <PrimaryButton label="TERRA" />
            </div>

            <div className="items-center justify-center flex flex-col">
              <img
                src={Images.WatterBottleBlack}
                alt="bottles"
                style={{ width: "100%" }}
              />
              <PrimaryButton label="ART" />
            </div>
          </div>
        </div>
      </Modal>
      <Header
        title="Spare Cylinders"
        textBackground="SPARE CYLINDERS"
        backgroundImg=""
      />
      <section>
        <div className="relative">
          <div className="md:relative my-10 md:z-50 z-9 text-[#005D6E]">
            <div className="px-5 md:px-24">
              <Product>
                <div className="md:p-10 p-3 text-start flex flex-col gap-5">
                  <h2 className="text-[20px] font-bold">
                    Get a Spare Cylinder
                  </h2>
                  <p className="font-bold">
                    Keep a backup cylinder and never run out of bubbles. Each
                    cylinder makes up to 60 Liters of fresh sparkling water.
                  </p>
                  <div className="flex gap-4">
                    {/* <SecondaryButton label='BUY NOW' /> */}
                    <Link to="/store-locator">
                      <PrimaryButton label="FIND A STORE" />
                    </Link>
                  </div>
                </div>
              </Product>
            </div>
          </div>
          <div className="bg-[#75A7AD] flex items-center justify-center gap-4 p-10 text-white pt-20">
            <img
              src={Icons.IconHeathier}
              style={{ width: "2.5%" }}
              alt="bottle"
            />
            <p>
              Our Pro Tip Never run out of bubbles! We recommend having 3
              cylinders on hand and refilling 2 at a time.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="md:px-60 px-10 py-10 flex flex-col gap-5 items-center text-[#005D6E] bg-[#EEEAE5]">
          <h2 className="text-[20px] font-bold">
            Getting a refill or a back up?
          </h2>
          <span>Find the best option for you</span>
          <div className="flex items-center justify-center md:px-40 px:10 py-10 gap-10 text-start">
            <div className="flex flex-col items-start justify-start gap-2">
              <img
                src={Images.ExchangeBottleSpareImg}
                alt="order spare"
                style={{ width: "100%" }}
              />
              <h3 className="font-semibold px-5 text-[18px]">
                Exchange Empty Cylinders
              </h3>
              <p className="px-5">
                Return your empty cylinders for full ones and pay only for the
                refill
              </p>
              <Link to="/store-locator">
                <div className="px-5 py-2">
                  <PrimaryButton label="REFILL NOW" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex justify-between md:px-60 px-10 py-10 gap-10 text-[#005D6E]">
          <div className="">
            <img
              src={Images.CylindersWithRippleImg}
              alt="cylinder with ripple"
            />
          </div>
          <div className="flex flex-col gap-4 items-start justify-center text-start">
            <h2 className="font-bold">Any empty cylinders lying around?</h2>
            <p>
              Don’t forget to send them back! Replacing empty cylinders with
              full ones saves you money and helps the environment.
            </p>
            <div>
              <Link to="/store-locator">
                <SecondaryButton label="REFILL NOW" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="text-[#005D6E] flex justify-between md:px-60 px-10 bg-[#EEEAE5]">
          <div className="basis-[50%] py-20 text-start flex flex-col gap-5">
            <h2 className="text-[20px] font-bold">Our gas cylinders</h2>
            <p>
              Each sparkling water maker is compatible with a specific cylinder:
              either the Classic screw-in system or the Quick Connect system.
            </p>
          </div>
          <div className="relative basis-[40%]">
            <img
              src={Images.CylinderUS}
              alt="cylunder img"
              className="absolute bottom-0 left-0 z-50"
              style={{ width: "70%" }}
            />
          </div>
        </div>
      </section>

      <section>
        <div className="text-[#005D6E] flex flex-col gap-10 items-center justify-center px-5 py-5 md:px-20 md:py-20 pb-40">
          <h2 className="text-[20px] font-bold">SodaStream benefits</h2>
          <div className="md:flex md:flex-nowrap flex-wrap gap-5 grid grid-cols-2 md:gap-0 justify-between items-center">
            <div className="flex flex-col gap-4 items-center justify-center">
              <img
                src={Icons.IconSparklingSmall}
                alt="sparkling water"
                style={{ width: "40%" }}
              />
              <span className="font-bold text-[14px]">SUSTAINABLE SPARKLE</span>
            </div>

            <div className="flex flex-col gap-4 items-center justify-center">
              <img
                src={Icons.IconQuickyEasy}
                alt="sparkling water"
                style={{ width: "40%" }}
              />
              <span className="font-bold text-[14px]">QUICK & EASY</span>
            </div>

            <div className="flex flex-col gap-4 items-center justify-center">
              <img
                src={Icons.IconQualityFirst}
                alt="sparkling water"
                style={{ width: "40%" }}
              />
              <span className="font-bold text-[14px]">QUALITY FIRST</span>
            </div>

            <div className="flex flex-col gap-4 items-center justify-center">
              <img
                src={Icons.IconFreshCertified}
                alt="sparkling water"
                style={{ width: "40%" }}
              />
              <span className="font-bold text-[14px]">FRESH & CERTIFIED</span>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default SpareCylinder;
