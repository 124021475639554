import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { IsLoadingButton, SubmitButton } from "../../Loading/ButtonLoading";
import { FormData } from "../../../@types/productInfo";
import { FormComponentProps } from "../../../@types/productInfo";
import { labelStyle } from "../../../@types/productInfo";

import { FaEye, FaEyeSlash } from "react-icons/fa";
const FormComponent: React.FC<FormComponentProps> = ({
  isUpdate = false,
  formData,
  isUpdateDelete = false,
  onClick,
  onSubmit,
}) => {
  const [FormData, setFormData] = useState<FormData>(formData);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    setFormData(formData);
  }, [formData]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    await onSubmit(FormData);
    setIsLoading(false);
  };


  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword: boolean) => !prevShowPassword);
  };

  const label = isUpdateDelete ? "Delete Member" : "Cancel";
  return (
    <form typeof="submit"  className="mt-[3%]">
      <div className="flex flex-col space-y-4 mt-4 ">
        <div className="flex items-center space-x-4">
          <div className="flex flex-col w-1/2">
            <label
              style={labelStyle as React.CSSProperties}
              htmlFor="firstName"
              className="text-gray-600 font-bold text-sm"
            >
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={FormData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
              className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
            />
          </div>
          <div className="flex flex-col w-1/2">
            <label
              style={labelStyle as React.CSSProperties}
              htmlFor="firstName"
              className="text-gray-600 font-bold text-sm"
            >
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              value={FormData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
              className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
            />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <label
            style={labelStyle as React.CSSProperties}
            htmlFor="email"
            className="text-gray-600 font-bold text-sm"
          >
            Email Address
          </label>
          <input
            type="email"
            name="email"
            value={FormData.email}
            onChange={handleChange}
            required
            placeholder="Email Address"
            className="w-full px-3 py-2 border rounded-[0.50rem] text-black border-gray-400 bg-white"
          />
        </div>
    
        <div className="form-group gender text-gray-500 p-2 rounded-md w-44 border-2">
        <label
            style={labelStyle as React.CSSProperties}
            htmlFor="email"
            className="text-gray-600 font-bold text-sm"
          >
            Role
          </label>
          <select
            id="role"
            className="mt-1 block w-full rounded-md focus:outline-none text-black text-sm bg-white"
            name="role"
            value={FormData.role}
            onChange={handleChange}
          >
            <option disabled className="text-gray-500" selected>
              Role
            </option>
            <option value="admin">Admin</option>
            <option value="super_admin">Super Admin</option>
          </select>
        </div>

        <div>
          <div>
            <label
              style={labelStyle as React.CSSProperties}
              htmlFor="password"
              className="text-gray-600 font-bold text-sm"
            >
              Password
            </label>

            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={FormData.password}
                onChange={handleChange}
                placeholder="Password"
                required
                className="w-full px-3 py-2 border text-black rounded-[0.50rem] border-gray-400 bg-white pr-10"
              />
              <div
                className="absolute inset-y-0 mt-7 right-0 flex items-center pr-2 cursor-pointer"
                onClick={handleTogglePassword}
              >
                {showPassword ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <div className="mt-[0px]">
              {!isLoading ? (
                <SubmitButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isLoading}
                  label={isUpdate ? "Update Member" : "Add Member"}
                />
              ) : (
                <IsLoadingButton  type="submit" label="Loading..." />
              )}
              <button
                onClick={onClick}
                type="button"
                className="bg-white w-full mt-[7px]
            text-gray-800 shadow-md font-semibold py-2 px-4 border
             border-gray-400 rounded"
              >
                {label}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormComponent;
