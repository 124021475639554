import axios, { AxiosResponse } from "axios";
const BASE_URL = "https://sodastreamapi.azurewebsites.net/accessories";

export interface Access {
  id: number;
  banner: string;
  name: string;
  description: string;
  caption: string;
  ingredient: string;
  price: number;
  rating: number;
  image: string;
  accessorylink :string;
}


class AccessoriesClass {
  async getAllAccessories(): Promise<{
    data: Access[] | null;
    error: string | null;
  }> {
    try {
      const response: AxiosResponse<Access[]> = await axios.get(BASE_URL);
      if (response.status >= 200 && response.status < 300) {
        const accs: Access[] = response.data;
        console.log("Accessories:", accs);
        return { data: accs, error: null };
      } else {
        const error = "Error fetching accessories";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching accessories:", error);
      return { data: null, error };
    }
  }

  // Method to fetch a product by ID
  async getAccessoryById(
    accessId: number
  ): Promise<{ data: Access | null; error: string | null }> {
    try {
      const response: AxiosResponse<Access> = await axios.get(
        `${BASE_URL}/id/${accessId}`
      );
      if (response.status >= 200 && response.status < 300) {
        const product: Access = response.data;
        console.log("Acc:", product);
        return { data: product, error: null };
      } else {
        const error = "Error fetching access by ID";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching access by ID:", error);
      return { data: null, error };
    }
  }

  async getAccessoryByLink(
    accessorylink : string
  ): Promise<{ data: Access | null; error: string | null }> {
    try {
      const response: AxiosResponse<Access> = await axios.get(
        `${BASE_URL}/${accessorylink}`
      );
      if (response.status >= 200 && response.status < 300) {
        const accessory: Access = response.data;
        console.log("Accessory:", accessory);
        return { data: accessory, error: null };
      } else {
        const error = "Error fetching accessory by name";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching accessory by name:", error);
      return { data: null, error };
    }
  }

  // Method to create a new Access
  async createAccessory(
    productData: any
  ): Promise<{ data: Access | null; error: string | null }> {
    try {
      const response: AxiosResponse<Access> = await axios.post(
        BASE_URL,
        productData
      );
      if (response.status >= 200 && response.status < 300) {
        const createdProduct: Access = response.data;
        console.log("Created Accessory:", createdProduct);
        return { data: createdProduct, error: null };
      } else {
        const error = `Error creating accessory - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error creating accessory:", error.message);
      // Log additional information if available
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }

 
  // Method to edit an existing product's data
  async editAcessory(
    productId: number,
    updatedProductData: any
  ): Promise<{ data: Access | null; error: string | null }> {
    try {
      const response: AxiosResponse<Access> = await axios.put(
        `${BASE_URL}/id/${productId}`,
        updatedProductData
      );
      if (response.status >= 200 && response.status < 300) {
        const updatedProduct: Access = response.data;
        console.log("Updated Accessory:", updatedProduct);
        return { data: updatedProduct, error: null };
      } else {
        const error = `Error updating accessory - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error updating accessory:", error.message);
      // Log additional information if available
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }
  // Method to delete a acc by ID
  async deleteAccessory(
    productId: number
  ): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<any> = await axios.delete(
        `${BASE_URL}/id/${productId}`
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("Acc deleted successfully:", response.data);
        return { data: response.data, error: null };
      } else {
        const error = `Error deleting Acc - ${response.status}: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error deleting acc:", error.message);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      return { data: null, error: error.message };
    }
  }
}

// Creating an instance of the ProductService class
const productServiceInstance = new AccessoriesClass();

// Exporting the instance for use in other parts of the application
export default productServiceInstance;

