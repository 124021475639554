import axios, { AxiosResponse } from "axios";


const BASE_URL = 'https://sodastreamapi.azurewebsites.net/water-machines';

class WaterMachineService {
  async getAllWaterMachines(): Promise<{
    data: any[] | null;
    error: string | null;
  }> {
    try {
      const response: AxiosResponse<any[]> = await axios.get(BASE_URL);
      if (response.status >= 200 && response.status < 300) {
        const waterMachines: any[] = response.data;
        console.log("Water Machines:", waterMachines);
        return { data: waterMachines, error: null };
      } else {
        const error = "Error fetching water machines";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching water machines:", error);
      return { data: null, error };
    }
  }


  async getWaterMachineByLink(machinelink: string): Promise<{ data: any | null; error: string | null }> {
    try {
        const response: AxiosResponse<any> = await axios.get(`${BASE_URL}/${machinelink}`);
        if (response.status >= 200 && response.status < 300) {
            const waterMachine: any = response.data;
            console.log("Water Machine:", waterMachine);
            return { data: waterMachine, error: null };
        } else {
            const error = "Error fetching water machine by link";
            console.error(error);
            return { data: null, error };
        }
    } catch (error: any) {
        console.error("Error fetching water machine by link:", error);
        return { data: null, error };
    }
}


  async getWaterMachineById(
    machineId: number
  ): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<any> = await axios.get(
        `${BASE_URL}/id/${machineId}`
      );
      if (response.status >= 200 && response.status < 300) {
        const waterMachine: any = response.data;
        console.log("Water Machine:", waterMachine);
        return { data: waterMachine, error: null };
      } else {
        const error = "Error fetching water machine by ID";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching water machine by ID:", error);
      return { data: null, error };
    }
  }

  async createWaterMachine(
    machineData: any
  ): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<any> = await axios.post(BASE_URL, machineData);
      if (response.status >= 200 && response.status < 300) {
        const createdMachine: any = response.data;
        console.log("Created Water Machine:", createdMachine);
        return { data: createdMachine, error: null };
      } else {
        const error = "Error creating water machine";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error creating water machine:", error);
      return { data: null, error };
    }
  }

  async editWaterMachine(
    machineId: number,
    updatedMachineData: any
  ): Promise<{ data: any | null; error: string | null }> {
    try {
      const response: AxiosResponse<any> = await axios.put(
        `${BASE_URL}/id/${machineId}`,
        updatedMachineData
      );
      if (response.status >= 200 && response.status < 300) {
        const editedMachine: any = response.data;
        console.log("Edited Water Machine:", editedMachine);
        return { data: editedMachine, error: null };
      } else {
        const error = `Error editing water machine: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error editing water machine:", error.message);
      return { data: null, error: error.message };
    }
  }

  async deleteWaterMachine(
    machineId: number
  ): Promise<{ data: boolean | null; error: string | null }> {
    try {
      const response: AxiosResponse<void> = await axios.delete(
        `${BASE_URL}/id/${machineId}`
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Water Machine deleted successfully");
        return { data: true, error: null };
      } else {
        const error = `Error deleting water machine: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error deleting water machine:", error.message);
      return { data: null, error: error.message };
    }
  }
}

const waterMachineServiceInstance = new WaterMachineService();

export default waterMachineServiceInstance;
