import { ReactNode } from "react";



//
export interface BlogState {
  id?: number;
  title: string;
  meta_title:string;
  meta_description:string;
  blogText: string
  link: string;
  image: string;
}


export interface Blog {
  id: number;
  title: string;
  meta_title:string;
  meta_description:string;
  blogText: string;
  image: string;
  name: string;
  link: string;
}

export interface ISection {
    title: string;
    content: ReactNode;
    image?: ReactNode;
  }

 export interface IComment {
    name: string;
    isVerified: boolean;
    rating: number;
    date: string;
    message: string;
    numLikes: number;
    numDislikes: number;
  }

  export interface User {
    email: string;
    role: string;
    firstName: string;
    password: string;
    lastName: string;
    createAt: string;
  }

 export interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    password: string;
    id?: number;
  }

  export interface BlogState {
    bloglink:string;
    id?: number;
    title: string;
    blogText: string;
    link: string;
    image: string;
  }
  
export interface FormComponentProps {
    formData: FormData;
    onSubmit: (data: FormData) => void;
    isUpdate?: boolean;
    isUpdateDelete?:boolean;
    onDelete?: () => void;
    onClick?: (e: any) => void;
  }

 export interface Product {
    id?: number;
    banner: string | null;
    name: string;
    meta_title:string;
    meta_description:string;
    description: string;
    caption: string;
    ingredient: string;
    price: number;
    rating: number;
    image: string | null;
    category: string;
    productlinkname:string
    productaltbanner?:String;
   
  }
  

  export interface WaterMachine {
    id?: number;
    name: string | any;
    description: string;
    images_white: string[];
    images_black: string[];
    video: string | null;
    newMethod?:string;
    meta_description:string;
    meta_title:string;
    machinelink?:any
  }

  export interface FormComponentProps {
    formData: FormData;
    newImage?:string;
    onSubmit: (data: FormData) => void;
    isUpdate?: boolean;
    onClick?: (e: any) => void;
    onDelete?: () => void;
  }

  

//accessory
export interface AccessProduct {
  id?: number;
  name: string;
  description: string;
  caption: string;
  image: File | string;
}

export interface Store {
  id?: number;
  name: string;
  description: string;
  caption: string;
  image: File | string;
}

export interface AccessFormData {
  id?: number;
  name: string;
  description: string;
  caption: string;
  image: File | string;
}

export interface FormComponentProps {
  formData: FormData;
  onSubmit: (data: FormData) => void;
  isUpdate?: boolean;
  onClick?: (e: any) => void;
  isUpdateDelete?:boolean
  onDelete?: () => void;
}


export interface AccessoryData {
  id?: number;
  name: string;
  description: string;
  meta_title:string;
  meta_description:string;
  caption: string;
  image: string | null;
  accessorylink:string
}

export const labelStyle: LabelStyle = {
  color: "#515151",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",

  lineHeight: "normal",
  textAlign: "start",
  float: "left",
  marginLeft: "0px",
  marginBottom: "8px",
};

  export interface LabelStyle {
    color: string;
    fontFamily: string;
    fontSize: string;
    fontStyle: string;
  
    lineHeight: string;
    textAlign: string;
    float: string;
    marginLeft: string;
    marginBottom: string;
  }
  
  export interface AccessData {
    id?: number;
    banner: string;
    name: string;
    description: string;
    caption: string;
    ingredient: string;
    price: number;
    rating: number;
    image: string | null;
  }
  
  export interface AccessProps {
    formData: AccessProps;
    onSubmit: (data: AccessProps) => void;
    isUpdate?: boolean;
    onDelete?: () => void;
    id?: number; 
    name: string;
    description: string;
    caption: string;
    image: string | null;
  }

  export interface Mixology {
    id?: number;
    name: string;
    ingredients: string[];
    methods: string[];
    description: string;
    meta_title:string;
    meta_description:string;
    productId?: number | null | undefined;
    image: string| null;
    width?: string;
    recipelink: string
  }

  export interface ProductsFlavours {
    id?: number;
    banner: string;
    name: string;
    description: string;
    caption: string;
    ingredient: string;
    price: string | number;
    rating: string | number;
    image: string;
    category: string;
    cta?: string;
    productlinkname:string;
    productaltbanner:string;
    product_type?: string
  }

  export const defaultProduct: ProductsFlavours = {
  banner: "default banner",
  name: "default name",
  description: "default description",
  caption: "default caption",
  ingredient: "default ingredient",
  price: "default price",
  rating: "default rating",
  image: "default image",
  category: "default category",
  productlinkname:"default link",
  productaltbanner:"n"
};

  export interface Mixologies {
    id?: number;
    name: string;
    ingredients: string[];
    methods: string[];
    description: string;
    productId?: number;
    image: string;
    meta_title:string;
    meta_description:string;
    recipelink:string
  }
  
  export const defaultRec: Mixologies = {
    name: "default name",
    ingredients: ["default ingredient...."],
    methods: ["default methods......."],
    description: "default description",
    image: "default image",
    productId: 0,
    meta_title:"default meta_title",
    meta_description:"default meta_description",
    recipelink:"n"

  };
  
  
  export interface DataRecipe {  
    name: string | any;
    ingredients: string[];
    methods: string[];
    image: string | null;
    description: string;
    productId?: number| undefined
    newMethod:string;
    newIngredient:string
    meta_description:string;
    meta_title:string;
    recipelink:string;
  }
  
  export interface Access {
    id?: number;
    name: string;
    description: string;
    caption: string; 
    image: string;
  }


  export interface StoreDateType {
    id?:number;
    storeName: string;
    storeId: string;
    storeNo: string;
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
    phoneNo: number;
    status: string;
    lat: number;
    lng: string;
    addressType: string;
    locationType: string;
    sell_machine: boolean;
    cylinder_exchange: boolean;
    meta_title:string;
    meta_description:string;
}

  
  export interface Recipe {
    id?: number;
    name: string;
    ingredients: string[];
    methods: string[];
    image: string | null;
    description: string;
    productId: number| undefined;
    newMethod:string;
    newIngredient:string;
    meta_description:string;
    meta_title:string;
    recipelink:string;
  }
  

  export interface StoreData {
    id?:number;
    storeName: string;
    storeId: string;
    storeNo: string;
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
    phoneNo: number;
    status: string;
    lat: number;
    lng: string;
    addressType: string;
    locationType: string;
    sell_machine: boolean;
  cylinder_exchange: boolean;
  meta_description:string;
  meta_title:string;
  }

  
  export interface AccessRecipe {
    formData: DataRecipe;
    onSubmit: (data: DataRecipe) => void;
    isUpdate?: boolean;
    onDelete?: () => void;
    id?: number;
    productId?: number;
    onClick?: (e: any) => void;
    isUpdateDelete?:boolean
  }
  
 