import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Images } from "../../assets/assets";
import Product from "../../components/Product/Product";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { Helmet } from "react-helmet";

const RegisterMachine = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Register your Machine | SodaStream SA"}</title>
        <meta
          name="description"
          content={
            "Register your SodaStream machine and enjoy exclusive benefits. Stay updated and get the most out of your device!"
          }
        />
        <link
          rel="canonical"
          href=" https://www.sodastream.co.za/register-machine"
        />
      </Helmet>
      <Header
        textBackground="REGISTER"
        backgroundImg={Images.Page20BG}
        subTitle="Register your product here and get an additional 2-year warranty."
        title="Register Your Machine"
      />
      <div className="relative min-h-[35rem] h-full w-full bg-[#EEEAE5] ">
        <div className="flex flex-col min-h-[45rem] md:flex-row">
          {/* <div className='basis-[50%] text-[#005D6E] flex flex-col gap-8 p-10 text-start'>
                                <div>
                                    <h2 className='font-bold'>Personal Details</h2>
                                    <div className='flex flex-col md:flex-row gap-4 mt-4'>
                                        <input className='rounded-full w-full pl-5 py-1 border border-[#005D6E] bg-white opacity-1 text-[#707070] text-[12px] font-bold' placeholder='Name' />
                                        <input className='rounded-full w-full pl-5 py-1 border border-[#005D6E] bg-white opacity-1 text-[#707070] text-[12px] font-bold' placeholder='Surname' />
                                    </div>
                                    <div className='flex flex-col md:flex-row gap-4 mt-4'>
                                        <input className='rounded-full w-full pl-5 py-1 border border-[#005D6E] bg-white opacity-1 text-[#707070] text-[12px] font-bold' placeholder='Email' />
                                        <input className='rounded-full w-full pl-5 py-1 border border-[#005D6E] bg-white opacity-1 text-[#707070] text-[12px] font-bold' placeholder='Date of birth ' />
                                    </div>
                                </div>

                                <div>
                                    <h2 className='font-bold'>Machine Details</h2>
                                    <div className='flex flex-col md:flex-row gap-4 mt-4'>
                                        <input className='rounded-full w-full pl-5 py-1 border border-[#005D6E] bg-white opacity-1 text-[#707070] text-[12px] font-bold' placeholder='Date of purchase' />
                                        <input className='rounded-full w-full pl-5 py-1 border border-[#005D6E] bg-white opacity-1 text-[#707070] text-[12px] font-bold' placeholder='Place of purchase' />
                                    </div>
                                </div>

                                <div className='flex flex-col items-center'>
                                    <h2 className='font-bold hidden md:block'>WHICH MACHINE DO YOU OWN?</h2>
                                    <div className='md:flex gap-2 items-center justify-center hidden'>
                                        <div className='relative w-fit mt-5'>
                                            <img src={Images.TerraBlack} alt='terra' style={{ width: "70%"}} />
                                            <div className='w-24 h-24 bg-[#005D6E] rounded-full absolute top-1/2 left-5 -z-10' style={{ transform: 'translateY(-50%)'}}></div>
                                        </div>

                                        <div className='relative w-fit'>
                                            <img src={Images.TerraBlack} alt='terra' style={{ width: "70%"}} />
                                            <div className='w-24 h-24 bg-[#005D6E] rounded-full absolute top-1/2 left-5 -z-10' style={{ transform: 'translateY(-50%)'}}></div>
                                        </div>

                                    </div>
                                </div>

                                <div className='text-start flex flex-col gap-4'>
                                    <p className='text-[12px]'>All fields marked with an asterisk * are mandatory.</p>
                                    <p className='text-[12px]'><input className='checkbox' /> Click here if you want to hear about our latest offers and news by e-mail. If you un-tick this option - opt-out from receiving marketing e-mails - we may still send you service emails regarding your orders or products.</p> 
                                    <p className='text-[12px]'>I understand SodaStream will process my personal data for the purposes of registering the machine in accordance with SodaStream’s T&Cs and Sodastream’s privacy policy.</p>
                                </div>

                                <div>
                                    <SecondaryButton label='Submit' />
                                </div>
    
                            </div>
 */}
          <iframe
            title="Register Your Machine"
            src="https://bravegroup.everlytic.net/public/forms/get/pID0m8kFsoq7Jne4/Zjk2MTRjNzcxMWY5YTIxZDkxYzIyMmI2ZjQzZjA5OGY4OGFmZWFjYg=="
            className="md:w-[50%] min-h-[65rem] md:p-10 border-none; overflow-x-auto overflow-y-none"
          ></iframe>
          <div className="p-10 md:w-[50%] md:flex items-center justify-center hidden">
            <img
              src={Images.Page20Img}
              alt="page 20"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RegisterMachine;
