import React, { useState } from 'react'
import { AiFillStar } from 'react-icons/ai';
import PrimaryButton from '../Button/PrimaryButton';
import { BiSearch } from 'react-icons/bi';
import CommentCard from '../CommentCard/CommentCard';
import Pagination from '../Pagination/Pagination';
import { IComment, ISection } from '../../@types/productInfo';
  
  type IOpenSections = { [key: number]: boolean };
  type ITabNames = 'reviews' | 'faqs' | 'specifications' | 'sodastream';
  

  type TabButtonProps = {
    label: string,
    isActive: boolean,
    onClick: () => void,
  };


//   
  const TabButton: React.FC<TabButtonProps> = ({ label, isActive, onClick }: { label: string, isActive: boolean, onClick: () => void}) => (
    <button 
      onClick={onClick} 
      className={`basis-[25%] cursor-pointer flex items-center justify-center py-2 ${isActive ? 'text-white rounded-full bg-[#75A7AD]' : 'text-[#75A7AD]'}`}
    >
      <span>{label}</span>
    </button>
  );

  const ReviewSection = ({ comments, renderStars}: { comments: IComment[], renderStars: any}) => (
    <>
        {/* header */}
        <div className='flex flex-col gap-4 items-center mt-5'>
            <div className='flex gap-2 items-center'>
                <span>4.8</span>
                <div className='flex gap-1'>{renderStars(5)}</div>
                <span>{`(${comments.length} Reviews)`}</span>
            </div>
            <PrimaryButton label='WRITE A REVIEW' />
        </div>
        {/* search */}
        <div className='w-full'>
            <div className='border-b border-gray-300 w-full pb-1 text-start'>
                <span className='border-b-4 border-b-[#75A7AD]'>REVIEWS</span>
            </div>
            <div className='mt-5 text-start'>
                <span className=''>Filter Reviews</span>
            </div>
            <div className='mt-5'>
                <div className='relative'>
                    <input className='px-10 w-full py-3 pr-20 text-[14px] rounded-full bg-transparent border-gray-300 border focus:border-gray-300 hover:border-gray-300' placeholder='Search Reviews' />
                    <span className='absolute top-1/2 right-1 p-3 bg-[#75A7AD] rounded-full' style={{ transform: "translateY(-50%)"}}>
                        <BiSearch color="white"/>
                    </span>
                </div>
            </div>
        </div>
        {/* comments */}
        <div>
            { comments.map((comment: any, index: any) => (
                <CommentCard key={index} commentData={comment} />
            ))}
        </div>
        {/* pagination */}
        <div>
            <Pagination />
        </div>
    </>
  );

const FAQSection = ({ sections,  toggleSection, openSections}: { sections: ISection[], toggleSection: (index: number) => void, openSections: IOpenSections}) => (
    <div className='flex flex-col gap-5'>
        {sections.map((section: ISection, index: number) => (
            <div className="collapse text-start p-0 mt-5" key={index}>
                <input type="checkbox" onClick={() => toggleSection(index)} /> 
                <div className="collapse-title text-xl font-medium p-0">
                    <div className="flex justify-between items-start">
                        <h2 className="font-bold text-[18px] text-[#75A7AD]">{section.title}</h2>
                        <button 
                            className="bg-[#75A7AD] px-2 flex items-center justify-center rounded-full text-white w-8 h-8" 
                        >
                            {openSections[index] ? "-" : "+"}
                        </button>
                    </div>
                </div>
                <div className="collapse-content p-0">
                    <p>{section.content}</p>
                    {section?.image}
                </div>
                {/* {
                    section.isImage ? <div className="collapse-content p-0"><img src={section.content} alt={section.title} style={{ width: "50%", margin: "0 auto"}} /></div> : (

                    )
                } */}
            </div>
        ))}
    </div>
)

const SpecificationSection = ({ title, items }: { title: string, items: string[]}) => (
    <div className='text-start flex flex-col items-start justify-start gap-4 w-full mt-10'>
        <h2 className='font-bold text-[18px] text-[#75A7AD]'>{title}</h2>
        <div className='flex flex-col gap-2'>
            {items.map((item, index) => (
                <p key={index}>{`${index + 1}. ${item}`}</p>
            ))}
        </div>
    </div>
);

const SodaStreamSection = () => (
    <div className='text-start flex flex-col items-start justify-start gap-4 w-full mt-10'>
        <h2 className='font-bold text-[18px] text-[#75A7AD]'>Why SodaStream?</h2>
        <div className='flex flex-col gap-2'>
            <div className="form-control flex flex-col gap-4 text-start items-start">
                <label className="cursor-pointer label flex gap-4">
                    <input type="checkbox" checked className="checkbox bg-black bg-opacity-50 border border-black" disabled/>
                    <span className="label-text">One SodaStream bottle can save up to thousands of single-use plastic bottle</span>
                </label>

                <label className="cursor-pointer label flex gap-4">
                    <input type="checkbox" checked className="checkbox bg-transparent" disabled/>
                    <span className="label-text">Make fresh sparkling water just the way you like it</span>
                </label>

                <label className="cursor-pointer label flex gap-4">
                    <input type="checkbox" checked className="checkbox bg-transparent" disabled/>
                    <span className="label-text">Staying hydrated has never been this fun or easy</span>
                </label>

                <label className="cursor-pointer label flex gap-4">
                    <input type="checkbox" checked className="checkbox bg-transparent" disabled/>
                    <span className="label-text">Craft an endless variety of healthy, delicious beverages</span>
                </label>

                <label className="cursor-pointer label flex gap-4">
                    <input type="checkbox" checked className="checkbox bg-transparent" disabled/>
                    <span className="label-text">Save Money: The Per Litre cost of sparkling water is a fraction of the cost of store-purchased carbonated water</span>
                </label>
            </div>
        </div>
    </div>
)

  
const ProductInfoPanel = ({ sections, comments, specifications }: { sections: ISection[], comments: IComment[], specifications: { title: string, items: string[]}}) => {
    const [activeSection, setActiveSection] = useState<ITabNames>('reviews');
    const [openSections, setOpenSections] = useState<IOpenSections>({});
  
    const toggleSection = (index: number) => {
      setOpenSections((prevState) => ({
        ...prevState,
        [index]: !Boolean(prevState[index]),
      }));
    };
  
    const renderStars = (count: number) => Array(count).fill(null).map((_, i) => <AiFillStar key={i} />);
  
    const sectionNames: ITabNames[] = ["reviews", "faqs", "specifications", "sodastream"];
    
    return (
      <section className="product-info">
        <div className=' px-5 md:px-60 py-10 flex flex-col gap-5 items-center justify-center bg-[#F6F9FA] w-full' style={{ boxShadow: "0px 7px 20px #00000026"}}>
        <div className='w-full rounded-lg md:rounded-full bg-[#FFFFFF] p-2 flex flex-col md:flex-row justify-between uppercase text-[14px] font-semibold text-[#75A7AD]'>
            {sectionNames.map((name: ITabNames) => (
                <TabButton 
                key={name} 
                label={name.toUpperCase()}
                isActive={activeSection === name}
                onClick={() => setActiveSection(name)}
                />
            ))}
            </div>
        
        {activeSection === "reviews" && <ReviewSection comments={comments} renderStars={renderStars} />}
        {activeSection === "faqs" && <FAQSection sections={sections} toggleSection={toggleSection} openSections={openSections} />}
        {activeSection === "specifications" && <SpecificationSection title={specifications.title} items={specifications.items} />}
        {activeSection === "sodastream" && <SodaStreamSection />}

        </div>
      </section>
    );
  };

export default ProductInfoPanel;